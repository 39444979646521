@charset "utf-8";
@use "variable" as vars;
@use "sass:math";

/*////////////////////////////////////////////////////

    ヘッダー

////////////////////////////////////////////////////*/
.header{
    width: 100%;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-sizing: border-box;
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.05));
    @include vars.media(pc){
        height: 100px;
    }
    @include vars.media(sp){
        height: 13.5vw;
    }
    @include vars.media(tablet){
        height: 6.75vw;
    }
    .header_menu {
        .header_menu_btn {
            #header_menu_btn_check {
                display: none;
            }
            .header_menu_nav {
                transition: all 0.5s 0s ease-out;
                background-color: vars.$baseColor01;
                position: absolute;
                left: 0;
                top: -100vh;
                width: 100%;
                opacity: 0;
                z-index: 1;
                filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.05));
                @include vars.media(sp) {
                    height: calc(100vh - 13.5vw);
                }
                @include vars.media(sp-tab) {
                    z-index: 998;
                    background-color: #EBEBEB;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .header_menu_nav_wrap{
                    background: #EBEBEB;
                    padding: 3vw 0;
                    @include vars.media(sp) {
                        padding: 3vw 0 25vw;
                    }
                    .gWrap{
                        display: flex;
                        width: 80vw;
                        margin: 0 auto;
                        box-sizing: border-box;
                        justify-content: space-between;
                        &.style01{
                            .navBox{
                                border-right: none;
                                padding:0 0.8vw;
                            }
                        }
                        @include vars.media(tablet) {
                            width: 98vw;
                        }
                        @include vars.media(sp) {
                            flex-direction: column;
                            width: 100%;
                            padding: 3.5vw 2vw 0 2vw;
                        }
                        .titBox{
                            position: relative;
                            .gnavMypageLink{
                                position: absolute;
                                top:0;
                                right: 5px;
                                a{
                                    display: flex;
                                    align-items: center;
                                    font-weight: normal;
                                    justify-content: flex-start;
                                    transition: all 0.5s 0s ease;
                                    font-weight: bold;
                                    color: #125da7;
                                    font-size: 1.3rem;
                                    &:hover{
                                        color: #448FC1;
                                    }
                                    &:before{
                                        content: "";
                                        display: block;
                                        width: 14px;
                                        height: 14px;
                                        background: url(/common/img/circle-arrow02.svg) center center no-repeat;
                                        background-size: 95%;
                                    }
                                }
                            }
                        }
                        p.title{
                            a{
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                justify-content: center;
                                background-color: #0C3F71;
                                padding: 5px 10px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                color: #fff;
                                font-weight: bold;
                                font-size: 1.3rem;
                                margin-bottom: 10px;
                                position: relative;
                                transition: all .3s ease;
                                
                                svg{
                                    margin-right: 5px;
                                    fill: #fff;
                                    width: 20px;
                                    height: 20px;
                                }
                                
                                &:hover {
                                    background-color: vars.$themeColor02;
                                }
                                &:after {
                                    content: "";
                                    display: block;
                                    width: 14px;
                                    height: 14px;
                                    background: url(/common/img/circle-arrow02-w.svg) center center no-repeat;
                                    background-size: 95%;
                                    margin-right: 5px;
                                    transition: all 0.5s 0s ease;
                                    @include vars.media(sp) {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                                &.style01{
                                    background-color: unset;
                                    padding: 0;
                                    color: #0C3F71;
                                    &:hover {
                                        background-color: unset;
                                        color: #448FC1;
                                    }
                                    &:after {
                                        background: url(/common/img/circle-arrow02.svg) center center no-repeat;
                                    }
                                }
                            }
                            &.style01{
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                justify-content: flex-start;
                                padding: 0 10px 5px;
                                box-sizing: border-box;
                                color: #0C3F71;
                                font-weight: bold;
                                font-size: 1.4rem;
                                margin-bottom: 15px;
                                position: relative;
                                border-bottom: #448FC1 2px solid;
                                svg{
                                    margin-right: 5px;
                                    fill: #0C3F71;
                                    width: 20px;
                                    height: 20px;
                                }
                                span{
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 5px;
                                    img{
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                           
                        }
                        h4{
                            color: #448FC1;
                            font-size: 1.3rem;
                            font-weight: bold;
                            &.sec{
                                margin-top: 10px;
                            }
                            a{
                                color: #448FC1;
                                font-size: 1.3rem;
                                font-weight: bold;
                            }
                        }
                        .navBox {
                            width: 25%;
                            border-right: solid 2px #C5D0D5;
                            box-sizing: border-box;
                            padding:0 1.5vw;
                            &.style01{
                                width: 50%;
                                @include vars.media(sp) {
                                    width: 100%;
                                }
                            }
                            &.style02{
                                width: 75%;
                                @include vars.media(sp) {
                                    width: 100%;
                                }
                            }
                            @include vars.media(sp) {
                                width: 100%;
                                border-right: none;
                            }
                            &:last-child{
                                border-right: none;
                            }
                            &.col02{
                                @include vars.media(sp) {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    p{
                                        width: 48%;
                                    }
                                }
                            }
                            .mypageTxt{
                                text-align: center;
                                font-weight: bold;
                                margin-top: 15px;
                                font-size: 0.9em;
                                line-height: 1.1;
                                br{
                                    display: none;
                                }
                                @media screen and (max-width: 1400px) {
                                    font-size: 0.8em;
                                    br{
                                        display: block;
                                    }
                                }
                                @include vars.media(sp) {
                                    font-size: 1em;
                                    br{
                                        display: none;
                                    }
                                }
                            }
                            .mypageLink{
                                margin-top: 5px;
                                a{
                                    display: flex;
                                    justify-content: center;
                                    font-weight: bold;
                                    background-color: #0077AC;
                                    color: #fff;
                                    font-size: 1.8rem;
                                    padding: 5px 12px;
                                    box-sizing: border-box;
                                    border-radius: 20px;
                                    align-items: center;
                                    &::before{
                                        display: block;
                                        content:"";
                                        left: 3px;
                                        width: 4px;
                                        height: 4px;
                                        border-top: 2px solid #fff;
                                        border-right: 2px solid #fff;
                                        -webkit-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                        .gnavWrap{
                            display: flex;
                            &.style02{
                                @include vars.media(sp) {
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                }
                            }
                            .gnav_memCont{
                                width: 33%;
                                &.member{
                                    @include vars.media(sp) {
                                        // display: none;
                                    }
                                }
                                @include vars.media(sp) {
                                    width: 49%;
                                }
                                &.sec{
                                    @include vars.media(sp) {
                                        margin-top: 10px;
                                    }
                                }
                                .gnavList{
                                    li{
                                        width: 100%;
                                        .g_subList{
                                            @include vars.media(sp) {
                                                display: none;
                                            }
                                        }
                                    }
                                    
                                }
                                &.style03{
                                    @include vars.media(sp) {
                                        width: 100%;
                                    }
                                    .gnavList{
                                        width: 100%;
                                        @include vars.media(sp) {
                                            display: flex;
                                            &> li{
                                                width: 49%;
                                            }
                                        }
                                    }
                                }
                            }
                            @include vars.media(sp) {
                                flex-direction: column;
                            }
                            .gnavList{
                                width: 100%;
                                justify-content: space-between;
                                &.style02{
                                    width: 100%;
                                }
                                @include vars.media(sp) {
                                    width: 100%;
                                    margin-bottom: 0;
                                }
                                >li{
                                    @include vars.media(sp) {
                                        width: 50%;
                                    }
                                    
                                }
                                .g_subList{
                                    @include vars.media(sp) {
                                        display: flex;
                                        flex-wrap: wrap;
                                        width: 100%;
                                        flex-direction: column;
    
                                    }
                                    >li{
                                        @include vars.media(sp) {
                                            width: 100%;
                                        }
                                        &:last-child{
                                            @include vars.media(sp) {
                                                margin-bottom: 0!important;
                                            }
                                        }
                                    }
                                }
                                &.style01{
                                    @include vars.media(sp) {
                                        width: 100%;
                                        margin-bottom: 10px;
                                    }
                                    &:first-child{
                                        @include vars.media(sp) {
                                            margin-bottom: 0;
                                        }
                                    }
                                    >li{
                                        @include vars.media(sp) {
                                            width: 49%;
                                        }
                                        
                                    }
                                }
                            }
                        }
                        .navCont01{

                        }
                        p.title02{
                            padding: 0 1vw;
                            font-weight: bold;
                            font-size: 0.83em;
                        }
                        .g_atherList{
                            @include vars.media(sp) {
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 15px;
                                margin-top: 15px;
                                justify-content: space-between;
                            }
                            li{
                                border-bottom: solid 2px #fff;
                                box-sizing: border-box;
                                padding-bottom: 5px;
                                margin-bottom: 5px;
                                @include vars.media(sp) {
                                    width: 49%;
                                }
                                a{
                                    display: flex;
                                    align-items: center;
                                    font-weight: bold;
                                    transition: all .3s ease;
                                    &:hover {
                                        color: vars.$themeColor02!important;
                                    }
                                    span{
                                        width: 15px;
                                        height: 15px;
                                        margin-right: 5px;
                                        fill: #0172BE;
                                        width: 20px;
                                        height: 20px;
                                        img{
                                            width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                                
                            }
                        }
                        .gNav_bnr{
                            margin-bottom: 10px;
                            &.style01{
                                @include vars.media(sp) {
                                    margin: 10px auto 10px;
                                }
                            }
                            @include vars.media(sp) {
                                width: 60%;
                                margin: 0 auto 10px;
                            }
                            img{
                                width: 100%;
                                height: auto;
                            }
                        }
                        .gnavList{
                            padding: 0 1vw;
                            
                            @include vars.media(sp) {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-bottom: 15px;
                            }
                            &.style01{
                                @include vars.media(sp) {
                                    margin-bottom: 0;
                                }
                            }
                            >li{
                                margin-bottom: 5px;
                                @include vars.media(sp) {
                                        width: 50%;
                                }
                                >a{
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    font-weight: bold;
                                    @include vars.media(sp-tab) {
                                        font-size: 1.3rem;
                                    }
                                    &:before {
                                        content: "";
                                        display: block;
                                        width: 14px;
                                        height: 14px;
                                        background: url(/top/img/circle-arrow.svg) center center no-repeat;
                                        background-size: 95%;
                                        margin-right: 5px;
                                        transition: all 0.5s 0s ease;
                                    }
                                    @include vars.media(pc) {
                                        transition: all .3s ease;
                                        &:hover {
                                        color: vars.$themeColor02;
                                        }
                                    }
                                }
                            }
                        }
                        .g_subList{
                            @include vars.media(sp) {
                                margin-top: 5px;
                            }
                            
                            >li{
                                    
                                @include vars.media(sp) {
                                    margin-bottom: 5px;
                                }
                                >a{
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    font-weight: normal;
                                    @include vars.media(sp-tab) {
                                        font-size: 1.3rem;
                                    }
                                    &:before {
                                        content: "";
                                        display: block;
                                        width: 14px;
                                        height: 14px;
                                        background: url(/common/img/circle-arrow02.svg) center center no-repeat;
                                        background-size: 95%;
                                        margin-right: 5px;
                                        transition: all 0.5s 0s ease;
                                    }
                                    @include vars.media(pc) {
                                        transition: all .3s ease;
                                        &:hover {
                                        color: vars.$themeColor02!important;
                                        }
                                    }
                                }
                            }
                            
                        }
                        .gNav_btn {
                            display: flex;
                            flex-direction: column;
                            @include vars.media(pc) {
                                // width: 100%;
                            }
                            @include vars.media(sp-tab) {
                                justify-content: space-between;
                                padding: 0 0 0;
                                
                                // margin-top: 20px;
                            }
                            @include vars.media(sp) {
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 15px;
                            }
                            &.biz-connect{

                                li{
                                    @include vars.media(sp) {
                                        width: 80%;
                                        margin: 0 auto;
                                    }
                                    a{
                                        background-color: #E44E00;
                                        img{
                                            width: 25px;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                            &.style01{
                                li{
                                    @include vars.media(sp) {
                                        align-items: center;
                                        width: 60%;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            li {
                                height: 50px;
                                @include vars.media(pc) {
                                    transition: all 0.5s 0s ease;
                                }
                                @include vars.media(sp) {
                                    height: auto;
                                    width: 49%;
                                    display: flex;
                                    align-items: center;
                                }
                                text-align: center;
                                &:nth-child(1) {
                                    background-color: vars.$themeColor03;
                                    margin-bottom: 10px;
                                    @include vars.media(sp) {
                                        margin-bottom: 0;
                                    }
                                    svg,img {
                                        width: 18px;
                                        height: 18px;
                                        vertical-align: middle;
                                        @include vars.media(sp) {
                                            width: 26px;
                                            height: 26px;
                                        }
                                    }
                                    &:hover {
                                        @include vars.media(pc) {
                                            background-color: #125da7;
                                        }
                                    }
                                }
                                &.biz{
                                    svg,img {
                                        width: 18px;
                                        height: 18px;
                                        vertical-align: middle;
                                        @include vars.media(sp) {
                                            width: 26px;
                                            height: 26px;
                                        }
                                    }
                                }
                                &:nth-child(2) {
                                    background-color: #E44E00;
                                    svg,img {
                                        width: 18px;
                                        height: 18px;
                                        vertical-align: middle;
                                        @include vars.media(sp) {
                                            width: 26px;
                                            height: 26px;
                                        }
                                    }
                                    &:hover {
                                        @include vars.media(pc) {
                                            background-color: #e47b26;
                                        }
                                    }
                                }
                                a {
                                    height: 100%;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    padding: 8px 0;
                                    color: vars.$baseColor01;
                                    font-size: 1.4rem;
                                    line-height: 1.3;
                                    @include vars.media(tablet) {
                                        font-size: 13px;
                                        line-height: 1;
                                        
                                    }
                                    @include vars.media(sp) {
                                        height: auto;
                                    }
                                    font-weight: bold;
                                    svg,img {
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .navTopLink{
                        margin-top: 15px;
                        a{
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            font-size: 1.4rem;
                            color: #032A44;
                            justify-content: center;
                            span{
                                width: 18px;
                                height: 18px;
                                margin-right: 5px;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.6);
                width: 100%;
                height: 0;
                z-index: 0;
                opacity: 0;
                transition: all 0.5s 0s ease;
            }
            .menu-btn {
                display: flex;
                z-index: 90;
                background-color: vars.$themeColor03;
                border-radius: 0 0 5px 5px;
                top: 0;
                position: absolute;
                @include vars.media(pc){
                    left: 0;
                    height: 110px;
                    width: 84px;
                    cursor: pointer;
                }
                @include vars.media(sp){
                    right: 0;
                    height: 16.74vw;
                    width: 15.66vw;
                    border-radius: 0 0 1.35vw 1.35vw;
                    z-index: 1000;
                    padding: 0;
                }
                @include vars.media(tablet){
                    right: 0;
                    height: 8.37vw;
                    width: 7.83vw;
                    border-radius: 0 0 0.68vw 0.68vw;
                    z-index: 1000;
                }
                span {
                    border-radius: 2px;
                    content: '';
                    display: block;
                    height: 8px;
                    position: absolute;
                    transition: all 0.5s 0s ease;
                    @include vars.media(pc){
                        left: 26px;
                    }
                    @include vars.media(sp){
                        height: 1.89vw;
                        left: 4.05vw;
                    }
                    @include vars.media(tablet){
                        height: 0.945vw;
                        left: 2.023vw;
                    }
                }
                span:nth-child(1) {
                    @include vars.media(pc){
                        top: 24px;
                        width: 23px;
                    }
                    @include vars.media(sp){
                        top: 2.16vw;
                        width: 5.67vw;
                    }
                    @include vars.media(tablet){
                        top: 1.08vw;
                        width: 2.835vw;
                    }
                    background-color: #A5DEFF;
                }
                span:nth-child(2) {
                    @include vars.media(pc){
                        top: 38px;
                        width: 28px;
                    }
                    @include vars.media(sp){
                        top: 5.4vw;
                        width: 6.48vw;
                    }
                    @include vars.media(tablet){
                        top: 2.7vw;
                        width: 3.24vw;
                    }
                    background-color: #448FC1;
                }
                span:nth-child(3) {
                    @include vars.media(pc){
                        top: 53px;
                        width: 33px;
                    }
                    @include vars.media(sp){
                        top: 8.64vw;
                        width: 7.56vw;
                    }
                    @include vars.media(tablet){
                        top: 4.32vw;
                        width: 3.78vw;
                    }
                    background-color: #0172BE;
                }
                span:nth-child(4) {
                    line-height: 1;
                    @include vars.media(pc){
                        top: 71px;
                        @include vars.fz(15);
                    }
                    @include vars.media(sp){
                        top: 11.34vw;
                        font-size: 2.97vw;
                    }
                    @include vars.media(tablet){
                        top: 5.67vw;
                        font-size: 1.49vw;
                    }
                    color: vars.$baseColor01;
                    font-family: sofia-pro, sans-serif;
                    font-weight: 600;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            
            #header_menu_btn_check:checked ~ .menu-btn{
                span {
                    &:nth-child(1) {
                        top: 38px;
                        width: 33px;
                        transform: rotate(45deg);
                        @include vars.media(tablet) {
                            top: 2.7vw;
                            width: 3.78vw;
                        }
                        @include vars.media(sp) {
                            top: 5.4vw;
                            width: 7.56vw;
                        }
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                    &:nth-child(3) {
                        top: 38px;
                        transform: rotate(-45deg);
                        @include vars.media(tablet) {
                            top: 2.7vw;
                        }
                        @include vars.media(sp) {
                            top: 5.4vw;
                        }
                    }
                }
            }
            #header_menu_btn_check:checked ~ .header_menu_nav {
                top: 100px;
                opacity: 1;
                @include vars.media(tablet) {
                    top: 6.75vw;
                }
                @include vars.media(sp) {
                    top: 13.5vw;
                }
            }
            #header_menu_btn_check:checked ~ .overlay {
                @include vars.media(pc) {
                    height: 100vh;
                    opacity: 1;
                }
            }
        }
    }
    .header_wrap {
        background-color: vars.$baseColor01;
        position: relative;
        z-index: 3;
        height: 100px;
        @include vars.media(tablet) {
            height: 6.75vw;
            z-index: 999;
        }
        @include vars.media(sp) {
            height: 13.5vw;
            z-index: 999;
        }
        .header_logo {
            position: absolute;
            display: flex;
            align-items: center;
            top: 50%;
            transform: translateY(-50%);
            line-height: 1;
            white-space: nowrap;
            >a{
                // margin-right: 30px;
            }
            p{
                
                &.link01{
                    &:first-child{
                        margin-left: 30px;
                    }

                    margin-right: 10px;
                    @include vars.media(tablet) {
                        display: none;
                    }
                    @include vars.media(sp) {
                        display: none;
                    }

                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                        transition: all 0.2s 0s ease;
                        
                        &::before{
                            content: "";
                            display: block;
                            width: 15px;
                            height: 15px;
                            background: url(/top/img/circle-arrow.svg) center center no-repeat;
                            background-size: 95%;
                            position: relative;
                            margin-right: 5px;
                        }
                        &:hover{
                            color: #0077AC;
                        }
                    }
                    &.style01{
                        margin-left: 30px;
                    }
                }
            }
            span{
                margin-right: 15px;
                padding-right: 15px;
                border-right: 1px solid #C5D0D5;
            }
            @include vars.media(pc){
                left: 105px;
                span {
                    height: 30px;
                    /* vertical-align: middle; */
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: normal;
                    vertical-align: middle;
                    margin-left: 12px;
                    letter-spacing: 0;
                }
            }
            @include vars.media(sp-tab){
                
                left: 10px;
                span {
                    border-right: none;
                padding: 0;
                margin: 0;
                    // display: none;
                    font-size: 11px;
                    vertical-align: middle;
                    padding-left: 5px;
                }
                @include vars.media(sp){
                    width: 60%;
                }
                img{
                    max-width: 100%;
                    &.mini{
                        max-width: 100%;
                    }
                }
            }
            
            img {
                vertical-align:middle;
            }
        }
        .header_contact {
            position: absolute;
            display: flex;
            top: 0;
            right: 0;
            background-color: vars.$baseColor01;
            @include vars.media(sp-tab){
                display: none;
            }
            .leftBox{
                padding: 0 20px;
                display: flex;
                .btn_purpose{
                    p{
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    
                }
            }
            .phone {
                padding: 0 35px;
                line-height: 1.25;
                // margin-bottom: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p{
                    line-height: 0.5;
                    
                }
                p:nth-child(1){
                    font-family: sofia-pro, sans-serif;
                    font-weight: 600;
                    font-style: normal;
                    @include vars.fz(33);
                    padding-top: 5px;
                    .icon {
                        width: 0.8em;
                        height: 0.8em;
                        vertical-align: middle;
                        margin-right: 8px;
                        fill: vars.$themeColor02;
                    }
                    span {
                        vertical-align: middle;
                        display: inline-block;
                        margin-bottom: 3px;
                    }
                }
                p:nth-child(2) {
                    line-height: 1.8;
                    margin-top: 0;
                    @include vars.fz(13);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        display: inline-block;
                        // background-color: vars.$themeColor02;
                        // color: vars.$baseColor01;
                        // padding: 5px 8px;
                        // border-radius: 11px;
                        // vertical-align: middle;
                        padding-left: 5px;
                        @include vars.fz(14);
                    }
                }
                
            }
            .btn_purpose {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                
                p{
                    &.link01{
                        margin-bottom: 7px;
                        a{
                            @include vars.fz(14);
                            line-height: 1.5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;
                            transition: all 0.2s 0s ease;
                            border-radius: 50px;
                            border: solid 2px #C5D0D5;
                            width:130px;
                            padding: 3px 5px;
                            box-sizing: border-box;
                            &:hover{
                                background-color: #C5D0D5;
                                color: #0077AC;
                            }
                        }
                        &.style01{
                            a{

                                background-color: #e3e3e3;
                                border-radius: 50px;
                                border: none;
                                border: solid 2px #d5d5d5;
                                transition: all 0.2s 0s ease;
                                &:hover{
                                    color: #333;
                                    background-color: #c6c6c6;
                                    border: solid 2px #c6c6c6;
                                }
                            }
                        }
                    }
                    &.link02{
                        a{
                            @include vars.fz(14);
                            line-height: 1.5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;
                            transition: all 0.2s 0s ease;
                            border-radius: 50px;
                            background-color: #0077AC;
                            color: #fff;
                            border: solid 2px #0077AC;
                            width:130px;
                            box-sizing: border-box;
                            padding: 3px 5px;
                            &:hover{
                                background-color: #fff;
                                color: #0077AC;
                            }
                        }
                    }
                }
                
            }
            .btn_entry {
                width: 120px;
                height: 100px;
                background-color: vars.$themeColor05;
                text-align: center;
                @include vars.fz(12);
                position: relative;
                transition: all 0.5s 0s ease;
                &.style01{
                    a{
                        background-color: #004B95;
                        transition: all 0.5s 0s ease;
                        &:hover {
                            @include vars.media(pc) {
                                background-color: #2567a7;
                            }
                        }
                    }
                    
                }
                &.biz{
                    p{
                        img{
                            width: 25px;
                            height: auto;
                            margin-bottom: 5px;
                        }
                    }
                }
                &:hover {
                    @include vars.media(pc) {
                        background-color: #e47b26;
                    }
                }
                a {
                    color: vars.$baseColor01;
                    font-weight: bold;
                    width: 100%;
                    height: 100%;
                    display: block;
                    p {
                        position: absolute;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        line-height: 1.25;
    
                        svg,img {
                            width: 25.7px;
                            height: 25.7px;
                            display: block;
                            margin: 0 auto 8px;
                        }
                        span {
                            display: block;
                            @include vars.fz(15);
                        }
                    }
                }
            }
        }
    }
}


.lower {
    @include vars.media(pc) {
        .header_menu_nav_ind {
            .toggle {
                display: none;
            }
        }
        .header_menu_nav_biz {
            .toggle {
                display: none;
            }
        }
    }
}

@keyframes navAnime{
    0% {
      line-height: 0;
    }
    100% {
      line-height: 1.8;
    }
}
@keyframes navAnimeClose{
    0% {
      line-height: 1.8;
      opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
      line-height: 0;
      opacity: 0;
    }
}

.contents_inner{
    padding-top: 150px;
    margin-top:-150px;
    @include vars.media(sp) {
        padding-top: 80px;
        margin-top:-80px;
    }
}