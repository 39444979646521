@charset "utf-8";
@use "variable" as vars;
/*////////////////////////////////////////////////////

  フォント・文字サイズ設定

////////////////////////////////////////////////////*/
html {
  font-size: 62.5%;
  background-color: vars.$baseColor01;
  scroll-behavior: auto;
  overflow-x: hidden;
  &.__fixed {
    overflow: hidden !important;
    height: 100% !important;
  }
}
body {
  /*font size自動調整をきる*/
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: "Yu Gothic UI", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", Arial, Helvetica, Verdana, sans-serif;
  line-height: 1.8;
  @include vars.fz(15);
  letter-spacing: 0.1em;
  color: vars.$baseColor02;
  background: vars.$baseColor01 0 0 repeat;
  @include vars.media(sp){
    @include vars.fz(14);
    letter-spacing: 0.03em
  }
  overflow-x: hidden;
  &.__fixed {
    overflow: hidden !important;
    height: 100% !important;
  }
  &:before,
  &:after {
    opacity: 0;
    content: '';
    pointer-events: none;
    position: fixed;
  }
  &.disable-js {
    &:before {
      opacity: 1;
    }
  }
}
/*////////////////////////////////////////////////////

  その他

////////////////////////////////////////////////////*/
p{
  letter-spacing: 0.02em;
}

a {
  color: vars.$baseColor02;
  text-decoration: none;
}

table{
  line-height: 1.8; //bodyに合わせておく
}
