@charset "utf-8";
@use "variable" as vars;

/*////////////////////////////////////////////////////

    フッター

////////////////////////////////////////////////////*/

// #ad {
//     animation: flow_bg_slide 30s linear infinite;
//     @include vars.media(pc){
//         padding: 4.5vw 0;
//         background: url(/top/img/flow_bg.svg) center bottom repeat-x;
//     }
//     @include vars.media(tablet){
//         background-image: url(/top/img/flow_bg.svg);
//         background-position: bottom;
//         background-repeat: repeat-x;
//         background-size: 50%;
//         padding-bottom: 6vw;
//     }
//     @include vars.media(sp){
//         padding: 9.26vw 0;
//         background: url(/top/img/flow_bg_sp.svg) center bottom repeat-x;
//     }
//     .application {
//         overflow: hidden;
//         @include vars.media(tab-pc){
//             display: flex;
//             justify-content: flex-end;
//             position: relative;
//             &::after {
//             content: url(/top/img/ico_kamome-1.svg);
//             position: absolute;
//             top: 2.92vw;
//             left: 2.92vw;
//             @include vars.media(tablet) {
//                 left: 1vw;
//             }
//         }
//     }
//     h2 {
//         @include vars.media(tab-pc){
//             font-size: clamp(16px, 1.3vw, 18px);
//         }
//         @include vars.media(sp){
//             @include vars.fz(16);
//             margin-bottom: 7.2vw;
//         }
//         font-weight: bold;
//         &::after{
//             content: '';
//             display: inline-block;
//             background: linear-gradient(90deg, vars.$baseColor01 0%, vars.$baseColor01 50%, vars.$themeColor01 50%, vars.$themeColor01 100%);
//             vertical-align: middle;
//             margin-left: 0.58vw;
//             @include vars.media(tab-pc){
//                 width: 25px;
//                 height: 3px;
//             }
//             @include vars.media(sp){
//                 width: 6.75vw;
//                 height: 3px;
//                 margin-left: 2.7vw;
//             }
//         }
//     }
//     &>div {
//         padding: 2.8vw;
//     }
//     .entry {
//         @include vars.media(tab-pc){
//             width: calc(39% - 5.6vw);
//             height: calc(22vw - 5.6vw);
//             max-height: 230px;
//         }
//         @include vars.media(sp){
//             width: calc(95% - 10.8vw);
//             margin: 0 auto;
//             padding: 8.5vw 5.4vw;
//         }
//         background-color: #E1EDF3;
//         &>a{
//             display: block;
//             transition: all .3s ease;
//             @include vars.media(tab-pc){
//                 width: 26.5vw;
//                 height: 6.1vw;
//                 margin: 4.7vw auto;
//                 @include vars.media(pcL){
//                     width: 363px;
//                     height: 83.5px;
//                     margin: 64.4px auto;            
//                 }
//             }
//             @include vars.media(sp){
//                 width: 100%;
//                 padding: 8.4vw 0;
//                 margin: 0 auto;
//                 text-align: center;
//             }
//             background-color: vars.$themeColor05;
//             position: relative;
//             @include vars.media(pc) {
//                 &:hover {
//                     background-color:#e47b26;
//                 }
//             }
//             .application_btn {
//                 @include vars.center(xy);
//                 display: flex;
//                 align-items: center;
//                 color: vars.$baseColor01;
//                 font-weight: bold;
//                 white-space: nowrap;
//                 @include vars.media(tab-pc){
//                     font-size: clamp(21px, 1.7vw, 23px);
//                 }
//                 @include vars.media(sp){
//                     font-size: 3.78vw;
//                 }
//                 svg {
//                     margin-right: 0.5em;
//                     @include vars.media(tab-pc){
//                         width: 1.9vw;
//                         height: 1.9vw;
//                         @include vars.media(pcL){
//                         width: 26px;
//                         height: 26px;
//                         }
//                     }
//                     @include vars.media(sp){
//                         width: 6.9vw;
//                         height: 6.9vw;
//                     }
//                 }
//             }
//         }
//     }
//     .campaign {
//             background-color: #EFC129;
//             @include vars.media(tab-pc){
//                 width: calc(50.5% - 5.6vw);
//             }
//             @include vars.media(sp){
//                 width: calc(95% - 10.8vw);
//                 margin: 0 auto;
//                 padding: 8.5vw 5.4vw;
//             }
//             .bnr_wrap {
//                 @include vars.media(tab-pc){
//                     display: flex;
//                     justify-content: space-between;
//                     width: 45.26vw;
//                     margin: 3.65vw auto 0;
//                     @include vars.media(pcL){
//                         margin-top: 50px;
//                         justify-content: space-around;
//                     }
//                 }
//                 @include vars.media(sp){
//                     width: 94%;
//                     margin: 0 auto;
//                 }
//                 .bnr {
//                     @include vars.media(tab-pc){
//                         width: 21.9vw;
//                         margin: 0;
//                         @include vars.media(pcL){
//                             width: 300px;
//                         }
//                     }
//                     @include vars.media(sp){
//                         &:nth-child(1) {
//                             margin-bottom: 4vw;
//                         }
//                     }
//                     img {
//                         width: 100%;
//                         height: auto;
//                         transition: all .3s ease;
//                         @include vars.media(pc) {
//                             &:hover {
//                             opacity: 0.8;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &.member {
//         .campaign {
//             width: 70%;
//             padding-bottom: 3vw;
//             .bnr_wrap {
//                 justify-content: space-between;
//                 width: 72%;
//                 .bnr {
//                     width: 21.9vw;
//                 }
//             }
//         }
//     }
//     // @keyframes flow_bg_slide {
//     //     0% { background-position: 0 bottom;}
//     //     100% { background-position: -1366px bottom;}
//     // }
// }
#inquiry {
    padding: 4vw 0 6.2vw;
    @include vars.media(sp){
      padding: 8.3vw 0 6.2vw;
    }
    .top_cont_tit {
      margin: 0 auto 2.5vw;
      @include vars.media(sp){
        margin: 0 auto 6.8vw;
      }
    }
    .name {
      @include vars.fz(19);
      font-weight: bold;
      text-align: center;
      margin: 0 0 2.5vw;
      @include vars.media(sp){
        font-size: 4.32vw;
        margin: 0 0 6.8vw;
      }
    }
    .inquiry_wrap {
      width: 80%;
      @include vars.media(pcSS) {
        width: 90%;
      }
      @include vars.media(sp-tab) {
        width: 95%;
      }
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @include vars.media(sp) {
        flex-wrap: wrap;
      }
      &>div {
        width: 32%;
        @include vars.media(sp) {
          width: 48.5%;
          padding-bottom: 5.9vw;
          &:first-child {
            width: 100%;
          }
        }
        h2 {
          text-align: center;
          padding: 1.3vw 0;
          background-color: #E1EDF3;
          font-size: clamp(14px, 1.2vw, 16px);
          @include vars.media(sp){
            padding: 2.16vw 0;
            font-size: 3.8vw;
          }
          font-weight: bold;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: #C8DFEC transparent transparent transparent;
            @include vars.center(x);
            border-width: 0.8vw 0.8vw 0 0.8vw;
            bottom: -0.8vw;
            @include vars.media(sp){
              border-width: 2.97vw 2.97vw 0 2.97vw;
              bottom: -2.97vw;            
            }
          }
        }
        .way {
          text-align: center;
          @include vars.media(tab-pc) {
            height: 5vw;
            margin-top: 30px;
          }
          @include vars.media(pcL) {
            padding: 0 1vw;
          }
          @include vars.media(sp) {
            margin-top: 1rem;
          }
        }
        .hours {
          margin-top: 10px;
          font-size: clamp(10px, 0.88vw, 12px);
          @include vars.media(sp){
            margin-top: 0;
            font-size: 3.24vw;
            white-space: nowrap;
            line-height: 1.3;
            font-weight: normal;      
          }
          display: flex;
          align-items: center;
          justify-content: center;
          span {
              display: inline-block;
              background-color: vars.$themeColor02;
              color: vars.$baseColor01;
              vertical-align: middle;
              padding: 0.37vw 0.58vw;
              margin-right: 0.44vw;
              border-radius: 0.8vw;
              font-size: clamp(8px, 0.73vw, 10px);
              @include vars.media(sp) {
                padding: 1.5vw;
                margin-right: 0.44vw;
                border-radius: 2.7vw;
                font-size: 2.7vw;              
              }
              line-height: 1;
          }
        }
        &.phone {
          .way {
            font-family: sofia-pro, sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: clamp(36px, 2.92vw, 40px);
            @include vars.media(tablet) {
              font-size: 3.5vw;
            }
            @include vars.media(sp) {
              font-size: 9.45vw;
            } 
            .icon {
              width: 0.8em;
              height: 0.8em;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }
        &.mail {
          h2 {
            @include vars.media(sp) {
              margin-bottom: 6.67vw;
            }
          }
          .way.btn {
            width: 88%;
            margin-right: auto;
            margin-left: auto;
            @include vars.media(sp){
              width: 100%;
              height: 26.2vw;
              margin-bottom: 2rem;
            }
            a {
              display: block;
              background-color: vars.$themeColor03;
              color: vars.$baseColor01;
              font-size:clamp(14px, 1.17vw, 16px) ;
              font-weight: bold;
              padding: 1.3vw 0;
              transition: all .3s ease;
              position: relative;
              overflow: hidden;
              text-align: center;
              @include vars.media(sp){
                font-size:4.32vw ;
                padding: 4.266vw 0 2.16vw;
                text-align: center;
                line-height: 1.3;
              }
              .icon {
                width: 2.52vw;
                height: 1.79vw;
                vertical-align: middle;
                margin-right: 0.55vw;
                @include vars.media(sp){
                  display: block;
                  width: 9.3vw;
                  height: 6.6vw;
                  margin: 0 auto 2.7vw;
                }
              }
              @include vars.media(pc){
                &:hover {
                  padding-left: 1vw;
                  .icon {
                    margin-right: 0.37vw;
                  }
                  &::after {
                    left: 100%;
                  }
                }
                &::after {
                  content: '';
                  width: 100%;
                  height: 100%;
                  display: block;
                  background-color: vars.$themeColor01;
                  position: absolute;
                  opacity: 0.7;
                  top: 0;
                  left: -100%;
                  z-index: 0;
                  transition: all .3s ease-in;
                }
              }
            }
          }
          .hours {
            @include vars.media(sp){
              display: block;
              text-align: center;
              span {
                margin-bottom: 5px;
              }
            }
          }
        }
        &.chat {
          h2 {
            @include vars.media(sp) {
              margin-bottom: 6.67vw;
            }
          }
          @include vars.fz(16);
          font-weight: bold;
          text-align: center;
          .way {
            @include vars.media(sp) {
              height: 26.2vw;
              border-top: 1px solid #EBEBEB;
              border-bottom: 1px solid #EBEBEB;
              margin-bottom: calc(2rem - 2px);
            }
            .icon {
              width: 6.643vw;
              height: 4.93vw;
              @include vars.media(sp) {
                width: 13.64vw;
                height: 10.1vw;
                display: block;
                margin: 4.4vw auto 1.8vw;
              }
              vertical-align: middle;
            }
          }
          .hours {
            @include vars.media(sp){
              display: block;
              text-align: center;
              span {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    } 
  }
.footer{
    @include vars.media(pc) {
        background-color: #E1EDF3;
        background-image: url(/common/img/wave_white.svg);
        background-size: 100%;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
    nav {
        background-color: vars.$baseColor02;
        .footer_util {
            width: 80%;
            max-width: 1100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include vars.media(tablet){
                width: 95%;
            }
            @include vars.media(sp){
                width: 100%;
            }
            li {
                @include vars.media(tab-pc){
                    font-size: clamp(11px, 0.95vw, 13px);
                    width: 20%;
                    text-align: center;
                }
                @include vars.media(sp){
                    width: calc(50% - 1px);
                    border-bottom: 1px solid vars.$baseColor01;
                    text-align: center;
                    font-size: 3.51vw;
                    &:nth-child(even){
                        border-left: 1px solid vars.$baseColor01;
                    }
                    &:nth-child(5){
                        width: 100%;
                    }
                }
                @include vars.media(pc) {
                    transition: all .3s ease;
                    &:hover {
                        background-color: #585858;
                    }
                }
                a {
                    display: block;
                    color: vars.$baseColor01;
                    padding: 12px 0;
                    line-height: 1;
                    @include vars.media(sp){
                        padding: 2.7vw 0;
                    }
                }
            }
        }
    }
    #sitemap {
        @include vars.media(sp) {
            display: none;
        }
        width: 80%;
        max-width: 1100px;
        margin: 35px auto 50px;
        display: flex;
        justify-content: space-between;
        @include vars.media(tablet) {
          width: 100%;
        }
        &>div {
            width: 20%;
            text-align: center;
            &.member{
              width: 40%;
              >ul{
                width: 100%;
              }
              li{
                margin-right: 0.5em;
              }
            }
            &>ul {
                margin: 0 auto;
                display: inline-block;
                text-align: left;
                li {
                    @include vars.fz(11);
                    font-weight: bold;  
                    line-height: 1;
                    margin-bottom: 10px; 
                    a {
                      
                        @include vars.media(pc) {
                            transition: all .3s ease;
                            &:hover {
                                color: #4699D1;
                            }
                        }
                    }
                    ul {
                        margin: 10px 0 0;
                        li {
                          a{
                            font-weight: normal;
                          }
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 7px;
                                height: 2px;
                                background-color: vars.$themeColor01;
                                vertical-align: middle;
                                margin-right: 0.5em;
                            }
                            &.second{
                              a{
                                font-weight: normal;
                              }
                              &::before {
                                content: '';
                                display: inline-block;
                                width: 2px;
                                height: 2px;
                                background-color: vars.$themeColor02;
                                vertical-align: middle;
                                margin-right: 0.5em;
                                margin-left: 0.5em;
                              }
                            }
                        }
                    }
                    .wrapMember{
                      div{
                        &:first-child{
                          margin-right: 1vw;
                        }
                        &:last-child{
                          margin-top: 1em;
                        }
                      }
                      display: flex;
                      justify-content: space-around;
                    }
                }
            }
        }
    }
    .footer_info {
        @include vars.media(tab-pc){
            width: 80%;
            @include vars.media(pcSS){
                width: 90%;
            }
            max-width: 1100px;
            margin: 0 auto;
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
            &::after {
                content: url(/top/img/ico_kamome-1.svg);
                transform: rotateY(180deg);
                // flex-grow: 2;
                @include vars.media(tablet){
                  display: none;
                }
            }
            .footer_logo {
                width: 31%;
                @include vars.media(tablet){
                    width: 48%;
                }
                img {
                    width: 100%;
                }
            }
            .footer_info_wrap {
                @include vars.media(tablet) {
                    text-align: center;
                }
                .name {
                    font-size: clamp(13px, 1vw, 15px);
                    @include vars.media(tablet){
                        font-size: clamp(13px, 1.7vw, 15px);
                    }
                    font-weight: bold;
                    margin: 0;
                    text-align: center;
                    line-height: 1;
                }
                .phone {
                    padding: 0 2.5vw;
                    @include vars.media(pcSS){
                        padding: 0 1.5vw;
                    }
                    line-height: 1;
                    margin-bottom: 6px;
                    p:nth-child(1){
                        font-family: sofia-pro, sans-serif;
                        font-weight: 600;
                        font-style: normal;
                        @include vars.fz(39);
                        @include vars.media(tablet) {
                            font-size: 4vw;
                        }
                        margin-bottom: 5px;
                        .icon {
                            width: 0.8em;
                            height: 0.8em;
                            vertical-align: middle;
                            margin-right: 8px;
                        }
                        span {
                            vertical-align: middle;
                            display: inline-block;
                            margin-bottom: 3px;
                        }
                    }
                    p:nth-child(2) {
                        margin-top: 0;
                        @include vars.fz(11);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span {
                            display: inline-block;
                            background-color: vars.$themeColor02;
                            color: vars.$baseColor01;
                            padding: 5px 8px;
                            border-radius: 11px;
                            vertical-align: middle;
                            @include vars.fz(10);
                            margin-right: 0.5em;
                        }
                        @include vars.media(pcSS){
                            @include vars.fz(10);
                            span {
                                @include vars.fz(8);
                            }
                        }
                    }
                }
            }
        }
        @include vars.media(sp){
            background-color: vars.$baseColor01;
            padding: 9.8vw 0 13.5vw;
            text-align: center;
            .footer_logo {
                width: 73vw;
                margin: 0 auto 4.5vw;
                img {
                    width: 100%;
                }
            }
            .name {
                font-size: 4vw;
                font-weight: bold;
                line-height: 1;
            }
            .phone {
                line-height: 1;
                margin: 0 auto 7.8vw;
                width: 90%;
                p:nth-child(1){
                    font-family: sofia-pro, sans-serif;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 10.8vw;
                    margin-bottom: 5px;
                    .icon {
                        width: 0.8em;
                        height: 0.8em;
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                    span {
                        vertical-align: middle;
                        display: inline-block;
                        margin-bottom: 3px;
                    }
                }
                p:nth-child(2) {
                    margin: 0 auto;
                    font-size: 3vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        display: inline-block;
                        background-color: vars.$themeColor02;
                        color: vars.$baseColor01;
                        padding: 5px 8px;
                        border-radius: 11px;
                        vertical-align: middle;
                        font-size: 2.7vw;
                        margin-right: 5px;
                    }
                }
            }
        }
        .footer_info_list {
            display: flex;
            align-items: center;
            @include vars.media(tablet){
                width: 100%;
            }
            @include vars.media(sp-tab){
                justify-content: center;
            }
            li {
                margin-right: 15px;
            }
            
        }
    }
}

.copylight{
    letter-spacing: 0;
    @include vars.fz(12);
    @include vars.media(sp) {
        font-size: 3.24vw;
        margin-bottom: 16vw;
    }
    background-color: vars.$themeColor03;
    color: vars.$baseColor01;
    text-align: center;
    padding: 18px 0;
    line-height: 1;
}

.btn_scrolltop{
    background-color: #E6E6E6;
    padding: 0.88vw 0;
    @include vars.media(sp){
        line-height: 1;
        padding: 3.24vw 0;
        font-size: clamp(12px, 3.8vw, 14px);
    }
    @include vars.media(tablet){
        font-size: clamp(12px, 1.82vw, 14px);
    }
    @include vars.media(pc){
        font-size: clamp(12px, 1.02vw, 14px);
        transition: all .3s ease;
        &:hover {
            opacity: 0.8;
        }
    }
    
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    span {
        &::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0.3vw 0.44vw 0.3vw;
            border-color: transparent transparent vars.$themeColor03 transparent;
            vertical-align: middle;
            margin-left: 0.37vw;
            @include vars.media(sp){
                border-width: 0 1.08vw 1.62vw 1.08vw;               
                margin-left: 2.5vw;
            }
        }
    }
}

.fixed_bnr {
    @include vars.media(pc) {
        display: none;
    }
    @include vars.media(sp-tab) {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: vars.$baseColor01;
        width: 100%;
        z-index: 999;
        ul {
            width: 98%;
            display: flex;
            justify-content: space-between;
            padding: 1% 0;
            margin: 0 auto;
            .biz &{
              li{
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                a{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  padding: 8px 0;
                  color: #fff;
                  font-size: 1.4rem;
                  line-height: 1.3;
                  font-weight: bold;
                }
              }
            }
            li {
                width: 49%;
                a {
                    display: block;
                    text-align: center;
                    color: vars.$baseColor01;
                    font-weight: bold;
                    padding: 2.7vw 0;
                    font-size: 4.05vw;
                    @include vars.media(tablet) {
                        padding: 1vw 0;
                        font-size: 1.8vw;
                    }
                    svg {
                        vertical-align: middle;
                    }
                }
                &:nth-child(1) {
                    background-color: vars.$themeColor03;
                    svg ,img{
                        width: 5.86vw;
                        height: 4.3vw;
                        margin-right: 1vw;
                        @include vars.media(tablet) {
                            width: 2.93vw;
                            height: 2.1vw;
                            margin-right: 0.5vw;
                        }
                    }
                }
                &.biz{
                  svg ,img{
                    width: 6.17vw;
                    height: 6.17vw;
                      margin-right: 1vw;
                      @include vars.media(tablet) {
                        width: 3.08vw;
                        height: 3.08vw;
                          margin-right: 0.5vw;
                      }
                  }
                }
                &:nth-child(2) {
                    background-color: vars.$themeColor05;
                    svg ,img {
                        width: 6.17vw;
                        height: 6.17vw;
                        margin-right: 1vw;
                        @include vars.media(tablet) {
                            width: 3.08vw;
                            height: 3.08vw;
                            margin-right: 0.5vw;                            
                        }
                    }
                }
            }
        }
        

    }
}
