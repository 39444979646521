@charset "utf-8";
@use "sass:math";
/*////////////////////////////////////////////////////

    .設定

////////////////////////////////////////////////////*/
//　==================================================
//　色
//　==================================================
// ベースカラー
$baseColor01: #fff;
$baseColor02: #333;
$baseColor03: #C8DFEC; // 薄青
$baseColor04: #646464; // 文字用グレー

// テーマカラー
$themeColor01: #448FC1; // ブルー
$themeColor02: #0077AC; // ネイビーブルー1
$themeColor03: #0C3F71; // ネイビーブルー2
$themeColor04: #f1f3f8; // 薄いネイビーブルー
$themeColor05: #E44E00; // 差し色オレンジ

$bizBgColor: #E3E9EC;
$commonBgColor: #E1EDF3;

/*スペース*/
$spaceS: 5px;
$spaceR: 10px;
$spaceM: 15px;
$spaceL: 20px;

$fSizeXSS: 9;
$fSizeXS: 10;
$fSizeSS:11;
$fSizeS:12;
$fSizeR:13;
$fSizeM:14;
$fSizeL:15;
$fSizeLL:16;
$fSizeXL:17;

$EnfSizeR:18;
$EnfSizeM:22;
$EnfSizeL:30;
$EnfSizeLL:36;



//　==================================================
//　フォント
//　==================================================
// フォントサイズのミックスイン指定
@mixin fz($size){
    font-size: $size + px;
    font-size: math.div($size , 10) * 1rem;
}

//　==================================================
//　メディアクエリ設定
//　==================================================
@mixin media($point, $character: screen){
    @if $character == print {
      $character: print, screen;
    }@else {
      $character:  only screen;
    }
    @if $point == sp{
      @media #{$character} and (max-width:767px){
        @content;
      }
    }@else if $point == spS{
      @media #{$character} and (max-width: 374px){
        @content;
      }
    }@else if $point == spL{
      @media #{$character} and (min-width:480px) and (max-width: 767px){
        @content;
      }
    }@else if $point == landscape{ //デバイスが横向きの時
      @media #{$character} and (orientation: landscape) {
        @content;
      }
    }@else if $point == sp-tab{
      @media #{$character} and (max-width: 1099px) {
        @content;
      }
    }@else if $point == tab-pc{
      @media #{$character} and (min-width: 768px){
        @content;
      }
    }@else if $point == tablet{
      @media #{$character} and (min-width: 768px) and (max-width:1099px){
        @content;
      }
    }@else if $point == pc {
      @media #{$character} and (min-width: 1100px){
        @content;
      }
    }@else if $point == pcS{
      @media #{$character} and (min-width: 1100px) and (max-width: 1399px){
        @content;
      }
    }@else if $point == pcSS{
      @media #{$character} and (min-width: 1100px) and (max-width: 1280px){
        @content;
      }
    }@else if $point == pcL{
      @media #{$character} and (min-width: 1400px){
        @content;
      }
    }@else if $point == pcLL{
      @media #{$character} and (min-width: 1500px){
        @content;
      }
    }@else if $point == print{
      @media print{
        @content;
      }
    }@else if $point == hover {
      @media (hover: hover) {
        @content;
      }
    }
}

//　----------------------------------------------------------
//　@extend
//　----------------------------------------------------------
%clearfix {
	*zoom: 1;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin clearfix {
	*zoom: 1;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	
}

%clearCenter {// フロートリストのセンタリングの解除
	left: auto;
	float: none;
}
%box-sizing{
	-webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
}
%spImg {
	width: 100px;
	height: auto;
}

//　==================================================
/*中央寄せ $direction x：横方向 y：縦方向 xy：縦横方向*/
//　==================================================
@mixin center($direction) {
	position: absolute;
	@if $direction==xy {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@else if $direction==x {
		left: 50%;
		transform: translateX(-50%);
	}
	@else if $direction==y {
		top: 50%;
		transform: translateY(-50%);
	}
}

// アニメーション設定
@mixin anim($property: all, $time: 0.5s, $timing: ease) {
	-webkit-transition: $property $time $timing;
	-moz-transition: $property $time $timing;
	-ms-transition: $property $time $timing;
	-o-transition: $property $time $timing;
	transition: $property $time $timing;
}

@keyframes fade-in {
	0% {
	display: none;
	opacity: 0;
	}

	1% {
	display: block;
	opacity: 0;
	}

	100% {
	display: block;
	opacity: 1;
	}
}

@-moz-keyframes fade-in {
	0% {
	display: none;
	opacity: 0;
	}

	1% {
	display: block;
	opacity: 0;
	}

	100% {
	display: block;
	opacity: 1;
	}
}

@-webkit-keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}


