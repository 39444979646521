@charset "utf-8";
@use "variable" as vars;

/*////////////////////////////////////////////////////

　下層ページ共通 各種部品

////////////////////////////////////////////////////*/
.lower{
    p{
        &.f-small{
            font-size: 1.2rem;
        }
        &.f-big{
            line-height: 1;
            font-size: 1.8rem;
        }
    }
    .link_area_list {
        margin-bottom: 40px;
        @include vars.media(tab-pc) {
            // margin: 40px 0 68px 20px;
        }
        @include vars.media(sp) {
            margin:0 15px 15px 0;
            // margin: 25px 0 25px 13px;
        }
        li {
            line-height: 1.3;
            position: relative;
            // padding-left: 28px;
            display: flex;
            align-content: center;
            align-items: center;
            margin-bottom: 15px;
            @include vars.media(tab-pc) {
            margin-bottom: 20px;
            }
            @include vars.media(sp) {
            margin-bottom: 15px;
            }
            &:last-child {
            margin-bottom: 0;
            }
            &::before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                background: url(/top/img/circle-arrow.svg) center center no-repeat;
                background-size: 95%;
                position: relative;
                margin-right: 5px;
                @include vars.media(sp) {
                    margin-right: 10px;
                }
            }
                a {
                    transition: all .3s ease;
                    &:hover {
                        color: vars.$themeColor02;
                        text-decoration: none;
                    }
                }
        }
    }
    
    .faq_area dd{
        .link_area_list{
            a{
                color: #333;
                transition: all 0.2s 0s ease;
                &:hover{
                    color: vars.$themeColor02;
                    transition: all 0.2s 0s ease;
                    text-decoration: none;
                }
            }
            
        }
    }

    &.biz {
        /*法人向けページ調整*/
        .hero {
            @include vars.media(pc){
                background-image: url(/common/img/lower_biz_hero.svg);
            }
            @include vars.media(sp-tab){
                background-image: url(/common/img/lower_biz_hero_sp.svg);
            }
        }
        .breadcrumb {
            background-color: #E3E9EC;
            &.white {
                background-color: vars.$baseColor01;
            }
        }
        &.white {
            .hero {
                @include vars.media(pc){
                    background-image: url(/common/img/lower_hero.svg);
                }
                @include vars.media(sp-tab){
                    background-image: url(/common/img/lower_hero_sp.svg);
                }
            }
            .breadcrumb {
                background-color: vars.$baseColor01;
            }
        }
        .top_sec {
            background-color: #E3E9EC;
            &.bg_w{
                background-color: #fff;
            }
            & + section {
                @include vars.media(pc){
                    padding-top: 70px;
                }
                @include vars.media(tablet){
                    padding-top: 60px;
                }
                @include vars.media(sp){
                    padding-top: 50px;
                }
            }
        }
        .bg_glay {
            background-color: #E3E9EC;
        }
        .lower_tit01 {
            &::before,
            &::after {
                background-color: vars.$themeColor03;
            }
        }
        .lower_tit02 {
            svg {
                fill: #032A44;
            }
        }
        .ul_common {
            &>li {
                &::before {
                    background-color: vars.$themeColor03;
                }
            }
        }
        .tbl_vertical,
        .tbl_scroll,
        .tbl_price{
            &>table{
                thead {
                    th{
                        background-color: vars.$baseColor02; 
                    }
                }
                tbody {
                    th{
                        color: #fff;
                        background-color: vars.$themeColor03;
                        @include vars.media(tab-pc) {
                        }
                    }
                    td.td_text {
                        a {
                            color: #448FC1;
                        }
                    }
                    
                }
            }
        }
        .toggle {
            dt {
                background: vars.$themeColor02;
                // background-position: 5px bottom;
                span {
                    &::before,
                    &::after {
                    background-color: vars.$themeColor02;
                    }
                }
            }
        }
    }
    &.ind {
        /*個人向けページ調整*/
        .hero {
            @include vars.media(pc){
                background-image: url(/common/img/lower_ind_hero.svg);
            }
            @include vars.media(sp-tab){
                background-image: url(/common/img/lower_ind_hero_sp.svg);
            }            
        }
        .breadcrumb {
            background-color: #E1EDF3;
        }
        .top_sec {
            background-color: #E1EDF3;
            & + section {
                @include vars.media(pc){
                    padding-top: 70px;
                }
                @include vars.media(tablet){
                    padding-top: 60px;
                }
                @include vars.media(sp){
                    padding-top: 50px;
                }
            }
        }
        //section 背景色
        .bg_glay {
            background-color: #E1EDF3;
        }
    }

    .hero {
        line-height: 1;
        &.style01{
            @include vars.media(sp) {
                padding-top: 4vw;
            }
        }
        @include vars.media(pc){
            width: calc(100% - 9.97vw);
            height: 12.4vw;
            padding: 0 9.97vw 0 0;
            background-image: url(/common/img/lower_hero.svg);
            background-position: bottom;
            background-size: 100%;
        }
        @include vars.media(sp-tab){
            width: 100%;
            height: 17.4vw;
            padding-top: 1.5vw;
            background-image: url(/common/img/lower_hero_sp.svg);
            background-position: bottom;
            background-size: 100%;
        }
        @include vars.media(tablet){
            height: 16vw;
            padding-top: 1.5vw;
        }
        .txt {
            padding-top: 2.3vw;
            &.style01{
                padding-top: 1.5vw;
                
            }
            @include vars.media(pc) {
                text-align: right;
            }
            @include vars.media(sp-tab){
                text-align: center;
            }
            .__sub {
                @include vars.media(pc) {
                font-size: clamp(18px, 1.46vw, 20px);
                }
                @include vars.media(tablet) {
                    font-size: 18px;
                }
                @include vars.media(sp) {
                    font-size: clamp(16px, 4.86vw, 18px);
                }
                color: vars.$themeColor02;
                font-family: '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif;
            }
            .__main {
                @include vars.media(pc) {
                    font-size: 40px;
                }
                @include vars.media(tablet) {
                    font-size: 40px;
                }
                @include vars.media(sp) {
                    font-size: clamp(23px, 6.75vw, 25px);
                }
                font-family: sofia-pro, sans-serif;
                font-style: italic;
                font-weight: 400;
                margin: 0;
                line-height: 1;
                color: vars.$themeColor03;
            }
            .__txt {
                margin-top: 20px;
                font-size: 12px;
                background-color: #fff;
                box-sizing: border-box;
                padding: 10px 15px;
                // border-radius: 5px;
                line-height: 1.3;
                display: inline-block;
                border: solid 2px #E3E9EC;
                a{
                    color: #448FC1;
                    transition: all 0.2s 0s ease;
                    &:hover{
                        color: #4699D1;
                    }
                }
                @include vars.media(sp) {
                    max-width: 90%;
                    margin: 15px auto 0;
                    padding: 10px;
                }
            }
        }
    }
    //パンくずリスト
    .breadcrumb {
        &.style01{
            @include vars.media(sp) {
            padding-top: 2em;
            }
        }
        @include vars.media(sp-tab) {
            overflow-x: scroll;
            word-break: keep-all;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
        }
        ol {
            margin: 0 auto;
            @include vars.media(pc) {
                width: 80%;
                max-width: 1100px;
            }
            @include vars.media(tablet) {
                width: 90%;
            }
            @include vars.media(sp) {
                width: 95%;
            }
            display: flex;
            padding-bottom: 10px;
            li {
                position: relative;
                @include vars.media(pc) {
                    @include vars.fz(14);
                }
                @include vars.media(sp-tab) {
                    @include vars.fz(11);
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: 0.5em;
                    height: 0.5em;
                    transform: rotate(45deg);
                    border-top: solid 2px vars.$themeColor02;
                    border-right: solid 2px vars.$themeColor02;
                    margin: 0 10px;
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                }
                a {
                    position: relative;
                    &:hover {
                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: vars.$themeColor02;
                            position: absolute;
                            bottom: -6px;
                            left: 0;
                        }
                    }
                }
                span {
                    position: relative;
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: vars.$themeColor02;
                        position: absolute;
                        bottom: -6px;
                        left: 0;
                    }                    
                }
            }
        }
    }
    //下層ページタイトル
    .lower_pagetit {
        text-align: center;
        font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
        @include vars.media(pc) {
            @include vars.fz(32);
            margin-bottom: 4vw;
        }
        @include vars.media(tablet){
            font-size: clamp(24px, 3.38vw, 26px);
            margin-bottom: 3.5vw;
        }
        @include vars.media(sp){
            font-size: 5.3vw;
            margin-bottom: 8vw;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.2em;
        font-size: 3.2rem;
        &::before,&::after{
            @include vars.media(pc) {
                border-top: 4px solid;
            }
            border-top: 3px solid;
            content: "";
            width: 1.3em;
            color:vars.$themeColor02;
        }
        &::before{
            margin-right: 0.5em;
        }
        &::after{
            margin-left: 0.5em;
        }
    }
    //下層見出し
    .lower_tit01 {
        font-weight: bold;
        text-align: center;
        @include vars.media(pc){
            @include vars.fz(30);
            margin-bottom: 2.5vw;
        }
        @include vars.media(tablet){
            font-size: clamp(24px, 3.38vw, 26px);
            margin-bottom: 2.5vw;
        }
        @include vars.media(sp){
            font-size: 5.3vw;
            line-height: 1;
            margin-bottom: 6.8vw;
            &.fold {
                line-height: 1.35;
            }
        }
        strong {
            color: vars.$themeColor02;
        }
        &::after{
            content: '';
            display: block;
            background: linear-gradient(90deg, vars.$baseColor03 0%, vars.$baseColor03 50%, vars.$themeColor01 50%, vars.$themeColor01 100%);
            @include vars.media(tab-pc){
                width: 54px;
                height: 3px;
                margin: 15px auto 0;
            }
            @include vars.media(sp){
                width: 14.5vw;
                height: 3px;
                margin: 4vw auto 0;
            }
        }
    }
    .lower_tit02 {
        font-weight: bold;
        line-height: 1;
        text-align: center;
        @include vars.media(pc) {
            @include vars.fz(24);
            margin-bottom: 50px;
        }
        @include vars.media(tablet) {
            font-size: clamp(22px, 4vw, 24px);
            margin-bottom: 8vw;
        }
        @include vars.media(sp) {
            font-size: clamp(18px, 5.4vw, 20px);
            margin-bottom: 8vw;
        }
        svg {
            width: 1.8em;
            height: 1.8em;
            vertical-align: middle;
            fill: vars.$themeColor02;
            margin-right: 4px;
        }
    }
    .text + .lower_tit03 {
        margin-top: 40px;
    }
    .lower_tit03 {
        font-weight: bold;
        line-height: 1.2;
        padding: 20px 30px 17px 30px;
        @include vars.fz(20);
        background-size: 34px;
        margin-bottom: 20px;
        background: #F2F2F2 url(/common/img/header-mark_sp.svg) no-repeat;
        background-position : 8px center;
        @include vars.media(sp) {
            margin-bottom: 15px;
            // padding: 20px 0 17px 23px;
            @include vars.fz(19);
            // background-size: 34px;
            // margin-bottom: 20px;
            // background: #F2F2F2 url(/common/img/header-mark_sp.svg) no-repeat;
            // background-position : 8px center;
        }
        
    }
    .lower_tit04 {
        @include vars.fz(16);
        font-weight: bold;
        line-height: 1;
        padding: 10px 0 10px 10px;
        margin-bottom: 25px;
        /* border-left: 5px solid #0077AC; */
        position: relative;
        @include vars.media(sp-tab) {
            line-height: 1.4;
            padding: 5px 10px 5px 10px;
        }
        &::after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: #EBEBEB;
            position: absolute;
            left: 0;
            bottom: -5px;
        }
    }
    .lower_tit05 {
        @include vars.fz(16);
        color: vars.$themeColor02;
        padding: 0 0 0 9px;
        &::before {
            content: "";
            width: 7px;
            height: 2px;
            display: inline-block;
            vertical-align: middle;
            background-color: #C8DFEC;
            margin-right: 5px;
        }
    }

    //テキスト
    .lower_lead {
        line-height: 2.4;
        @include vars.media(tab-pc) {
            text-align: center;
        }
        @include vars.media(pc) {
            @include vars.fz(16);
        }
        @include vars.media(tablet) {
            font-size: clamp(14px, 2.7vw, 16px);
        }
        @include vars.media(sp) {
            padding: 0 15px;
            font-size: clamp(14px, 4.3vw, 16px);
            line-height: 1.8;
        }
    }
    .text {
        line-height: 2.2;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 30px;
        }
        &+ .text {
            margin-top: 30px;
        }
        a {
            color: #448FC1;
            @include vars.media(pc) {
                &:hover {
                    color: vars.$themeColor03;
                    text-decoration: underline;
                }
            }
        }
    }
    
    strong {
        font-weight: bold;
        color: #E44E00;
    }
    em {
        font-weight: bold;
        color: vars.$themeColor03;
        font-style: normal;
    }
    .red {
        color: #E44E00;
    }
    .tag {
        display: inline-block;
        padding: 3px 8px;
        background-color: #E3E9EC;
        @include vars.fz(12);
        font-weight: bold;
        margin-right: 5px;
    }
    .bold {
        font-weight: bold;
    }
    .txt-links{
            color: #448FC1;
            @include vars.media(pc) {
                &:hover {
                    color: vars.$themeColor03;
                    text-decoration: underline;
                }
            }
        }

    //ボタン
    //キャンペーン誘導黄色いボタン
    .cam_btn {
        @include vars.media(sp) {
            width: 100%;
        }
        a {
            display: inline-block;
            padding: 15px 20px;
            background-color: #EFC129;
            color: vars.$baseColor02;
            @include vars.fz(19);
            font-weight: bold;
            @include vars.media(sp) {
                display: block;
            }
            span{
                color: #B00000;
            }
            position: relative;
            &::after {
                content: '';
                width: 0;
                height: 0;
                display: inline-block;
                border-style: solid;
                border-width: 5px 0 5px 8px;
                border-color: transparent transparent transparent vars.$baseColor02;
                vertical-align: middle;
                margin-left: 12px;
                @include vars.media(sp) {
                    @include vars.center(y);
                    right: 15px;
                }
            }
            @include vars.media(pc) {
                &:hover {
                    background-color: #f2cd54;
                }
            }
        }
    }
    //申し込みボタン
    .lower_application_btn {
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.2;
        
        @include vars.media(tab-pc) {
            @include vars.fz(20);
        }
        @include vars.media(sp) {
            @include vars.fz(17);
        }
        @include vars.media(spS) {
            @include vars.fz(16);
        }
        a {
            background-color: vars.$themeColor05;
            color: vars.$baseColor01;
            font-weight: bold;
            display: inline-block;
            transition: all 0.5s 0s ease;
            @include vars.media(tab-pc) {
                padding: 30px 100px;
            }
            @include vars.media(sp) {
                padding: 25px 10px;
            }
            svg {
                width: 1.1em;
                height: 1.1em;
                fill: vars.$baseColor01;
                vertical-align: middle;
                margin-right: 8px;   
            }
            @include vars.media(pc) {
                &:hover {
                    background-color: #e47b26;
                }
            }
        }
        span{
            margin-top: 8px;
            display: block;
            font-size: 12px;
        }
        
    }

    //リスト
    .ul_common {
        padding-left: 17px;
        margin-bottom: 30px;
        @include vars.media(pc) {
            &.ulcol2 {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                    width: 48%;              
                }
            }
        }
        &>li {
            position: relative;
            &::before {
                content: '';
                width: 9px;
                height: 9px;
                display: block;
                border-radius: 50%;
                background-color: vars.$themeColor02;
                position: absolute;
                left: -14px;
                top: 9px;
            }
            strong {
                color: vars.$themeColor01;
            }
        }
        &.gray {
            &>li {
                &::before {
                    width: 5px;
                    height: 5px;
                    background-color: #C5C5C5;
                    top: 11px;
                }
            }
        }
    }
    .ol_common {
        padding-left: 17px;
        margin-bottom: 30px;
        counter-reset: num;
        &>li {
            margin-bottom: 15px;
            position: relative;
            &::before {
                counter-increment: num;
                content: counter(num)'.';
                color: vars.$themeColor02;
                font-weight: bold;
                position: absolute;
                left: -17px;
                top: 0;
            }
            .lower_tit05{
                font-size: 1.4rem;
            }
        }
    }
    //注釈用リスト
    .annotation {
        margin-left: 1.5em;
        margin-bottom: 30px;
        &>li {
            text-indent: -1.5em;
            @include vars.fz(13);
            &.type01 {
                text-indent: -1em;
            }
        }
        &.type01 {
            margin-left: 1em;
            &>li {
                text-indent: -1em;
            }
        }
    }
    //注釈用dlリスト
    .ann {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        @include vars.fz(13);
        margin-bottom: 30px;
        &.style02{
            @include vars.fz(15); 
            @include vars.media(sp) {
                @include vars.fz(14); 
            }
        }
        @include vars.media(sp) {
            width: 95%;
            margin: 0 auto 30px;
        }
        dt {
            width: 1.5em;
        }
        dd {
            a{
                color: #448FC1;
            }
            width: calc(100% - 1.5em);
            &.text {
                margin-bottom: 0;
            }
        }
        &.col2 {
            @include vars.media(tab-pc) {
                margin-left: 20px;
            }
        }
    }

    //テーブルの中のリストのマージン削除
    .tbl_vertical,
    .tbl_scroll {
        .ul_common {
            margin-bottom: 0;
        }
    }
    //テーブル SP縦積み
    .tbl_vertical {
        margin-bottom: 30px;
        &>table{
            width: 100%;
            thead {
                tr{
                    th{
                        background-color: vars.$themeColor03;
                        border-bottom: 1px solid #EAEAEA;
                    }
                }
                
            }
            td{
                img{
                    width: 100%;
                    height: auto;
                }
            }
            @include vars.media(tab-pc) {
                border: 1px solid #EAEAEA;
                border-collapse: separate;
                tr{
                    th,td{
                        border-right: 1px solid #EAEAEA;
                        border-bottom: 1px solid #EAEAEA;
                        .text{
                            margin-bottom: 0;
                        }
                    }
                    th{
                        font-weight: bold;
                        vertical-align: middle;
                        padding: 15px;
                        color: vars.$baseColor01;
                        background-color: #4699D1;                    
                        &:last-child {
                            border-right: none;
                        }
                    }
                    td {
                        text-align: left;
                        vertical-align: middle;
                        padding: 15px;
                        background-color: vars.$baseColor01;
                        @include vars.media(sp) {
                            padding: 10px 20px;
                            line-height: 1.2;
                        }
                        &:last-child {
                            border-right: none;
                        }
                    }
                    &:last-child {
                        th{
                            border-bottom: none;
                        }
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
            @include vars.media(sp) {
                tr {
                    margin-bottom: 12px;
                    &:first-child {
                        th {
                            margin-top: 0;
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }
                }
                th,td {
                    display: block;
                    text-align: left;
                    
                }
                th {
                    background-color: #F5F5F5;
                    // border-bottom: 2px solid #EAEAEA;
                    padding: 5px 0 5px 15px;
                    margin-top: 30px;
                    color: #032A44;
                    font-weight: bold;
                    position: relative;
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 7px;
                        height: 3px;
                        margin-right: 7px;
                        background-color: #4699D1;
                        vertical-align: middle;
                        @include vars.center(y);
                        left: 0;
                    }
                }
                td {
                    padding: 14px 15px 0 15px;
                }
            }
        }
    }

    .tbl_scroll {
        margin-bottom: 30px;
        &>table{
            width: 100%;
            @include vars.media(sp) {
                max-width: 1000px;
            }
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            border-left: 1px solid #EAEAEA;
            border-collapse: separate;
            th,td{
                a{
                    color: #448FC1;
                    @include vars.media(pc) {
                        &:hover {
                            color: vars.$themeColor03;
                            text-decoration: underline;
                        }
                    }
                }
            }
            thead {
                th{
                    background-color: vars.$themeColor03;
                    
                }
            }
            tbody {
                th{
                    background-color: #4699D1;
                    white-space: nowrap;
                }
                tr {
                    &:last-child {
                        th{
                            border-bottom: none;
                        }
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
            tr{
                th,td{
                    border-right: 1px solid #EAEAEA;
                    border-bottom: 1px solid #EAEAEA;
                    @include vars.media(sp) {
                        white-space: nowrap;
                    }
                }
                th{
                    font-weight: bold;
                    vertical-align: middle;
                    padding: 15px;
                    color: vars.$baseColor01;  
                    @include vars.media(sp) {
                        padding: 10px 20px;
                        line-height: 1.2;
                    }
                }
                td {
                    text-align: left;
                    vertical-align: middle;
                    padding: 15px;
                    @include vars.media(sp) {
                        padding: 10px 20px;
                        line-height: 1.2;
                    }
                }
            }
        }
    }


    .tbl_price {
        margin-bottom: 30px;
        &>table{
            width: 100%;
            
            @include vars.media(sp) {
                // max-width: 1000px;
            }
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            border-left: 1px solid #EAEAEA;
            border-collapse: separate;
            thead {
                th{
                    background-color: vars.$baseColor02; 
                    
                }
            }
            tbody {
                th{
                    background-color: #4699D1;
                    span{
                        font-size: 1.2rem;
                        line-height: 1.4;
                        vertical-align: text-bottom;
                        display: block;
                        &.no-br{
                            display: inline-block;
                        }
                        @include vars.media(sp) {
                            &.no-br{
                                display: block;
                            }
                            font-size: 1.1rem;
                        }
                    }
                }
                tr {
                    th{
                        padding: 15px;
                        @include vars.media(sp) {
                            padding: 20px 5px;
                        }
                    }
                    &:last-child {
                        th{
                            border-bottom: none;
                        }
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
            tr{
                th,td{
                    border-right: 1px solid #EAEAEA;
                    border-bottom: 1px solid #EAEAEA;
                    
                }
                th{
                    font-weight: bold;
                    vertical-align: middle;
                    padding: 5px;  
                    color: vars.$baseColor01;  
                    @include vars.media(sp) {
                        padding: 10px 5px;
                        line-height: 1.2;
                    }
                }
                td {
                    line-height: 1.4;
                    text-align: center;
                    vertical-align: middle;
                    padding: 15px 5px;
                    font-weight: bold;
                    font-size: 2.0rem;
                    @include vars.media(sp) {
                        font-size: 1.6rem;
                        padding: 10px;
                        line-height: 1.2;
                    }
                    b{
                        font-size: 1.3rem;
                        font-weight: bold;
                        color: vars.$themeColor01; 
                        display: block;
                        @include vars.media(sp) {
                            font-size: 1.3rem;
                            display: block;
                        }
                    }
                    span{
                        vertical-align: text-bottom;
                        font-weight: normal;
                        font-size: 1.1rem;
                        line-height: 1.6;
                        display: block;
                        @include vars.media(sp) {
                        }
                    }
                    strong{
                        font-size: 1.7rem;
                        @include vars.media(sp) {
                            font-size: 1.4rem;
                        }
                    }
                    &.f-small{
                        font-size: 1.3rem;
                        padding: 15px;
                        font-weight: normal;
                    }
                    &.vertical{
                        padding: 0;
                        display: flex;
                        justify-content: space-around;
                        @include vars.media(sp) {
                            flex-direction: column;
                        }
                        p{
                            flex: 1 0 auto;
                            text-align: center;
                            border-right: 1px solid #EAEAEA;
                            padding: 15px 5px;
                            &:last-child{
                                border-right: none;
                            }
                            @include vars.media(sp) {
                                padding: 10px;
                                flex-direction: column;
                                border-right: none;
                                border-bottom: 1px solid #EAEAEA;
                                &:last-child{
                                    border-bottom: none;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    }
    
    //企業向けパートナー募集
    #partner {
        width: 80%;
        max-width: 1100px;
        padding-top: 5.33vw;
        @include vars.media(tablet) {
            width: 90%;
        }
        @include vars.media(sp) {
            width: 95%;
        }
        margin: 0 auto;
        img {
            width: 100%;
            height: auto;
            @include vars.media(pc) {
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

/*パートナー募集バナー*/


.add-control{
    position: fixed;
    bottom: 10px;
    left: 0px;
    z-index: 9999;
    @include vars.media(tablet) {
        bottom: 60px;
    }
    @include vars.media(sp) {
        bottom: 65px;
    }
    @include vars.media(spS) {
        bottom: 65px;
    }
    .pt_box{
        
        .partner_bnr{
            a{
                display: inline-block;
                width: 180px;
                @include vars.media(sp) {
                    width: 120px;
                }
                img{
                    width: 100%;
                    height: auto;
                    opacity: 1;
                    padding-bottom: 0;
                }
                &:hover{
                    transition: .2s ;
                    padding-bottom: 5px;
                }
            }
        }
    }
}

 
/* close button */
.action-close {
    position: relative;
    margin-top: 2em;
}
.action-close .pt_btn {
    position: absolute;
    left: 5px;
    top: -10px;
    @include vars.media(sp) {
        left: 5px;
        top: -10px;
    }
    .round_btn {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        border: 2px solid #0C3F71; /* 枠の調整 */
        border-radius: 50%;  /* 丸みの度合い */
        background: #fff; /* ボタンの背景色 */
        @include vars.media(sp) {
            width: 20px;
            height: 20px;
        }
        &::after,&::before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px; /* 棒の幅（太さ） */
            height: 22px; /* 棒の高さ */
            background: #0C3F71; /* バツ印の色 */
            @include vars.media(sp) {
                height: 13px; /* 棒の高さ */
            }
        }
        &::before {
        transform: translate(-50%,-50%) rotate(45deg);
        }
        
        &::after {
        transform: translate(-50%,-50%) rotate(-45deg);
        }
    }
}
.action-close .pt_box {
}
 
/* checkbox 非表示 */
.add-control .checkbox {
    display: none;
}
 
/* close button's control */
.add-control .action-close #close:checked ~ .pt_btn {
    display: none;
}
.add-control .action-close #close:checked ~ .pt_box {
    display: none;
}

/* ------------------------------------------------
  コピー禁止
------------------------------------------------ */
.ncpy{
	user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
}

.boxEnclosure01 {
	padding: 25px;
    box-sizing: border-box;
	background-color: #F2F2F2;
	border: 1px solid #e3e3e3;
    @include vars.media(sp) {
        padding: 15px;
    }
    .phone {
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
        span{
            font-weight: bold;
            font-size: 2rem;
            display: block;
            i{
                font-size: 1.5rem;
                font-style: normal;
                vertical-align: middle;
                @include vars.media(sp) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.alignC{
    .mr10{
        @include vars.media(sp) {
            margin-right: 0!important;
        }
    }
}

.common_btn{
    a{
        box-sizing: border-box;
    }
    &.style01{
        a{
            background-color: vars.$themeColor03;
            color: #fff;
            &:hover{
                background-color: #005f88;
                transition: all 0.5s 0s ease;
            }
            &::after {
                background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
                background-size: 95%;
              }
        }
    }
}

.section_inner{
    .lower_pagetit{
        &.sp01{
            margin-bottom: 0.5vw;
            @include vars.media(sp) {
                margin-bottom: 1.3vw;
            }
        }
    }
    .fukidashi{
        text-align: center;
        margin-bottom: 2vw;
        @include vars.media(sp) {
            margin-bottom: 3vw;
        }
        span{
            font-style: normal;
            display: block;
            font-weight: bold;
            font-size: 15px;
            background-color: vars.$themeColor02;
            color: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            display: inline-block;
            // @include vars.center(x);
        }
      }
}

.tbl_vertical,.tbl_price {
    .fukidashi{
        margin-top: 10px;
        display: block;
        text-align: left;
        &.center{
            text-align: center;
        }
        &.bg_W{
            span{
                background-color: #fff;
                color: #333;
                &::before{
                    border-color: transparent transparent #fff transparent;
                }
            }
        }
        span{
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            background-color: vars.$themeColor02;
            color: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            display: inline-block!important;
            line-height: 1;
            position: relative;
            text-align: center;
            &::before{
                content: "";
                position: absolute;
                top: -7px;
                left: 10px;
                width: 0px;
                height: 0px;
                margin: auto;
                border-style: solid;
                border-color: transparent transparent vars.$themeColor02 transparent;
                border-width: 0px 8px 8px 8px;
            }
            &.arrow_c{
                &::before{
                    left: 0;
                    right: 0;
                }
            }
        }
      }
}

// チャットボタン調整
#chatplusview #eye_catcher .button-hide {
    position: absolute;
    top: -10px !important;
    right: 33px !important;
    font-size: 13px!important;
    line-height: 1!important;
    color: #0077AC!important;
    opacity: 1!important;
    text-shadow: none!important;
    background: #fff!important;
    padding: 3px!important;
    border-radius: 30px!important;
    width: 20px!important;
    height: 20px!important;
    box-sizing: border-box!important;
    display: flex!important;
    align-content: center!important;
    align-items: center!important;
    justify-content: center!important;
    border: 2px solid #0077AC!important;
}
