@charset "utf-8";
@use "variable" as vars;

/*////////////////////////////////////////////////////

  例外のレイアウト

  ※各ページ固有の、ちょっとしたスタイルの追加に使用します

////////////////////////////////////////////////////*/

/* ------------------------------------------------
  デバイスごとの表示切り分け
------------------------------------------------ */
.only-sp {
  display: none !important;
  @include vars.media(sp){
      display: block !important;
  }
}
.only-pc {
  display: none !important;
  @include vars.media(pc){
      display: block !important;
  }
}
.only-tablet {
  display: none !important;
  @include vars.media(tablet){
      display: block !important;
  }
}
.no-sp {
  @include vars.media(sp){
      display: none !important;
  }
}
.no-pc {
  @include vars.media(pc){
      display: none !important;
  }
}
.no-tablet {
  @include vars.media(tablet){
      display: none !important;
  }
}

/* ------------------------------------------------
  テキストの調整
------------------------------------------------ */
.color01{
  color: vars.$themeColor01;
}
.color02{
  color: vars.$themeColor02;
}
.fw_b{
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

/* ------------------------------------------------
  余白の調整
------------------------------------------------ */
.mt_0{
  margin-top: 0 !important;
}
.mt_l{
  @include vars.media(tab-pc, print){
    margin-top: 80px;
  }
  @include vars.media(sp){
    margin-top: 40px;
  }
}
.mt_m{
  @include vars.media(tab-pc, print){
    margin-top: 40px;
  }
  @include vars.media(sp){
    margin-top: 20px;
  }
}
.mt_s{
  @include vars.media(tab-pc, print){
    margin-top: 20px;
  }
  @include vars.media(sp){
    margin-top: 10px;
  }
}

.mb_0{
  margin-bottom: 0 !important;
}
.mb_l{
  @include vars.media(tab-pc, print){
    margin-bottom: 80px;
  }
  @include vars.media(sp){
    margin-bottom: 40px;
  }
}
.mb_m{
  @include vars.media(tab-pc, print){
    margin-bottom: 40px;
  }
  @include vars.media(sp){
    margin-bottom: 20px;
  }
}
.mb_s{
  @include vars.media(tab-pc, print){
    margin-bottom: 20px;
  }
  @include vars.media(sp){
    margin-bottom: 10px;
  }
}
/* ------------------------------------------------
	utility margin class
------------------------------------------------ */
.mt0 {margin-top: 0 !important;}
.mt5 {margin-top: 5px !important;}
.mt10 {margin-top: 10px !important;}
.mt15 {margin-top: 15px !important;}
.mt20 {margin-top: 20px !important;}
.mt25 {margin-top: 25px !important;}
.mt30 {margin-top: 30px !important;}
.mt35 {margin-top: 35px !important;}
.mt40 {margin-top: 40px !important;}
.mt45 {margin-top: 45px !important;}
.mt50 {margin-top: 50px !important;}
.mt55 {margin-top: 55px !important;}
.mt60 {margin-top: 60px !important;}
.mt65 {margin-top: 65px !important;}
.mt70 {margin-top: 70px !important;}
.mt75 {margin-top: 75px !important;}
.mt80 {margin-top: 80px !important;}
.mt85 {margin-top: 85px !important;}
.mt90 {margin-top: 90px !important;}
.mt95 {margin-top: 95px !important;}
.mt100 {margin-top: 100px !important;}
.mt110 {margin-top: 110px !important;}

.mb0 {margin-bottom: 0 !important;}
.mb5 {margin-bottom: 5px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb25 {margin-bottom: 25px !important;}
.mb30 {margin-bottom: 30px !important;}
.mb35 {margin-bottom: 35px !important;}
.mb40 {margin-bottom: 40px !important;}
.mb45 {margin-bottom: 45px !important;}
.mb50 {margin-bottom: 50px !important;}
.mb55 {margin-bottom: 55px !important;}
.mb60 {margin-bottom: 60px !important;}
.mb65 {margin-bottom: 65px !important;}
.mb70 {margin-bottom: 70px !important;}
.mb75 {margin-bottom: 75px !important;}
.mb80 {margin-bottom: 80px !important;}
.mb85 {margin-bottom: 85px !important;}
.mb90 {margin-bottom: 90px !important;}
.mb95 {margin-bottom: 95px !important;}
.mb100 {margin-bottom: 100px !important;}

.ml0 {margin-left: 0 !important;}
.ml5 {margin-left: 5px !important;}
.ml10 {margin-left: 10px !important;}
.ml15 {margin-left: 15px !important;}
.ml20 {margin-left: 20px !important;}
.ml25 {margin-left: 25px !important;}
.ml30 {margin-left: 30px !important;}
.ml35 {margin-left: 35px !important;}
.ml40 {margin-left: 40px !important;}
.ml45 {margin-left: 45px !important;}
.ml50 {margin-left: 50px !important;}
.ml55 {margin-left: 55px !important;}
.ml60 {margin-left: 60px !important;}
.ml65 {margin-left: 65px !important;}
.ml70 {margin-left: 70px !important;}
.ml75 {margin-left: 75px !important;}
.ml80 {margin-left: 80px !important;}
.ml85 {margin-left: 85px !important;}
.ml90 {margin-left: 90px !important;}
.ml95 {margin-left: 95px !important;}
.ml100 {margin-left: 100px !important;}

.mr0 {margin-right: 0 !important;}
.mr5 {margin-right: 5px !important;}
.mr10 {margin-right: 10px !important;}
.mr15 {margin-right: 15px !important;}
.mr20 {margin-right: 20px !important;}
.mr25 {margin-right: 25px !important;}
.mr30 {margin-right: 30px !important;}
.mr35 {margin-right: 35px !important;}
.mr40 {margin-right: 40px !important;}
.mr45 {margin-right: 45px !important;}
.mr50 {margin-right: 50px !important;}
.mr55 {margin-right: 55px !important;}
.mr60 {margin-right: 60px !important;}
.mr65 {margin-right: 65px !important;}
.mr70 {margin-right: 70px !important;}
.mr75 {margin-right: 75px !important;}
.mr80 {margin-right: 80px !important;}
.mr85 {margin-right: 85px !important;}
.mr90 {margin-right: 90px !important;}
.mr95 {margin-right: 95px !important;}
.mr100 {margin-right: 100px !important;}

.pt0 {padding-top: 0 !important;}
.pb0 {padding-bottom: 0 !important;}

.alignC {
	text-align: center !important;
}
.alignR {
	text-align: right !important;
}
.alignL {
	text-align: left !important;
}
/* ------------------------------------------------
  電話
------------------------------------------------ */
@include vars.media(tab-pc) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}