@charset "utf-8";
@use "variable" as vars;

/*////////////////////////////////////////////////////

　全ページ共通

////////////////////////////////////////////////////*/
#main{
    margin-top: 0;
    color: vars.$baseColor02;
    @include vars.media(pc){
        padding-top: 100px;
    }
    @include vars.media(sp){
        padding-top: 13.5vw;
    }
    @include vars.media(tablet){
        padding-top: 6.75vw;
    }
}



