@charset "utf-8";
@use "variable" as vars;

/*////////////////////////////////////////////////////

    セクション

////////////////////////////////////////////////////*/
section.top_sec {
  @include vars.media(pc) {
    padding-top: 60px;
  }
  @include vars.media(tablet) {
    padding-top: 40px;
  }
  @include vars.media(sp) {
    padding-top: 35px;
  }
}
.section_inner {
  margin: 0 auto;
  @include vars.media(pc) {
      width: 80%;
      max-width: 1100px;
      padding: 0 0 70px;
  }
  @include vars.media(tablet) {
      width: 90%;
      padding: 0 0 50px;
  }
  @include vars.media(sp) {
      width: 92%;
      padding: 0 0 30px;
  }
  .section_box {
    @include vars.media(tab-pc) {
      padding: 0 20px;
    }
  }
}

/*////////////////////////////////////////////////////
    左右2カラム
////////////////////////////////////////////////////*/
// 単なる左右2等分
.column02{
  display: flex;
  padding: 0 20px;
  margin-bottom: 30px;
  @include vars.media(pc){
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @include vars.media(sp-tab){
    padding: 0;
    flex-direction: column;
  }
  &:nth-child(n + 2){
    margin-top: 3.5rem;
    @include vars.media(sp){
      margin-top: 2.5rem;
    }
  }
  &>div{
    @include vars.media(pc){
      width: calc(50% - 10px);
      margin-top: 0;
    }
    @include vars.media(sp-tab){
      width: 100%;
    }
    &:nth-child(n+2){
      @include vars.media(sp-tab){
        margin-top: 2.8rem;
      }
    }
  }
}
// PC/Tablet3等分　SP1カラム
.column03{
  display: flex;
  padding: 0 20px;
  margin-bottom: 30px;
  @include vars.media(tab-pc){
    justify-content: space-between;
  }
  @include vars.media(sp){
    flex-direction: column;
  }
  &:nth-child(n + 2){
    margin-top: 3.5rem;
    @include vars.media(sp){
      margin-top: 2.5rem;
    }
  }
  &>div{
    @include vars.media(tab-pc){
      width: 32%;
      margin-top: 0;
    }
    @include vars.media(sp){
      width: 100%;
    }
    &:nth-child(2),
    &:nth-child(3){
      @include vars.media(sp){
        margin-top: 2.8rem;
      }
    }
  }
}
// PC/Tablet1:2等分　SP1カラム
.column2to1{
  display: flex;
  margin-bottom: 30px;
  @include vars.media(tab-pc){
    justify-content: space-between;
  }
  @include vars.media(sp){
    flex-direction: column;
  }
  &>div{
    @include vars.media(sp){
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.single {
      @include vars.media(tab-pc){
        width: 32%;
        margin-top: 0;
      }
      @include vars.media(sp){
        width: 100%;
      }
    }
    &.double {
      @include vars.media(tab-pc){
        width: 66%;
        margin-top: 0;
      }
      @include vars.media(sp){
        width: 100%;
      }      
    }
  }
}
// 1カラム 画像中央配置
.img_center {
  text-align: center;
  margin-bottom: 30px;
  display: block;
  img {
    @include vars.media(tab-pc) {
      width: auto;
      height: auto;
      max-width: 100%;
    }
    @include vars.media(sp) {
      width: 100%;
      height: auto;
    }
  }
}
.caption {
  text-align: center;
  @include vars.fz(18);
  font-weight: bold;
  margin-top: 15px;
  line-height: 1.5;
  @include vars.media(sp) {
    @include vars.fz(17);
  }
}

/*////////////////////////////////////////////////////
    トグル
////////////////////////////////////////////////////*/
.toggle {
  dt {
    font-weight: bold;
    line-height: 1;
    color: vars.$baseColor01;
    position: relative;
    @include vars.media(tab-pc) {
        padding: 15px;
        @include vars.fz(17);
        margin-bottom: 40px;
    }
    @include vars.media(sp) {
        padding: 10px;
        @include vars.fz(15);
        background-size: 34px;
        margin-bottom: 30px;
    }
    background: vars.$themeColor03;
    background-position : 5px bottom;
    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: vars.$baseColor01;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      &::before,
      &::after {
        content: '';
        display: block;
        width: 14px;
        height: 3px;
        background-color: vars.$themeColor03;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        transition: all 0.5s 0s ease;
        @include vars.media(sp) {
          width: 10px;
          height: 2px;
        }
      }
      &::after {
        transform: translate(-50%) rotate(0deg);
      }
      @include vars.media(sp) {
        width: 20px;
        height: 20px;
      }
    }
    &.close {
      span {
        &::after {
          transform: translate(-50%) rotate(90deg);
        }
      }
    }
  }
  dd {
    display: none;
  }
}

/*////////////////////////////////////////////////////
    枠線付きエリア
////////////////////////////////////////////////////*/
.linebox{
  border: solid 1px vars.$themeColor01;
  margin-bottom: 30px;
  @include vars.media(pc){
    padding: 3rem;
  }
  @include vars.media(sp-tab){
    padding: 1.8rem;
  }
}

/*////////////////////////////////////////////////////
    色付きエリア
////////////////////////////////////////////////////*/
.bgcbox{
  background-color: vars.$themeColor04;
  margin-bottom: 30px;
  @include vars.media(pc){
    padding: 3rem;
  }
  @include vars.media(sp-tab){
    padding: 1.8rem;
  }
  &:nth-child(n + 2){
    margin-top: 2.5rem;
    @include vars.media(sp){
      margin-top: 1rem;
    }
  }
}

/*////////////////////////////////////////////////////
    ボタン
////////////////////////////////////////////////////*/
.common_btn {
  margin-bottom: 30px;
  @include vars.media(tab-pc) {
    display: inline-block;
  }
  background-color: vars.$baseColor01;
  transition: all 0.5s 0s ease;
  line-height: 1;
  filter: drop-shadow(0px 1px 4px rgba(0,0,0,0.2));
  @include vars.media(pc){
    &:hover {
      background-color: vars.$themeColor03;
      a {
        
        border-left: none;
        color: vars.$baseColor01;
        border-left: 0.51vw solid vars.$themeColor03;
        &::after {
          background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
          background-size: 95%;
          right: 0.6vw;
        }
      }
    }
    &:hover + .img .img-hover {
      opacity: 1;
    }
  }
  &.active {
    background-color: vars.$themeColor03;
    a {
      border-left: none;
      color: vars.$baseColor01;
      border-left: 0.51vw solid vars.$themeColor03;
      &::after {
        background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
        background-size: 95%;
        right: 0.6vw;
      }
    }
  }
  &:hover + .img .img-hover {
    opacity: 1;
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    i{
      display: inline-block;
      width: 30px;
      margin-right: 5px;
      img{
        width: 100%;
        height: auto;
      }
    }
    @include vars.media(tab-pc) {
      @include vars.fz(15);
      padding: 22px 60px;
      border-left: 7px solid vars.$themeColor02;
    }
    @include vars.media(sp) {
      width: 100%;
      font-size: 4.05vw;
      padding: 6.2vw 0;
      border-left: 1.89vw solid vars.$themeColor02;
      text-align: center;
    }
    font-weight: bold;
    text-align: center;
    
    &::after {
      content: '';
      display: block;
      @include vars.media(tab-pc) {
        width: 15px;
        height: 15px;
        right: 10px;
      }
      @include vars.media(sp) {
        width: 4.86vw;
        height: 4.86vw;
        right: 2.7vw;
      }
      background: url(/top/img/circle-arrow.svg) center center no-repeat;
      background-size: 95%;
      @include vars.center(y);
    }
    
  }

  &.under{
    a{
      &::after {
        background: url(/top/img/circle-arrow_under.svg) center center no-repeat;
      }
    }
  }
}
/*////////////////////////////////////////////////////
    ページ内リンクに戻るボタン
////////////////////////////////////////////////////*/
.return_index {
  position: relative;
  @include vars.media(tab-pc) {
    text-align: right;
    a {
      &:hover {
        background-color: vars.$themeColor03;
        color: vars.$baseColor01;
        svg {
          fill: vars.$baseColor01;
        }
        &::after {
          background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
          background-size: 95%;
        }
      }
    }
  }
  @include vars.media(sp) {
    margin: 30px 0 20px;
  }
  a {
    display: inline-block;
    padding: 17px 0;
    background-color: vars.$baseColor01;
    color: vars.$baseColor02;
    @include vars.fz(15);
    font-weight: bold;
    filter: drop-shadow(0px 1px 4px rgba(0,0,0,0.2));
    text-align: center;
    @include vars.media(tab-pc) {
      min-width: 320px;
    }
    @include vars.media(sp) {
      width: 100%;
    }
    svg {
      fill: vars.$themeColor02;
      width: 1em;
      height: 1em;
      margin-right: 10px;
      vertical-align: middle;
    }
    &::after {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background: url(/top/img/circle-arrow.svg) center center no-repeat;
      background-size: 95%;
      position: absolute;
      top: calc(50% - 9px);
      transform: rotate(-90deg);
      right: 17px;
    }
  }
  
  
}
/*////////////////////////////////////////////////////
    よくある質問（開閉無し）
////////////////////////////////////////////////////*/
.faq_area {
  dt {
    margin: 0 20px 20px 20px;
    padding: 15px 0 15px 30px;
    background-color: #E1EDF3;
    font-size: clamp(13px, 1.1vw, 15px);
    font-weight: bold;
    position: relative;
    line-height: 1.3;
    @include vars.media(sp) {
      padding: 10px 0 10px 30px;
      font-size: clamp(12px, 3.78vw, 14px);
      padding-right: 10px;
    }
    &::before {
      content: 'Q';
      font-size: 26px;
      font-family: sofia-pro, sans-serif;
      font-weight: bold;
      color: vars.$baseColor01;
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: vars.$themeColor02;
      position: absolute;
      left: -17px;
      top: 5px;
      text-align: center;
      line-height: 1.1;
      @include vars.media(sp) {
        left: -13px;
        top: 5px;
      }
    }
  } 
  dd {
    margin: 0 0 70px 20px;
    font-size: clamp(12px, 1vw, 14px);
    line-height: 2.2;
    @include vars.media(sp) {
      margin: 0 20px 40px 20px;
      font-size: clamp(12px, 3.78vw, 14px);
    }
    a {
      color: #448FC1;
      @include vars.media(pc) {
          &:hover {
              color: vars.$themeColor03;
              text-decoration: underline;
          }
      }
    }
  }   
}
/*////////////////////////////////////////////////////
    下層ページ遷移リンク
////////////////////////////////////////////////////*/
.lower_link {
  @include vars.media(pc) {
    margin-bottom: 30px;
  }
  @include vars.media(tablet) {
    margin-bottom: 20px;
  }
  &.style01{
    li{
      @include vars.media(pc) {
        width: 24%;
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    
    li {
      position: relative;
      @include vars.media(pc) {
        width: 19%;
        margin-right: 1%;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
      @include vars.media(tablet) {
        width: 33%;
        margin-right: 0.5%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @include vars.media(sp) {
        width: 49%;
        margin-right: 1%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .common_btn{
        margin-bottom: 10px;
        width: 100%;
        position: relative;
        a {
          padding: 10px 0 10px 10px;
          display: block;
          position: relative;   
          box-sizing: border-box;
          text-align: left;
          @include vars.media(sp) {
            text-align: left;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 6.8vw;
            &::after {
              right: 1.3vw;
            }
          }
        }
      }
    }
  }
  
}

.boxContent.mypage{
  width: 100%;
  box-sizing: border-box;
  width: 1000px;
  margin: 0 auto;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
  @include vars.media(sp) {
    width: 90%;
  }
}

.boxContent.mypage>.boxInner{
  display: flex;
  color: #333;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s 0s ease;
  border-radius: 5px;
}

.boxContent.mypage>a:hover,
.boxContent>a:hover{
  background-color: #dee3e7;
  transition: all 0.5s 0s ease;
}

.boxContent.mypage>.boxInner .boxImg{
  border-bottom: none;
  width: 10%;
  height: auto;
  @include vars.media(sp) {
    width: 25%;
    margin-bottom: 10px;
  }
}

.boxContent.mypage>.boxInner .boxImg img{
  padding: 0;
  width: 100%;
  height: auto;
}

.boxContent.mypage>.boxInner>.boxTxt {
  padding: 0 30px 0 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 67%;
  align-items: center;
  
}
a{
  &.boxInner{
    &:hover{
      p.btn01{
        background-color:#0C3F71;
      }
    }
  }
}
.boxTxt{
  h3{
    color: #448FC1;
  }
  p{
    text-align: center;
    padding-bottom: 10px;
  }
  p.btn01{
    background-color: #448FC1;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
    transition: all 0.5s 0s ease;
  }
} 
.boxContent.mt20{
  margin-top: 20px;
}
.ulBtn01>li{
  
  padding: 0 10px;
}



@media screen and (max-width: 769px){
  .boxContent.mypage>.boxInner>.boxTxt {
      padding: 0 15px;
      width: 100%;
  }
  .boxContent.mypage>.boxInner {
      flex-direction: column;
      padding: 15px;
  }
  .boxContent.mypage>.boxInner>.boxTxt .tit02{
      margin-bottom: 5px;
  }

  .ulBtn01>li:nth-child(2n+1):nth-last-child(2){
      margin: 0 auto 10px;
  }
  .ulBtn01>li{
      padding: 0;
      width: 90%;
  }
  .btn01{
      width: 90%;
  }
  #mv .btn01 a{
      padding: 0 5px;
  }
  
}
@media screen and (max-width: 480px){
  .titPanel01.fontS{
      font-size: 2rem;
  }
}

.mam_disc{
  padding-top: 20px;
  background-color: #E1EDF3;
}

.lower.ind .top_sec.member_top{
  padding-top: 10px;
}

.lower_tit01{
  &.common_inq{
    font-weight: bold;
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 2.5vw;
    @include vars.media(pc){
      @include vars.fz(30);
      line-height: 1
    }
    @include vars.media(tablet){
      @include vars.fz(26);
    }
    @include vars.media(sp){
      @include vars.fz(20);
      margin-bottom: 7.5vw;
      line-height: 1;
      &.fold {
        span {
          line-height: 1.8;
        }
      }
    }
    strong {
      color: vars.$themeColor02;
    }
    &::after{
      content: '';
      display: block;
      background: linear-gradient(90deg, vars.$baseColor03 0%, vars.$baseColor03 50%, vars.$themeColor01 50%, vars.$themeColor01 100%);
      @include vars.media(tab-pc){
        width: 54px;
        height: 3px;
        margin: 25px auto 0;
      }
      @include vars.media(sp){
        width: 14.5vw;
        height: 3px;
        margin: 4vw auto 0;
      }
    }
  }
}

.inq_wrap{
  max-width: 1000px;
  margin: 0 auto;
  @include vars.media(sp){
    padding: 0 10px;
  }
  .btnList{
    // border-bottom: dotted 2px #C5D0D5;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    
    @include vars.media(sp) {
      flex-wrap: wrap;
      padding: 0 0 0;
      justify-content: space-between;
    }
    li{
      
      a{
        background-color: #F5F5F5;
        padding: 19px 78px 19px 60px;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        color: #333;
        font-weight: bold;
        align-items: center;
        align-content: center;
        justify-content: center;
        border-left: 3px solid vars.$themeColor01;
        margin-bottom: 15px;
        position: relative;
        line-height: 1.3;
        font-size: 16px;
        @include vars.media(sp) {
          font-size: 14px;
        }
        
        span{
          height: 22px;
          margin-right: 8px;
          @include vars.media(sp) {
            margin-bottom: 5px;
          }
          img{
            width: 100%;
            height: auto;
          }
        }
        &::after {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background: url(/top/img/circle-arrow.svg) center center no-repeat;
          background-size: 95%;
          @include vars.center(y);
          right: 0.73vw;
          transition: all .3s ease;
          @include vars.media(sp) {
            right: 1.73vw;
          }
        }
        &:hover{
            background-color: vars.$themeColor04;
            transition: all 0.5s 0s ease;
            &::after {
              right: 5px;
            }
        }
      }
      &.group01{
        margin-right: 50px;
        &:last-child{
          margin-right: 0;
        }
        @include vars.media(sp) {
          margin-right: 0;
          width: 48%;
          // margin-bottom: 30px;
        }
        a{
          @include vars.media(sp) {
            flex-direction: column;
            padding:15px 10px;
            span{
              margin-bottom: 10px;
            }
          }
        }
        .txt01{
          padding: 0 10px;
          @include vars.media(sp) {
            padding: 0 10px;
          }
        }
      }
      &.group02{
        @include vars.media(sp) {
          width: 100%;
          margin: 0 auto;
        }
        a{
          @include vars.media(sp) {
            padding: 10px 48px 10px 30px;
          }
        }
      }
      .txt01{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1.3;
        @include vars.media(sp) {
          flex-direction: column;
        }
        span{
          border-radius: 30px;
          margin-right: 5px;
          background-color: vars.$themeColor02;
          padding: 3px 15px;
          box-sizing: border-box;
          color: #fff;
          font-weight: bold;
          font-size: 1.2rem;
          @include vars.media(sp) {
            margin-right: 0;
            margin-bottom: 5px;
          }
        }
      }

      .txt02{
        color: #646464;
        line-height: 1.5;
      }
    }
  }
  .inqRwapArea{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    @include vars.media(sp) {
      flex-direction: column;
    }
    h3{
      font-size: 15px;
      position: relative;
      @include vars.media(sp) {
        margin-bottom: 15px;
      }
      &::after{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        content: "";
        vertical-align: middle;
        right: -15px;
        width: 4px;
        height: 4px;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        @include vars.media(sp) {
          display: none;
        }
      }
    }
    .blk01{
      @include vars.media(sp) {
        margin-bottom: 5px;
      }
      .tit{
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
        line-height: 1;
      }
      .way {
        line-height: 1;
        font-family: sofia-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        @include vars.media(sp) {
         margin-bottom: 15px;
        } 
        .icon {
          width: 0.8em;
          height: 0.8em;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
    }
    .blk02{
      .txt01{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-weight: bold;
        line-height: 1.3;
        margin-bottom: 10px;
        @include vars.media(sp) {
          flex-direction: column;
          text-align: center;
        }
        span{
          border-radius: 30px;
          margin-right: 5px;
          background-color: vars.$themeColor02;
          padding: 3px 15px;
          box-sizing: border-box;
          color: #fff;
          font-weight: bold;
          font-size: 1.2rem;
          @include vars.media(sp) {
            margin-bottom: 5px;
            margin-right: 0;
           
          }
        }
      }
      .txt02{
        color: #646464;
        line-height: 1.5;
        @include vars.media(sp) {
          text-align: center;
        }
      }
    }
  }
}

// フッターお問い合わせバナーエリア改修
.applicationBox{
    margin-top: 120px;
    position: relative;
    @include vars.media(sp) {
      margin-top: 60px;
    }
    &::before{
      content: "";
      background-image: url(/common/img/bg_inq.svg);
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 100%;
      height: 5vw;
      position: absolute;
      top: -5vw;
      // background: transparent;
      // border-top-left-radius: 100%;
      // border-top-right-radius: 100%;
      // background-color: vars.$commonBgColor;
      // border-bottom: none;
    }
    &.member{
      &::before{
        display: none;
      }
    }
    .entryBox{
        background-color: vars.$commonBgColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px 65px;
        box-sizing: border-box;
        position: relative;
        
        @include vars.media(sp) {
          padding: 20px 15px 50px;
        }
        &::after{
          // content: url(/top/img/ico_kamome-1.svg);
          // position: absolute;
          // bottom: 60px;
          // left: 4vw;
          // z-index: 1;
          content: '';
          display: inline-block;
          width: 80px;
          height: 60px;
          background-image: url(/top/img/ico_kamome-1.svg);
          background-size: contain;
          vertical-align: middle;
          background-repeat: no-repeat;
          position: absolute;
          bottom: 50%;
          left: 4vw;
          @include vars.media(sp) {
            width: 50px;
            height: 40px;
          }
        }
        h2{
            margin-bottom: 25px;
            line-height: 1.3;
            text-align: center;
            font-size: 25px;
            span{
              color: #E44E00;
            }
            @include vars.media(sp) {
              font-size: 22px;
              margin-bottom: 15px;
            }
        }
        &>a{
          box-sizing: border-box;
          display: inline-block;
          padding: 15px 80px 15px 70px;
          transition: all .3s ease;
          background-color: vars.$themeColor05;
          position: relative;
          z-index: 2;
          @include vars.media(pc) {
            &:hover {
              background-color:#e47b26;
            }
          }
          @include vars.media(sp) {
            width: 298px;
            padding: 15px;
            display: flex;
            justify-content: center;
          }
        &::after {
          right: 15px;
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
          background-size: 95%;
          @include vars.center(y);
          right: 0.73vw;
          transition: all .3s ease;
          @include vars.media(sp) {
            right: 15px;
          }
        }
        .application_btn {
          display: flex;
          align-items: center;
          color: vars.$baseColor01;
          font-weight: bold;
          white-space: nowrap;
          @include vars.media(tab-pc){
            font-size: clamp(21px, 1.7vw, 23px);
          }      
          @include vars.media(sp){
            font-size: 17px;
          }
          svg {
            margin-right: 3px;
            @include vars.media(tab-pc){
              width: 1.9vw;
              height: 1.9vw;
              @include vars.media(pcL){
                width: 26px;
                height: 26px;
              }
            }
            @include vars.media(sp){
              width: 6.9vw;
              height: 6.9vw;
            }
          }
        }
      }

      &.biz{
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          align-items: stretch;
          box-sizing: border-box;
          @include vars.media(sp){
            flex-direction: column;
          }
          a{
            box-sizing: border-box;
            display: inline-block;
            padding: 15px 80px 15px 70px;
            transition: all .3s ease;
            background-color: vars.$themeColor05;
            position: relative;
            z-index: 2;
            @include vars.media(sp){
              height: 8rem;
            }
            &:nth-child(2){
              margin-left: 20px;
              @include vars.media(sp){
                margin-left: 0;
              }
            }
            @include vars.media(sp){
              width: 100%;
            }
            &.blue{
              background-color: #0C3F71;
              @include vars.media(sp){
                margin-bottom: 15px;
              }
            }
            .application_btn {
              display: flex;
              align-items: center;
              color: vars.$baseColor01;
              font-weight: bold;
              white-space: nowrap;
              line-height: 1.4;
              height: 100%;
              text-align: center;
              justify-content: center;
              @include vars.media(tab-pc){
                font-size: clamp(21px, 1.7vw, 23px);
              }      
              @include vars.media(sp){
                font-size: 17px;
                width: 100%;
              }
              svg ,img{
                margin-right: 10px;
                @include vars.media(tab-pc){
                  width: 1.9vw;
                  height: 1.9vw;
                  @include vars.media(pcL){
                    width: 26px;
                    height: 26px;
                  }
                }
                @include vars.media(sp){
                  width: 6.9vw;
                  height: 6.9vw;
                }
              }
            }
          }
        }
        &::after{
          bottom: 60px;
        }
      }
    }
    .campaignBox{
        animation: flow_bg_slide 30s linear infinite;
        background: url(/top/img/flow_bg.svg) ;
        background-position: top;
        background-repeat: repeat-x;
        // padding-bottom: 6vw;
        // background-size: 89%;
        position: relative;
        top: -30px;
        left: 0;
        padding-top: 50px;
        @include vars.media(sp) {
          background: url(/top/img/flow_bg_sp.svg) center bottom repeat-x;
          // background-size: 190%;
          padding-top: 30px;
        }
        .campainBoxIn{
          background-color: #EFC129;
          border-radius: 5px;
          padding: 30px 40px;
          width: 750px;
          box-sizing: border-box;
          margin: 0 auto;
          text-align: center;
          @include vars.media(sp) {
            width: 95%;
            // padding: 1vw 1.5vw;
            padding: 30px 15px;
          }
          h2{
            position: relative;
            padding: 0 1.7rem;
            font-size: 17px;
            font-weight: bold;
            text-align: center;
            display: inline-block;
            margin-bottom: 20px;
            &::before,
            &::after {
                position: absolute;
                top: 0.8rem;
                height: 1.7rem;
                content: '';
            }
            &::before {
              border-left: solid 2px;
              left: 0;
              transform: rotate(-30deg);
            }
            
            &::after {
                border-right: solid 2px;
                right: 0;
                transform: rotate(30deg);
            }
          }
          .bnr_wrap{
            display: flex;
            justify-content: space-around;
            align-items: center;
            @include vars.media(sp) {
              flex-direction: column;
            }
            p{
              @include vars.media(sp) {
                margin-bottom: 10px;
                &:last-child{
                  margin-bottom: 0;
                }
              }
              a{
                transition: all .3s ease;
                &:hover{
                  opacity: 0.8;
                }
              }
            }
          }
        }
    }
    @keyframes flow_bg_slide {
      0% { background-position: 0 top;}
    100% { background-position: -1366px top;}
    }
}

// よくあるご質問・お知らせ改修
#main{
  .columunWrap{
    display: flex;
    width: 80vw;
    margin: 0 auto;
    justify-content: space-between;
    @include vars.media(sp-tab) {
      flex-direction: column;
      width: 95vw;
    }
    
    .section_inner{
      width: 100%;
      padding: 0 0 50px;
      @include vars.media(sp-tab) {
        padding: 0 0 30px;
      }
    }
    .cateTit{
      color: #032A44;
      font-size: 17px;
      font-size: 1.9rem;
      text-align: left;
      border-bottom: #82BFE0 3px solid;
      padding-bottom: 10px;
      margin-bottom: 15px;
      font-weight: bold;
      @include vars.media(sp-tab) {
        font-size: 1.7rem;
        padding-left: 15px;
      }
    }
    .sideTit{
      color: #032A44;
      font-size: 17px;
      font-size: 1.9rem;
      text-align: center;
      margin-bottom: 10px;
      font-weight: bold;
      @include vars.media(sp-tab) {
        // font-size: 1.7rem;
      }
    }
    .columunMain{
      width: 73%;
      @include vars.media(sp-tab) {
        width: 100%;
      }
      .dlFAQ01{
        padding: 0 0 0 15px!important;
        
        dt{
          margin: 0 0 13px 0!important;
          padding: 15px 10px 15px 25px !important;
          font-size: 1.4rem;
          @include vars.media(sp-tab) {
            font-size: 1.3rem;
          }
          >span{
          display: none;
          }
        }
        dd{
          margin: 0 0 50px 0!important;
          padding: 0 20px!important;
          font-size: 1.4rem;
          @include vars.media(sp-tab) {
            font-size: 1.3rem;
          }
        }
      }
      .faq_btn{
        @include vars.media(sp-tab) {
          text-align: center;
        }
      }
      .column02{
        >div{

          margin-bottom: 30px;
          @include vars.media(sp-tab) {
            margin-bottom: 0;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .mainSearch{
        display: none;
        @include vars.media(sp-tab) {
          display: block;
        }
      }
      .searchMainBox{
        background: #f6f6f6;
        padding: 30px 50px;
        @include vars.media(sp-tab) {
          padding: 20px;
        }
      }
      .txtLead01{
        margin-top: 15px;
        text-align: center;
        margin-bottom: 30px!important;
      }
      .fs-paginate{
        ul{
          justify-content: center;
        }
      }
    }
    .columunSide{
      width: 25%;
      @include vars.media(sp-tab) {
        width: 100%;
      }
      .sideWrap{
        background: #F8F8F8;
        padding: 5px;
        box-sizing: border-box;
        ul{
          background: #fff;
          border: solid 1px #E8E8E8;
          li{
            
            border-bottom: dotted 1px #CCCCCC;
            div{
              &.active{
                a{
                  background: #e7f1f5;
                }
              }
              a{
                font-weight: 600;
                font-size: 1.5rem;
                transition: all 0.5s 0s ease;
                position: relative;
                padding:1rem 1rem 1rem 2.8rem;
                display: block;
                background: #fff;
                &::before{
                  vertical-align: middle;
                  position: absolute;
                  content: "";
                  display: block;
                  top: 0;
                  bottom: 0;
                  left: 1.5rem;
                  box-sizing: border-box;
                  width: 4px;
                  height: 4px;
                  margin: auto;
                  border: 3px solid transparent;
                  border-left: 4px solid #4699D1;
                }
                &:hover{
                  background: #e7f1f5;
                }
              }
            }
            &:last-child{
              border-bottom: none;
            }
          }
        }
      }
      .sideSearch{
        @include vars.media(sp-tab) {
          display: none;
        }
      }
    }
    .boxForm01{
      max-width: 600px;
    }
    .boxForm01 > input[type=text]{
      width: 100%;
      font-size: 1.3rem;
      border-radius: 3px 0 0 3px;
      padding:5px 15px;
      @include vars.media(sp-tab) {
        font-size: 1.6rem;
      }
    }
    .boxForm01 > input{
      &::placeholder{
        color: #838383;
        font-weight: normal;
      }

    }
    
    .boxForm01 > input[type=submit]{
      font-size: 1.3rem;
      background: #0077AC;
      border-radius: 0 3px 3px 0;
    }
    .txtForm01{
      color: #333333;
      text-align: center;
      margin-top: 5px;
      font-size: 1.3rem;
    }
  }
}

.cate_qaFoot{
	#btnPagetop{
		 @include vars.media(sp-tab) {
			display: none;
		}
	}
	.cateBtn{
		display: none;
		position: fixed;
		right: 0;
		bottom: 70px;
		z-index: 99999;
		 @include vars.media(sp-tab) {
			display: block;
		}
		a{
			background: #F5F5F5;
			border: solid 1px #4699D1;
			padding: 5px 5px 5px 20px;
			color: #333;
			border-radius: 20px 0 0 20px;
			text-decoration: none;
			position: relative;
			vertical-align: middle;
			font-weight: bold;
			font-size: 1.2rem;
			
			&::before{
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				content: "";
				vertical-align: middle;
				left: 10px;
				width: 4px;
				height: 4px;
				border-top: 2px solid #032A44;
				border-right: 2px solid #032A44;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
}
