@charset "utf-8";
@use "variable" as vars;

// 下から上にフェードイン
@keyframes bottomToTopfadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
// 右から左にフェードイン
@keyframes rightToLeftfadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
// 左から右にフェードイン
@keyframes leftToRightfadeIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
// 縮んでいるものが元の大きさまで広がる
@keyframes sideWidthAnime{
  0% {transform: scaleX(0);}
  100% {transform: scaleX(1);}
}

// 左からしゅっと出てくる
@keyframes slideFromLeftAnime{
  0% {transform: translateX(-100%);}
  100% {transform: translateX(0%);}
}

// 画面横幅いっぱいまで広がる
@keyframes spreadWideAnime{
  0% {width: 0vw;}
  100% {width: 100vw;}
}

// 矢印の動き
@keyframes arrowAnime01_be{
    0% {transform: translateY(-50%) scaleX(0);}
    70% {transform: translateY(-50%) scaleX(1);}
    100% {transform: translateY(-50%) scaleX(1);}
  }
@keyframes arrowAnime01_af{
    0% {transform: translateY(-50%) rotate(45deg) scaleX(0);}
    70% {transform: translateY(-50%) rotate(45deg) scaleX(1);}
    100% {transform: translateY(-50%) rotate(45deg) scaleX(1);}
}
@keyframes arrowAnime01_af_prev{
  0% {transform: translateY(-50%) rotate(-45deg) scaleX(0);}
  70% {transform: translateY(-50%) rotate(-45deg) scaleX(1);}
  100% {transform: translateY(-50%) rotate(-45deg) scaleX(1);}
}


@keyframes arrowAnime02_be{
  0% {transform: scaleX(0);}
  30% {transform: scaleX(1);}
  100% {transform: scaleX(1);}
}
@keyframes arrowAnime02_af{
  0% {transform: skewX(45deg) scaleY(0);}
  30% {transform: skewX(45deg) scaleY(1);}
  100% {transform: skewX(45deg) scaleY(1);}
}