@charset "UTF-8";
/*////////////////////////////////////////////////////

    .設定

////////////////////////////////////////////////////*/
/*スペース*/
/*中央寄せ $direction x：横方向 y：縦方向 xy：縦横方向*/
@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
/*////////////////////////////////////////////////////

    リセット

////////////////////////////////////////////////////*/
/**
 * html5doctor.com Reset Stylesheet v1.6.1 (http://html5doctor.com/html-5-reset-stylesheet/)
 * Richard Clark (http://richclarkdesign.com)
 * http://cssreset.com
 */
html, body, div, span, object, iframe, button,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: bottom;
  background: transparent;
}

html, body {
  width: 100%;
}

body {
  line-height: 1;
  letter-spacing: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}

th, td {
  font-weight: normal;
  vertical-align: middle;
}

caption {
  text-align: left;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

img {
  border: none;
  vertical-align: top;
  zoom: 1;
}

button {
  cursor: pointer;
}

iframe {
  max-width: 100%;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

/*////////////////////////////////////////////////////

  フォント・文字サイズ設定

////////////////////////////////////////////////////*/
html {
  font-size: 62.5%;
  background-color: #fff;
  scroll-behavior: auto;
  overflow-x: hidden;
}
html.__fixed {
  overflow: hidden !important;
  height: 100% !important;
}

body {
  /*font size自動調整をきる*/
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: "Yu Gothic UI", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", Arial, Helvetica, Verdana, sans-serif;
  line-height: 1.8;
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  color: #333;
  background: #fff 0 0 repeat;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
    font-size: 1.4rem;
    letter-spacing: 0.03em;
  }
}
body.__fixed {
  overflow: hidden !important;
  height: 100% !important;
}
body:before, body:after {
  opacity: 0;
  content: "";
  pointer-events: none;
  position: fixed;
}
body.disable-js:before {
  opacity: 1;
}

/*////////////////////////////////////////////////////

  その他

////////////////////////////////////////////////////*/
p {
  letter-spacing: 0.02em;
}

a {
  color: #333;
  text-decoration: none;
}

table {
  line-height: 1.8;
}

@keyframes bottomToTopfadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes rightToLeftfadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes leftToRightfadeIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes sideWidthAnime {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes slideFromLeftAnime {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes spreadWideAnime {
  0% {
    width: 0vw;
  }
  100% {
    width: 100vw;
  }
}
@keyframes arrowAnime01_be {
  0% {
    transform: translateY(-50%) scaleX(0);
  }
  70% {
    transform: translateY(-50%) scaleX(1);
  }
  100% {
    transform: translateY(-50%) scaleX(1);
  }
}
@keyframes arrowAnime01_af {
  0% {
    transform: translateY(-50%) rotate(45deg) scaleX(0);
  }
  70% {
    transform: translateY(-50%) rotate(45deg) scaleX(1);
  }
  100% {
    transform: translateY(-50%) rotate(45deg) scaleX(1);
  }
}
@keyframes arrowAnime01_af_prev {
  0% {
    transform: translateY(-50%) rotate(-45deg) scaleX(0);
  }
  70% {
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
  100% {
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}
@keyframes arrowAnime02_be {
  0% {
    transform: scaleX(0);
  }
  30% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes arrowAnime02_af {
  0% {
    transform: skewX(45deg) scaleY(0);
  }
  30% {
    transform: skewX(45deg) scaleY(1);
  }
  100% {
    transform: skewX(45deg) scaleY(1);
  }
}
/*////////////////////////////////////////////////////

    セクション

////////////////////////////////////////////////////*/
@media only screen and (min-width: 1100px) {
  section.top_sec {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  section.top_sec {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  section.top_sec {
    padding-top: 35px;
  }
}

.section_inner {
  margin: 0 auto;
}
@media only screen and (min-width: 1100px) {
  .section_inner {
    width: 80%;
    max-width: 1100px;
    padding: 0 0 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .section_inner {
    width: 90%;
    padding: 0 0 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section_inner {
    width: 92%;
    padding: 0 0 30px;
  }
}
@media only screen and (min-width: 768px) {
  .section_inner .section_box {
    padding: 0 20px;
  }
}

/*////////////////////////////////////////////////////
    左右2カラム
////////////////////////////////////////////////////*/
.column02 {
  display: flex;
  padding: 0 20px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1100px) {
  .column02 {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1099px) {
  .column02 {
    padding: 0;
    flex-direction: column;
  }
}
.column02:nth-child(n+2) {
  margin-top: 3.5rem;
}
@media only screen and (max-width: 767px) {
  .column02:nth-child(n+2) {
    margin-top: 2.5rem;
  }
}
@media only screen and (min-width: 1100px) {
  .column02 > div {
    width: calc(50% - 10px);
    margin-top: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .column02 > div {
    width: 100%;
  }
}
@media only screen and (max-width: 1099px) {
  .column02 > div:nth-child(n+2) {
    margin-top: 2.8rem;
  }
}

.column03 {
  display: flex;
  padding: 0 20px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .column03 {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  .column03 {
    flex-direction: column;
  }
}
.column03:nth-child(n+2) {
  margin-top: 3.5rem;
}
@media only screen and (max-width: 767px) {
  .column03:nth-child(n+2) {
    margin-top: 2.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .column03 > div {
    width: 32%;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .column03 > div {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .column03 > div:nth-child(2), .column03 > div:nth-child(3) {
    margin-top: 2.8rem;
  }
}

.column2to1 {
  display: flex;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .column2to1 {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  .column2to1 {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .column2to1 > div {
    margin-bottom: 30px;
  }
  .column2to1 > div:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .column2to1 > div.single {
    width: 32%;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .column2to1 > div.single {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .column2to1 > div.double {
    width: 66%;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .column2to1 > div.double {
    width: 100%;
  }
}

.img_center {
  text-align: center;
  margin-bottom: 30px;
  display: block;
}
@media only screen and (min-width: 768px) {
  .img_center img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .img_center img {
    width: 100%;
    height: auto;
  }
}

.caption {
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 15px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .caption {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

/*////////////////////////////////////////////////////
    トグル
////////////////////////////////////////////////////*/
.toggle dt {
  font-weight: bold;
  line-height: 1;
  color: #fff;
  position: relative;
  background: #0C3F71;
  background-position: 5px bottom;
}
@media only screen and (min-width: 768px) {
  .toggle dt {
    padding: 15px;
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .toggle dt {
    padding: 10px;
    font-size: 15px;
    font-size: 1.5rem;
    background-size: 34px;
    margin-bottom: 30px;
  }
}
.toggle dt span {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.toggle dt span::before, .toggle dt span::after {
  content: "";
  display: block;
  width: 14px;
  height: 3px;
  background-color: #0C3F71;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.5s 0s ease;
}
@media only screen and (max-width: 767px) {
  .toggle dt span::before, .toggle dt span::after {
    width: 10px;
    height: 2px;
  }
}
.toggle dt span::after {
  transform: translate(-50%) rotate(0deg);
}
@media only screen and (max-width: 767px) {
  .toggle dt span {
    width: 20px;
    height: 20px;
  }
}
.toggle dt.close span::after {
  transform: translate(-50%) rotate(90deg);
}
.toggle dd {
  display: none;
}

/*////////////////////////////////////////////////////
    枠線付きエリア
////////////////////////////////////////////////////*/
.linebox {
  border: solid 1px #448FC1;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1100px) {
  .linebox {
    padding: 3rem;
  }
}
@media only screen and (max-width: 1099px) {
  .linebox {
    padding: 1.8rem;
  }
}

/*////////////////////////////////////////////////////
    色付きエリア
////////////////////////////////////////////////////*/
.bgcbox {
  background-color: #f1f3f8;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1100px) {
  .bgcbox {
    padding: 3rem;
  }
}
@media only screen and (max-width: 1099px) {
  .bgcbox {
    padding: 1.8rem;
  }
}
.bgcbox:nth-child(n+2) {
  margin-top: 2.5rem;
}
@media only screen and (max-width: 767px) {
  .bgcbox:nth-child(n+2) {
    margin-top: 1rem;
  }
}

/*////////////////////////////////////////////////////
    ボタン
////////////////////////////////////////////////////*/
.common_btn {
  margin-bottom: 30px;
  background-color: #fff;
  transition: all 0.5s 0s ease;
  line-height: 1;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
}
@media only screen and (min-width: 768px) {
  .common_btn {
    display: inline-block;
  }
}
@media only screen and (min-width: 1100px) {
  .common_btn:hover {
    background-color: #0C3F71;
  }
  .common_btn:hover a {
    border-left: none;
    color: #fff;
    border-left: 0.51vw solid #0C3F71;
  }
  .common_btn:hover a::after {
    background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
    background-size: 95%;
    right: 0.6vw;
  }
  .common_btn:hover + .img .img-hover {
    opacity: 1;
  }
}
.common_btn.active {
  background-color: #0C3F71;
}
.common_btn.active a {
  border-left: none;
  color: #fff;
  border-left: 0.51vw solid #0C3F71;
}
.common_btn.active a::after {
  background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
  background-size: 95%;
  right: 0.6vw;
}
.common_btn:hover + .img .img-hover {
  opacity: 1;
}
.common_btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: bold;
  text-align: center;
}
.common_btn a i {
  display: inline-block;
  width: 30px;
  margin-right: 5px;
}
.common_btn a i img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .common_btn a {
    font-size: 15px;
    font-size: 1.5rem;
    padding: 22px 60px;
    border-left: 7px solid #0077AC;
  }
}
@media only screen and (max-width: 767px) {
  .common_btn a {
    width: 100%;
    font-size: 4.05vw;
    padding: 6.2vw 0;
    border-left: 1.89vw solid #0077AC;
    text-align: center;
  }
}
.common_btn a::after {
  content: "";
  display: block;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 768px) {
  .common_btn a::after {
    width: 15px;
    height: 15px;
    right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .common_btn a::after {
    width: 4.86vw;
    height: 4.86vw;
    right: 2.7vw;
  }
}
.common_btn.under a::after {
  background: url(/top/img/circle-arrow_under.svg) center center no-repeat;
}

/*////////////////////////////////////////////////////
    ページ内リンクに戻るボタン
////////////////////////////////////////////////////*/
.return_index {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .return_index {
    text-align: right;
  }
  .return_index a:hover {
    background-color: #0C3F71;
    color: #fff;
  }
  .return_index a:hover svg {
    fill: #fff;
  }
  .return_index a:hover::after {
    background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
    background-size: 95%;
  }
}
@media only screen and (max-width: 767px) {
  .return_index {
    margin: 30px 0 20px;
  }
}
.return_index a {
  display: inline-block;
  padding: 17px 0;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .return_index a {
    min-width: 320px;
  }
}
@media only screen and (max-width: 767px) {
  .return_index a {
    width: 100%;
  }
}
.return_index a svg {
  fill: #0077AC;
  width: 1em;
  height: 1em;
  margin-right: 10px;
  vertical-align: middle;
}
.return_index a::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  position: absolute;
  top: calc(50% - 9px);
  transform: rotate(-90deg);
  right: 17px;
}

/*////////////////////////////////////////////////////
    よくある質問（開閉無し）
////////////////////////////////////////////////////*/
.faq_area dt {
  margin: 0 20px 20px 20px;
  padding: 15px 0 15px 30px;
  background-color: #E1EDF3;
  font-size: clamp(13px, 1.1vw, 15px);
  font-weight: bold;
  position: relative;
  line-height: 1.3;
}
@media only screen and (max-width: 767px) {
  .faq_area dt {
    padding: 10px 0 10px 30px;
    font-size: clamp(12px, 3.78vw, 14px);
    padding-right: 10px;
  }
}
.faq_area dt::before {
  content: "Q";
  font-size: 26px;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
  color: #fff;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #0077AC;
  position: absolute;
  left: -17px;
  top: 5px;
  text-align: center;
  line-height: 1.1;
}
@media only screen and (max-width: 767px) {
  .faq_area dt::before {
    left: -13px;
    top: 5px;
  }
}
.faq_area dd {
  margin: 0 0 70px 20px;
  font-size: clamp(12px, 1vw, 14px);
  line-height: 2.2;
}
@media only screen and (max-width: 767px) {
  .faq_area dd {
    margin: 0 20px 40px 20px;
    font-size: clamp(12px, 3.78vw, 14px);
  }
}
.faq_area dd a {
  color: #448FC1;
}
@media only screen and (min-width: 1100px) {
  .faq_area dd a:hover {
    color: #0C3F71;
    text-decoration: underline;
  }
}

/*////////////////////////////////////////////////////
    下層ページ遷移リンク
////////////////////////////////////////////////////*/
@media only screen and (min-width: 1100px) {
  .lower_link {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower_link {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1100px) {
  .lower_link.style01 li {
    width: 24%;
  }
}
.lower_link ul {
  display: flex;
  flex-wrap: wrap;
}
.lower_link ul li {
  position: relative;
}
@media only screen and (min-width: 1100px) {
  .lower_link ul li {
    width: 19%;
    margin-right: 1%;
  }
  .lower_link ul li:nth-child(5n) {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower_link ul li {
    width: 33%;
    margin-right: 0.5%;
  }
  .lower_link ul li:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .lower_link ul li {
    width: 49%;
    margin-right: 1%;
  }
  .lower_link ul li:nth-child(2n) {
    margin-right: 0;
  }
}
.lower_link ul li .common_btn {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}
.lower_link ul li .common_btn a {
  padding: 10px 0 10px 10px;
  display: block;
  position: relative;
  box-sizing: border-box;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .lower_link ul li .common_btn a {
    text-align: left;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 6.8vw;
  }
  .lower_link ul li .common_btn a::after {
    right: 1.3vw;
  }
}

.boxContent.mypage {
  width: 100%;
  box-sizing: border-box;
  width: 1000px;
  margin: 0 auto;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
}
@media only screen and (max-width: 767px) {
  .boxContent.mypage {
    width: 90%;
  }
}

.boxContent.mypage > .boxInner {
  display: flex;
  color: #333;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s 0s ease;
  border-radius: 5px;
}

.boxContent.mypage > a:hover,
.boxContent > a:hover {
  background-color: #dee3e7;
  transition: all 0.5s 0s ease;
}

.boxContent.mypage > .boxInner .boxImg {
  border-bottom: none;
  width: 10%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .boxContent.mypage > .boxInner .boxImg {
    width: 25%;
    margin-bottom: 10px;
  }
}

.boxContent.mypage > .boxInner .boxImg img {
  padding: 0;
  width: 100%;
  height: auto;
}

.boxContent.mypage > .boxInner > .boxTxt {
  padding: 0 30px 0 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 67%;
  align-items: center;
}

a.boxInner:hover p.btn01 {
  background-color: #0C3F71;
}

.boxTxt h3 {
  color: #448FC1;
}
.boxTxt p {
  text-align: center;
  padding-bottom: 10px;
}
.boxTxt p.btn01 {
  background-color: #448FC1;
  border-radius: 100px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  transition: all 0.5s 0s ease;
}

.boxContent.mt20 {
  margin-top: 20px;
}

.ulBtn01 > li {
  padding: 0 10px;
}

@media screen and (max-width: 769px) {
  .boxContent.mypage > .boxInner > .boxTxt {
    padding: 0 15px;
    width: 100%;
  }
  .boxContent.mypage > .boxInner {
    flex-direction: column;
    padding: 15px;
  }
  .boxContent.mypage > .boxInner > .boxTxt .tit02 {
    margin-bottom: 5px;
  }
  .ulBtn01 > li:nth-child(2n+1):nth-last-child(2) {
    margin: 0 auto 10px;
  }
  .ulBtn01 > li {
    padding: 0;
    width: 90%;
  }
  .btn01 {
    width: 90%;
  }
  #mv .btn01 a {
    padding: 0 5px;
  }
}
@media screen and (max-width: 480px) {
  .titPanel01.fontS {
    font-size: 2rem;
  }
}
.mam_disc {
  padding-top: 20px;
  background-color: #E1EDF3;
}

.lower.ind .top_sec.member_top {
  padding-top: 10px;
}

.lower_tit01.common_inq {
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 2.5vw;
}
@media only screen and (min-width: 1100px) {
  .lower_tit01.common_inq {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower_tit01.common_inq {
    font-size: 26px;
    font-size: 2.6rem;
  }
}
@media only screen and (max-width: 767px) {
  .lower_tit01.common_inq {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 7.5vw;
    line-height: 1;
  }
  .lower_tit01.common_inq.fold span {
    line-height: 1.8;
  }
}
.lower_tit01.common_inq strong {
  color: #0077AC;
}
.lower_tit01.common_inq::after {
  content: "";
  display: block;
  background: linear-gradient(90deg, #C8DFEC 0%, #C8DFEC 50%, #448FC1 50%, #448FC1 100%);
}
@media only screen and (min-width: 768px) {
  .lower_tit01.common_inq::after {
    width: 54px;
    height: 3px;
    margin: 25px auto 0;
  }
}
@media only screen and (max-width: 767px) {
  .lower_tit01.common_inq::after {
    width: 14.5vw;
    height: 3px;
    margin: 4vw auto 0;
  }
}

.inq_wrap {
  max-width: 1000px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .inq_wrap {
    padding: 0 10px;
  }
}
.inq_wrap .btnList {
  display: flex;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList {
    flex-wrap: wrap;
    padding: 0 0 0;
    justify-content: space-between;
  }
}
.inq_wrap .btnList li a {
  background-color: #F5F5F5;
  padding: 19px 78px 19px 60px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  color: #333;
  font-weight: bold;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-left: 3px solid #448FC1;
  margin-bottom: 15px;
  position: relative;
  line-height: 1.3;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li a {
    font-size: 14px;
  }
}
.inq_wrap .btnList li a span {
  height: 22px;
  margin-right: 8px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li a span {
    margin-bottom: 5px;
  }
}
.inq_wrap .btnList li a span img {
  width: 100%;
  height: auto;
}
.inq_wrap .btnList li a::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.73vw;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li a::after {
    right: 1.73vw;
  }
}
.inq_wrap .btnList li a:hover {
  background-color: #f1f3f8;
  transition: all 0.5s 0s ease;
}
.inq_wrap .btnList li a:hover::after {
  right: 5px;
}
.inq_wrap .btnList li.group01 {
  margin-right: 50px;
}
.inq_wrap .btnList li.group01:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li.group01 {
    margin-right: 0;
    width: 48%;
  }
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li.group01 a {
    flex-direction: column;
    padding: 15px 10px;
  }
  .inq_wrap .btnList li.group01 a span {
    margin-bottom: 10px;
  }
}
.inq_wrap .btnList li.group01 .txt01 {
  padding: 0 10px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li.group01 .txt01 {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li.group02 {
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li.group02 a {
    padding: 10px 48px 10px 30px;
  }
}
.inq_wrap .btnList li .txt01 {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.3;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li .txt01 {
    flex-direction: column;
  }
}
.inq_wrap .btnList li .txt01 span {
  border-radius: 30px;
  margin-right: 5px;
  background-color: #0077AC;
  padding: 3px 15px;
  box-sizing: border-box;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .btnList li .txt01 span {
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.inq_wrap .btnList li .txt02 {
  color: #646464;
  line-height: 1.5;
}
.inq_wrap .inqRwapArea {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea {
    flex-direction: column;
  }
}
.inq_wrap .inqRwapArea h3 {
  font-size: 15px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea h3 {
    margin-bottom: 15px;
  }
}
.inq_wrap .inqRwapArea h3::after {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
  right: -15px;
  width: 4px;
  height: 4px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea h3::after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea .blk01 {
    margin-bottom: 5px;
  }
}
.inq_wrap .inqRwapArea .blk01 .tit {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.inq_wrap .inqRwapArea .blk01 .way {
  line-height: 1;
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea .blk01 .way {
    margin-bottom: 15px;
  }
}
.inq_wrap .inqRwapArea .blk01 .way .icon {
  width: 0.8em;
  height: 0.8em;
  vertical-align: middle;
  margin-right: 8px;
}
.inq_wrap .inqRwapArea .blk02 .txt01 {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea .blk02 .txt01 {
    flex-direction: column;
    text-align: center;
  }
}
.inq_wrap .inqRwapArea .blk02 .txt01 span {
  border-radius: 30px;
  margin-right: 5px;
  background-color: #0077AC;
  padding: 3px 15px;
  box-sizing: border-box;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea .blk02 .txt01 span {
    margin-bottom: 5px;
    margin-right: 0;
  }
}
.inq_wrap .inqRwapArea .blk02 .txt02 {
  color: #646464;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .inq_wrap .inqRwapArea .blk02 .txt02 {
    text-align: center;
  }
}

.applicationBox {
  margin-top: 120px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .applicationBox {
    margin-top: 60px;
  }
}
.applicationBox::before {
  content: "";
  background-image: url(/common/img/bg_inq.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 5vw;
  position: absolute;
  top: -5vw;
}
.applicationBox.member::before {
  display: none;
}
.applicationBox .entryBox {
  background-color: #E1EDF3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px 65px;
  box-sizing: border-box;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox {
    padding: 20px 15px 50px;
  }
}
.applicationBox .entryBox::after {
  content: "";
  display: inline-block;
  width: 80px;
  height: 60px;
  background-image: url(/top/img/ico_kamome-1.svg);
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 50%;
  left: 4vw;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox::after {
    width: 50px;
    height: 40px;
  }
}
.applicationBox .entryBox h2 {
  margin-bottom: 25px;
  line-height: 1.3;
  text-align: center;
  font-size: 25px;
}
.applicationBox .entryBox h2 span {
  color: #E44E00;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.applicationBox .entryBox > a {
  box-sizing: border-box;
  display: inline-block;
  padding: 15px 80px 15px 70px;
  transition: all 0.3s ease;
  background-color: #E44E00;
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 1100px) {
  .applicationBox .entryBox > a:hover {
    background-color: #e47b26;
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox > a {
    width: 298px;
    padding: 15px;
    display: flex;
    justify-content: center;
  }
}
.applicationBox .entryBox > a::after {
  right: 15px;
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
  background-size: 95%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.73vw;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox > a::after {
    right: 15px;
  }
}
.applicationBox .entryBox > a .application_btn {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) {
  .applicationBox .entryBox > a .application_btn {
    font-size: clamp(21px, 1.7vw, 23px);
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox > a .application_btn {
    font-size: 17px;
  }
}
.applicationBox .entryBox > a .application_btn svg {
  margin-right: 3px;
}
@media only screen and (min-width: 768px) {
  .applicationBox .entryBox > a .application_btn svg {
    width: 1.9vw;
    height: 1.9vw;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1400px) {
  .applicationBox .entryBox > a .application_btn svg {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox > a .application_btn svg {
    width: 6.9vw;
    height: 6.9vw;
  }
}
.applicationBox .entryBox.biz div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: stretch;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div {
    flex-direction: column;
  }
}
.applicationBox .entryBox.biz div a {
  box-sizing: border-box;
  display: inline-block;
  padding: 15px 80px 15px 70px;
  transition: all 0.3s ease;
  background-color: #E44E00;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a {
    height: 8rem;
  }
}
.applicationBox .entryBox.biz div a:nth-child(2) {
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a:nth-child(2) {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a {
    width: 100%;
  }
}
.applicationBox .entryBox.biz div a.blue {
  background-color: #0C3F71;
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a.blue {
    margin-bottom: 15px;
  }
}
.applicationBox .entryBox.biz div a .application_btn {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1.4;
  height: 100%;
  text-align: center;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .applicationBox .entryBox.biz div a .application_btn {
    font-size: clamp(21px, 1.7vw, 23px);
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a .application_btn {
    font-size: 17px;
    width: 100%;
  }
}
.applicationBox .entryBox.biz div a .application_btn svg, .applicationBox .entryBox.biz div a .application_btn img {
  margin-right: 10px;
}
@media only screen and (min-width: 768px) {
  .applicationBox .entryBox.biz div a .application_btn svg, .applicationBox .entryBox.biz div a .application_btn img {
    width: 1.9vw;
    height: 1.9vw;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1400px) {
  .applicationBox .entryBox.biz div a .application_btn svg, .applicationBox .entryBox.biz div a .application_btn img {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .entryBox.biz div a .application_btn svg, .applicationBox .entryBox.biz div a .application_btn img {
    width: 6.9vw;
    height: 6.9vw;
  }
}
.applicationBox .entryBox.biz::after {
  bottom: 60px;
}
.applicationBox .campaignBox {
  animation: flow_bg_slide 30s linear infinite;
  background: url(/top/img/flow_bg.svg);
  background-position: top;
  background-repeat: repeat-x;
  position: relative;
  top: -30px;
  left: 0;
  padding-top: 50px;
}
@media only screen and (max-width: 767px) {
  .applicationBox .campaignBox {
    background: url(/top/img/flow_bg_sp.svg) center bottom repeat-x;
    padding-top: 30px;
  }
}
.applicationBox .campaignBox .campainBoxIn {
  background-color: #EFC129;
  border-radius: 5px;
  padding: 30px 40px;
  width: 750px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .applicationBox .campaignBox .campainBoxIn {
    width: 95%;
    padding: 30px 15px;
  }
}
.applicationBox .campaignBox .campainBoxIn h2 {
  position: relative;
  padding: 0 1.7rem;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}
.applicationBox .campaignBox .campainBoxIn h2::before, .applicationBox .campaignBox .campainBoxIn h2::after {
  position: absolute;
  top: 0.8rem;
  height: 1.7rem;
  content: "";
}
.applicationBox .campaignBox .campainBoxIn h2::before {
  border-left: solid 2px;
  left: 0;
  transform: rotate(-30deg);
}
.applicationBox .campaignBox .campainBoxIn h2::after {
  border-right: solid 2px;
  right: 0;
  transform: rotate(30deg);
}
.applicationBox .campaignBox .campainBoxIn .bnr_wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .applicationBox .campaignBox .campainBoxIn .bnr_wrap {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .applicationBox .campaignBox .campainBoxIn .bnr_wrap p {
    margin-bottom: 10px;
  }
  .applicationBox .campaignBox .campainBoxIn .bnr_wrap p:last-child {
    margin-bottom: 0;
  }
}
.applicationBox .campaignBox .campainBoxIn .bnr_wrap p a {
  transition: all 0.3s ease;
}
.applicationBox .campaignBox .campainBoxIn .bnr_wrap p a:hover {
  opacity: 0.8;
}
@keyframes flow_bg_slide {
  0% {
    background-position: 0 top;
  }
  100% {
    background-position: -1366px top;
  }
}

#main .columunWrap {
  display: flex;
  width: 80vw;
  margin: 0 auto;
  justify-content: space-between;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap {
    flex-direction: column;
    width: 95vw;
  }
}
#main .columunWrap .section_inner {
  width: 100%;
  padding: 0 0 50px;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .section_inner {
    padding: 0 0 30px;
  }
}
#main .columunWrap .cateTit {
  color: #032A44;
  font-size: 17px;
  font-size: 1.9rem;
  text-align: left;
  border-bottom: #82BFE0 3px solid;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .cateTit {
    font-size: 1.7rem;
    padding-left: 15px;
  }
}
#main .columunWrap .sideTit {
  color: #032A44;
  font-size: 17px;
  font-size: 1.9rem;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
#main .columunWrap .columunMain {
  width: 73%;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain {
    width: 100%;
  }
}
#main .columunWrap .columunMain .dlFAQ01 {
  padding: 0 0 0 15px !important;
}
#main .columunWrap .columunMain .dlFAQ01 dt {
  margin: 0 0 13px 0 !important;
  padding: 15px 10px 15px 25px !important;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .dlFAQ01 dt {
    font-size: 1.3rem;
  }
}
#main .columunWrap .columunMain .dlFAQ01 dt > span {
  display: none;
}
#main .columunWrap .columunMain .dlFAQ01 dd {
  margin: 0 0 50px 0 !important;
  padding: 0 20px !important;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .dlFAQ01 dd {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .faq_btn {
    text-align: center;
  }
}
#main .columunWrap .columunMain .column02 > div {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .column02 > div {
    margin-bottom: 0;
  }
}
#main .columunWrap .columunMain .column02 > div:last-child {
  margin-bottom: 0;
}
#main .columunWrap .columunMain .mainSearch {
  display: none;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .mainSearch {
    display: block;
  }
}
#main .columunWrap .columunMain .searchMainBox {
  background: #f6f6f6;
  padding: 30px 50px;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunMain .searchMainBox {
    padding: 20px;
  }
}
#main .columunWrap .columunMain .txtLead01 {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 30px !important;
}
#main .columunWrap .columunMain .fs-paginate ul {
  justify-content: center;
}
#main .columunWrap .columunSide {
  width: 25%;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunSide {
    width: 100%;
  }
}
#main .columunWrap .columunSide .sideWrap {
  background: #F8F8F8;
  padding: 5px;
  box-sizing: border-box;
}
#main .columunWrap .columunSide .sideWrap ul {
  background: #fff;
  border: solid 1px #E8E8E8;
}
#main .columunWrap .columunSide .sideWrap ul li {
  border-bottom: dotted 1px #CCCCCC;
}
#main .columunWrap .columunSide .sideWrap ul li div.active a {
  background: #e7f1f5;
}
#main .columunWrap .columunSide .sideWrap ul li div a {
  font-weight: 600;
  font-size: 1.5rem;
  transition: all 0.5s 0s ease;
  position: relative;
  padding: 1rem 1rem 1rem 2.8rem;
  display: block;
  background: #fff;
}
#main .columunWrap .columunSide .sideWrap ul li div a::before {
  vertical-align: middle;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  left: 1.5rem;
  box-sizing: border-box;
  width: 4px;
  height: 4px;
  margin: auto;
  border: 3px solid transparent;
  border-left: 4px solid #4699D1;
}
#main .columunWrap .columunSide .sideWrap ul li div a:hover {
  background: #e7f1f5;
}
#main .columunWrap .columunSide .sideWrap ul li:last-child {
  border-bottom: none;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .columunSide .sideSearch {
    display: none;
  }
}
#main .columunWrap .boxForm01 {
  max-width: 600px;
}
#main .columunWrap .boxForm01 > input[type=text] {
  width: 100%;
  font-size: 1.3rem;
  border-radius: 3px 0 0 3px;
  padding: 5px 15px;
}
@media only screen and (max-width: 1099px) {
  #main .columunWrap .boxForm01 > input[type=text] {
    font-size: 1.6rem;
  }
}
#main .columunWrap .boxForm01 > input::placeholder {
  color: #838383;
  font-weight: normal;
}
#main .columunWrap .boxForm01 > input[type=submit] {
  font-size: 1.3rem;
  background: #0077AC;
  border-radius: 0 3px 3px 0;
}
#main .columunWrap .txtForm01 {
  color: #333333;
  text-align: center;
  margin-top: 5px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 1099px) {
  .cate_qaFoot #btnPagetop {
    display: none;
  }
}
.cate_qaFoot .cateBtn {
  display: none;
  position: fixed;
  right: 0;
  bottom: 70px;
  z-index: 99999;
}
@media only screen and (max-width: 1099px) {
  .cate_qaFoot .cateBtn {
    display: block;
  }
}
.cate_qaFoot .cateBtn a {
  background: #F5F5F5;
  border: solid 1px #4699D1;
  padding: 5px 5px 5px 20px;
  color: #333;
  border-radius: 20px 0 0 20px;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  font-weight: bold;
  font-size: 1.2rem;
}
.cate_qaFoot .cateBtn a::before {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
  left: 10px;
  width: 4px;
  height: 4px;
  border-top: 2px solid #032A44;
  border-right: 2px solid #032A44;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*////////////////////////////////////////////////////

　下層ページ共通 各種部品

////////////////////////////////////////////////////*/
.lower p.f-small {
  font-size: 1.2rem;
}
.lower p.f-big {
  line-height: 1;
  font-size: 1.8rem;
}
.lower .link_area_list {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .lower .link_area_list {
    margin: 0 15px 15px 0;
  }
}
.lower .link_area_list li {
  line-height: 1.3;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .lower .link_area_list li {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .lower .link_area_list li {
    margin-bottom: 15px;
  }
}
.lower .link_area_list li:last-child {
  margin-bottom: 0;
}
.lower .link_area_list li::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  position: relative;
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .lower .link_area_list li::before {
    margin-right: 10px;
  }
}
.lower .link_area_list li a {
  transition: all 0.3s ease;
}
.lower .link_area_list li a:hover {
  color: #0077AC;
  text-decoration: none;
}
.lower .faq_area dd .link_area_list a {
  color: #333;
  transition: all 0.2s 0s ease;
}
.lower .faq_area dd .link_area_list a:hover {
  color: #0077AC;
  transition: all 0.2s 0s ease;
  text-decoration: none;
}
.lower.biz {
  /*法人向けページ調整*/
}
@media only screen and (min-width: 1100px) {
  .lower.biz .hero {
    background-image: url(/common/img/lower_biz_hero.svg);
  }
}
@media only screen and (max-width: 1099px) {
  .lower.biz .hero {
    background-image: url(/common/img/lower_biz_hero_sp.svg);
  }
}
.lower.biz .breadcrumb {
  background-color: #E3E9EC;
}
.lower.biz .breadcrumb.white {
  background-color: #fff;
}
@media only screen and (min-width: 1100px) {
  .lower.biz.white .hero {
    background-image: url(/common/img/lower_hero.svg);
  }
}
@media only screen and (max-width: 1099px) {
  .lower.biz.white .hero {
    background-image: url(/common/img/lower_hero_sp.svg);
  }
}
.lower.biz.white .breadcrumb {
  background-color: #fff;
}
.lower.biz .top_sec {
  background-color: #E3E9EC;
}
.lower.biz .top_sec.bg_w {
  background-color: #fff;
}
@media only screen and (min-width: 1100px) {
  .lower.biz .top_sec + section {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower.biz .top_sec + section {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .lower.biz .top_sec + section {
    padding-top: 50px;
  }
}
.lower.biz .bg_glay {
  background-color: #E3E9EC;
}
.lower.biz .lower_tit01::before, .lower.biz .lower_tit01::after {
  background-color: #0C3F71;
}
.lower.biz .lower_tit02 svg {
  fill: #032A44;
}
.lower.biz .ul_common > li::before {
  background-color: #0C3F71;
}
.lower.biz .tbl_vertical > table thead th,
.lower.biz .tbl_scroll > table thead th,
.lower.biz .tbl_price > table thead th {
  background-color: #333;
}
.lower.biz .tbl_vertical > table tbody th,
.lower.biz .tbl_scroll > table tbody th,
.lower.biz .tbl_price > table tbody th {
  color: #fff;
  background-color: #0C3F71;
}
.lower.biz .tbl_vertical > table tbody td.td_text a,
.lower.biz .tbl_scroll > table tbody td.td_text a,
.lower.biz .tbl_price > table tbody td.td_text a {
  color: #448FC1;
}
.lower.biz .toggle dt {
  background: #0077AC;
}
.lower.biz .toggle dt span::before, .lower.biz .toggle dt span::after {
  background-color: #0077AC;
}
.lower.ind {
  /*個人向けページ調整*/
}
@media only screen and (min-width: 1100px) {
  .lower.ind .hero {
    background-image: url(/common/img/lower_ind_hero.svg);
  }
}
@media only screen and (max-width: 1099px) {
  .lower.ind .hero {
    background-image: url(/common/img/lower_ind_hero_sp.svg);
  }
}
.lower.ind .breadcrumb {
  background-color: #E1EDF3;
}
.lower.ind .top_sec {
  background-color: #E1EDF3;
}
@media only screen and (min-width: 1100px) {
  .lower.ind .top_sec + section {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower.ind .top_sec + section {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .lower.ind .top_sec + section {
    padding-top: 50px;
  }
}
.lower.ind .bg_glay {
  background-color: #E1EDF3;
}
.lower .hero {
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .lower .hero.style01 {
    padding-top: 4vw;
  }
}
@media only screen and (min-width: 1100px) {
  .lower .hero {
    width: calc(100% - 9.97vw);
    height: 12.4vw;
    padding: 0 9.97vw 0 0;
    background-image: url(/common/img/lower_hero.svg);
    background-position: bottom;
    background-size: 100%;
  }
}
@media only screen and (max-width: 1099px) {
  .lower .hero {
    width: 100%;
    height: 17.4vw;
    padding-top: 1.5vw;
    background-image: url(/common/img/lower_hero_sp.svg);
    background-position: bottom;
    background-size: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .hero {
    height: 16vw;
    padding-top: 1.5vw;
  }
}
.lower .hero .txt {
  padding-top: 2.3vw;
}
.lower .hero .txt.style01 {
  padding-top: 1.5vw;
}
@media only screen and (min-width: 1100px) {
  .lower .hero .txt {
    text-align: right;
  }
}
@media only screen and (max-width: 1099px) {
  .lower .hero .txt {
    text-align: center;
  }
}
.lower .hero .txt .__sub {
  color: #0077AC;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, sans-serif;
}
@media only screen and (min-width: 1100px) {
  .lower .hero .txt .__sub {
    font-size: clamp(18px, 1.46vw, 20px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .hero .txt .__sub {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .lower .hero .txt .__sub {
    font-size: clamp(16px, 4.86vw, 18px);
  }
}
.lower .hero .txt .__main {
  font-family: sofia-pro, sans-serif;
  font-style: italic;
  font-weight: 400;
  margin: 0;
  line-height: 1;
  color: #0C3F71;
}
@media only screen and (min-width: 1100px) {
  .lower .hero .txt .__main {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .hero .txt .__main {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .lower .hero .txt .__main {
    font-size: clamp(23px, 6.75vw, 25px);
  }
}
.lower .hero .txt .__txt {
  margin-top: 20px;
  font-size: 12px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px 15px;
  line-height: 1.3;
  display: inline-block;
  border: solid 2px #E3E9EC;
}
.lower .hero .txt .__txt a {
  color: #448FC1;
  transition: all 0.2s 0s ease;
}
.lower .hero .txt .__txt a:hover {
  color: #4699D1;
}
@media only screen and (max-width: 767px) {
  .lower .hero .txt .__txt {
    max-width: 90%;
    margin: 15px auto 0;
    padding: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .lower .breadcrumb.style01 {
    padding-top: 2em;
  }
}
@media only screen and (max-width: 1099px) {
  .lower .breadcrumb {
    overflow-x: scroll;
    word-break: keep-all;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
.lower .breadcrumb ol {
  margin: 0 auto;
  display: flex;
  padding-bottom: 10px;
}
@media only screen and (min-width: 1100px) {
  .lower .breadcrumb ol {
    width: 80%;
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .breadcrumb ol {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .lower .breadcrumb ol {
    width: 95%;
  }
}
.lower .breadcrumb ol li {
  position: relative;
}
@media only screen and (min-width: 1100px) {
  .lower .breadcrumb ol li {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 1099px) {
  .lower .breadcrumb ol li {
    font-size: 11px;
    font-size: 1.1rem;
  }
}
.lower .breadcrumb ol li::after {
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  transform: rotate(45deg);
  border-top: solid 2px #0077AC;
  border-right: solid 2px #0077AC;
  margin: 0 10px;
}
.lower .breadcrumb ol li:last-child::after {
  content: none;
}
.lower .breadcrumb ol li a {
  position: relative;
}
.lower .breadcrumb ol li a:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #0077AC;
  position: absolute;
  bottom: -6px;
  left: 0;
}
.lower .breadcrumb ol li span {
  position: relative;
}
.lower .breadcrumb ol li span::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #0077AC;
  position: absolute;
  bottom: -6px;
  left: 0;
}
.lower .lower_pagetit {
  text-align: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.2em;
  font-size: 3.2rem;
}
@media only screen and (min-width: 1100px) {
  .lower .lower_pagetit {
    font-size: 32px;
    font-size: 3.2rem;
    margin-bottom: 4vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .lower_pagetit {
    font-size: clamp(24px, 3.38vw, 26px);
    margin-bottom: 3.5vw;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_pagetit {
    font-size: 5.3vw;
    margin-bottom: 8vw;
  }
}
.lower .lower_pagetit::before, .lower .lower_pagetit::after {
  border-top: 3px solid;
  content: "";
  width: 1.3em;
  color: #0077AC;
}
@media only screen and (min-width: 1100px) {
  .lower .lower_pagetit::before, .lower .lower_pagetit::after {
    border-top: 4px solid;
  }
}
.lower .lower_pagetit::before {
  margin-right: 0.5em;
}
.lower .lower_pagetit::after {
  margin-left: 0.5em;
}
.lower .lower_tit01 {
  font-weight: bold;
  text-align: center;
}
@media only screen and (min-width: 1100px) {
  .lower .lower_tit01 {
    font-size: 30px;
    font-size: 3rem;
    margin-bottom: 2.5vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .lower_tit01 {
    font-size: clamp(24px, 3.38vw, 26px);
    margin-bottom: 2.5vw;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_tit01 {
    font-size: 5.3vw;
    line-height: 1;
    margin-bottom: 6.8vw;
  }
  .lower .lower_tit01.fold {
    line-height: 1.35;
  }
}
.lower .lower_tit01 strong {
  color: #0077AC;
}
.lower .lower_tit01::after {
  content: "";
  display: block;
  background: linear-gradient(90deg, #C8DFEC 0%, #C8DFEC 50%, #448FC1 50%, #448FC1 100%);
}
@media only screen and (min-width: 768px) {
  .lower .lower_tit01::after {
    width: 54px;
    height: 3px;
    margin: 15px auto 0;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_tit01::after {
    width: 14.5vw;
    height: 3px;
    margin: 4vw auto 0;
  }
}
.lower .lower_tit02 {
  font-weight: bold;
  line-height: 1;
  text-align: center;
}
@media only screen and (min-width: 1100px) {
  .lower .lower_tit02 {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .lower_tit02 {
    font-size: clamp(22px, 4vw, 24px);
    margin-bottom: 8vw;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_tit02 {
    font-size: clamp(18px, 5.4vw, 20px);
    margin-bottom: 8vw;
  }
}
.lower .lower_tit02 svg {
  width: 1.8em;
  height: 1.8em;
  vertical-align: middle;
  fill: #0077AC;
  margin-right: 4px;
}
.lower .text + .lower_tit03 {
  margin-top: 40px;
}
.lower .lower_tit03 {
  font-weight: bold;
  line-height: 1.2;
  padding: 20px 30px 17px 30px;
  font-size: 20px;
  font-size: 2rem;
  background-size: 34px;
  margin-bottom: 20px;
  background: #F2F2F2 url(/common/img/header-mark_sp.svg) no-repeat;
  background-position: 8px center;
}
@media only screen and (max-width: 767px) {
  .lower .lower_tit03 {
    margin-bottom: 15px;
    font-size: 19px;
    font-size: 1.9rem;
  }
}
.lower .lower_tit04 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  padding: 10px 0 10px 10px;
  margin-bottom: 25px;
  /* border-left: 5px solid #0077AC; */
  position: relative;
}
@media only screen and (max-width: 1099px) {
  .lower .lower_tit04 {
    line-height: 1.4;
    padding: 5px 10px 5px 10px;
  }
}
.lower .lower_tit04::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #EBEBEB;
  position: absolute;
  left: 0;
  bottom: -5px;
}
.lower .lower_tit05 {
  font-size: 16px;
  font-size: 1.6rem;
  color: #0077AC;
  padding: 0 0 0 9px;
}
.lower .lower_tit05::before {
  content: "";
  width: 7px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  background-color: #C8DFEC;
  margin-right: 5px;
}
.lower .lower_lead {
  line-height: 2.4;
}
@media only screen and (min-width: 768px) {
  .lower .lower_lead {
    text-align: center;
  }
}
@media only screen and (min-width: 1100px) {
  .lower .lower_lead {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower .lower_lead {
    font-size: clamp(14px, 2.7vw, 16px);
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_lead {
    padding: 0 15px;
    font-size: clamp(14px, 4.3vw, 16px);
    line-height: 1.8;
  }
}
.lower .text {
  line-height: 2.2;
  margin-bottom: 15px;
}
.lower .text:last-child {
  margin-bottom: 30px;
}
.lower .text + .text {
  margin-top: 30px;
}
.lower .text a {
  color: #448FC1;
}
@media only screen and (min-width: 1100px) {
  .lower .text a:hover {
    color: #0C3F71;
    text-decoration: underline;
  }
}
.lower strong {
  font-weight: bold;
  color: #E44E00;
}
.lower em {
  font-weight: bold;
  color: #0C3F71;
  font-style: normal;
}
.lower .red {
  color: #E44E00;
}
.lower .tag {
  display: inline-block;
  padding: 3px 8px;
  background-color: #E3E9EC;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 5px;
}
.lower .bold {
  font-weight: bold;
}
.lower .txt-links {
  color: #448FC1;
}
@media only screen and (min-width: 1100px) {
  .lower .txt-links:hover {
    color: #0C3F71;
    text-decoration: underline;
  }
}
@media only screen and (max-width: 767px) {
  .lower .cam_btn {
    width: 100%;
  }
}
.lower .cam_btn a {
  display: inline-block;
  padding: 15px 20px;
  background-color: #EFC129;
  color: #333;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: bold;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .lower .cam_btn a {
    display: block;
  }
}
.lower .cam_btn a span {
  color: #B00000;
}
.lower .cam_btn a::after {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #333;
  vertical-align: middle;
  margin-left: 12px;
}
@media only screen and (max-width: 767px) {
  .lower .cam_btn a::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
@media only screen and (min-width: 1100px) {
  .lower .cam_btn a:hover {
    background-color: #f2cd54;
  }
}
.lower .lower_application_btn {
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.2;
}
@media only screen and (min-width: 768px) {
  .lower .lower_application_btn {
    font-size: 20px;
    font-size: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_application_btn {
    font-size: 17px;
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 374px) {
  .lower .lower_application_btn {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.lower .lower_application_btn a {
  background-color: #E44E00;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  transition: all 0.5s 0s ease;
}
@media only screen and (min-width: 768px) {
  .lower .lower_application_btn a {
    padding: 30px 100px;
  }
}
@media only screen and (max-width: 767px) {
  .lower .lower_application_btn a {
    padding: 25px 10px;
  }
}
.lower .lower_application_btn a svg {
  width: 1.1em;
  height: 1.1em;
  fill: #fff;
  vertical-align: middle;
  margin-right: 8px;
}
@media only screen and (min-width: 1100px) {
  .lower .lower_application_btn a:hover {
    background-color: #e47b26;
  }
}
.lower .lower_application_btn span {
  margin-top: 8px;
  display: block;
  font-size: 12px;
}
.lower .ul_common {
  padding-left: 17px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 1100px) {
  .lower .ul_common.ulcol2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .lower .ul_common.ulcol2 li {
    width: 48%;
  }
}
.lower .ul_common > li {
  position: relative;
}
.lower .ul_common > li::before {
  content: "";
  width: 9px;
  height: 9px;
  display: block;
  border-radius: 50%;
  background-color: #0077AC;
  position: absolute;
  left: -14px;
  top: 9px;
}
.lower .ul_common > li strong {
  color: #448FC1;
}
.lower .ul_common.gray > li::before {
  width: 5px;
  height: 5px;
  background-color: #C5C5C5;
  top: 11px;
}
.lower .ol_common {
  padding-left: 17px;
  margin-bottom: 30px;
  counter-reset: num;
}
.lower .ol_common > li {
  margin-bottom: 15px;
  position: relative;
}
.lower .ol_common > li::before {
  counter-increment: num;
  content: counter(num) ".";
  color: #0077AC;
  font-weight: bold;
  position: absolute;
  left: -17px;
  top: 0;
}
.lower .ol_common > li .lower_tit05 {
  font-size: 1.4rem;
}
.lower .annotation {
  margin-left: 1.5em;
  margin-bottom: 30px;
}
.lower .annotation > li {
  text-indent: -1.5em;
  font-size: 13px;
  font-size: 1.3rem;
}
.lower .annotation > li.type01 {
  text-indent: -1em;
}
.lower .annotation.type01 {
  margin-left: 1em;
}
.lower .annotation.type01 > li {
  text-indent: -1em;
}
.lower .ann {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 13px;
  font-size: 1.3rem;
  margin-bottom: 30px;
}
.lower .ann.style02 {
  font-size: 15px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 767px) {
  .lower .ann.style02 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 767px) {
  .lower .ann {
    width: 95%;
    margin: 0 auto 30px;
  }
}
.lower .ann dt {
  width: 1.5em;
}
.lower .ann dd {
  width: calc(100% - 1.5em);
}
.lower .ann dd a {
  color: #448FC1;
}
.lower .ann dd.text {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .lower .ann.col2 {
    margin-left: 20px;
  }
}
.lower .tbl_vertical .ul_common,
.lower .tbl_scroll .ul_common {
  margin-bottom: 0;
}
.lower .tbl_vertical {
  margin-bottom: 30px;
}
.lower .tbl_vertical > table {
  width: 100%;
}
.lower .tbl_vertical > table thead tr th {
  background-color: #0C3F71;
  border-bottom: 1px solid #EAEAEA;
}
.lower .tbl_vertical > table td img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .lower .tbl_vertical > table {
    border: 1px solid #EAEAEA;
    border-collapse: separate;
  }
  .lower .tbl_vertical > table tr th, .lower .tbl_vertical > table tr td {
    border-right: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
  }
  .lower .tbl_vertical > table tr th .text, .lower .tbl_vertical > table tr td .text {
    margin-bottom: 0;
  }
  .lower .tbl_vertical > table tr th {
    font-weight: bold;
    vertical-align: middle;
    padding: 15px;
    color: #fff;
    background-color: #4699D1;
  }
  .lower .tbl_vertical > table tr th:last-child {
    border-right: none;
  }
  .lower .tbl_vertical > table tr td {
    text-align: left;
    vertical-align: middle;
    padding: 15px;
    background-color: #fff;
  }
}
@media only screen and (min-width: 768px) and (max-width: 767px) {
  .lower .tbl_vertical > table tr td {
    padding: 10px 20px;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 768px) {
  .lower .tbl_vertical > table tr td:last-child {
    border-right: none;
  }
}
@media only screen and (min-width: 768px) {
  .lower .tbl_vertical > table tr:last-child th {
    border-bottom: none;
  }
  .lower .tbl_vertical > table tr:last-child td {
    border-bottom: none;
  }
}
@media only screen and (max-width: 767px) {
  .lower .tbl_vertical > table tr {
    margin-bottom: 12px;
  }
  .lower .tbl_vertical > table tr:first-child th {
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .lower .tbl_vertical > table th, .lower .tbl_vertical > table td {
    display: block;
    text-align: left;
  }
  .lower .tbl_vertical > table th {
    background-color: #F5F5F5;
    padding: 5px 0 5px 15px;
    margin-top: 30px;
    color: #032A44;
    font-weight: bold;
    position: relative;
  }
  .lower .tbl_vertical > table th::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 3px;
    margin-right: 7px;
    background-color: #4699D1;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .lower .tbl_vertical > table td {
    padding: 14px 15px 0 15px;
  }
}
.lower .tbl_scroll {
  margin-bottom: 30px;
}
.lower .tbl_scroll > table {
  width: 100%;
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  border-left: 1px solid #EAEAEA;
  border-collapse: separate;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_scroll > table {
    max-width: 1000px;
  }
}
.lower .tbl_scroll > table th a, .lower .tbl_scroll > table td a {
  color: #448FC1;
}
@media only screen and (min-width: 1100px) {
  .lower .tbl_scroll > table th a:hover, .lower .tbl_scroll > table td a:hover {
    color: #0C3F71;
    text-decoration: underline;
  }
}
.lower .tbl_scroll > table thead th {
  background-color: #0C3F71;
}
.lower .tbl_scroll > table tbody th {
  background-color: #4699D1;
  white-space: nowrap;
}
.lower .tbl_scroll > table tbody tr:last-child th {
  border-bottom: none;
}
.lower .tbl_scroll > table tbody tr:last-child td {
  border-bottom: none;
}
.lower .tbl_scroll > table tr th, .lower .tbl_scroll > table tr td {
  border-right: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_scroll > table tr th, .lower .tbl_scroll > table tr td {
    white-space: nowrap;
  }
}
.lower .tbl_scroll > table tr th {
  font-weight: bold;
  vertical-align: middle;
  padding: 15px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_scroll > table tr th {
    padding: 10px 20px;
    line-height: 1.2;
  }
}
.lower .tbl_scroll > table tr td {
  text-align: left;
  vertical-align: middle;
  padding: 15px;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_scroll > table tr td {
    padding: 10px 20px;
    line-height: 1.2;
  }
}
.lower .tbl_price {
  margin-bottom: 30px;
}
.lower .tbl_price > table {
  width: 100%;
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  border-left: 1px solid #EAEAEA;
  border-collapse: separate;
}
.lower .tbl_price > table thead th {
  background-color: #333;
}
.lower .tbl_price > table tbody th {
  background-color: #4699D1;
}
.lower .tbl_price > table tbody th span {
  font-size: 1.2rem;
  line-height: 1.4;
  vertical-align: text-bottom;
  display: block;
}
.lower .tbl_price > table tbody th span.no-br {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tbody th span {
    font-size: 1.1rem;
  }
  .lower .tbl_price > table tbody th span.no-br {
    display: block;
  }
}
.lower .tbl_price > table tbody tr th {
  padding: 15px;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tbody tr th {
    padding: 20px 5px;
  }
}
.lower .tbl_price > table tbody tr:last-child th {
  border-bottom: none;
}
.lower .tbl_price > table tbody tr:last-child td {
  border-bottom: none;
}
.lower .tbl_price > table tr th, .lower .tbl_price > table tr td {
  border-right: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}
.lower .tbl_price > table tr th {
  font-weight: bold;
  vertical-align: middle;
  padding: 5px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr th {
    padding: 10px 5px;
    line-height: 1.2;
  }
}
.lower .tbl_price > table tr td {
  line-height: 1.4;
  text-align: center;
  vertical-align: middle;
  padding: 15px 5px;
  font-weight: bold;
  font-size: 2rem;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr td {
    font-size: 1.6rem;
    padding: 10px;
    line-height: 1.2;
  }
}
.lower .tbl_price > table tr td b {
  font-size: 1.3rem;
  font-weight: bold;
  color: #448FC1;
  display: block;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr td b {
    font-size: 1.3rem;
    display: block;
  }
}
.lower .tbl_price > table tr td span {
  vertical-align: text-bottom;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.6;
  display: block;
}
.lower .tbl_price > table tr td strong {
  font-size: 1.7rem;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr td strong {
    font-size: 1.4rem;
  }
}
.lower .tbl_price > table tr td.f-small {
  font-size: 1.3rem;
  padding: 15px;
  font-weight: normal;
}
.lower .tbl_price > table tr td.vertical {
  padding: 0;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr td.vertical {
    flex-direction: column;
  }
}
.lower .tbl_price > table tr td.vertical p {
  flex: 1 0 auto;
  text-align: center;
  border-right: 1px solid #EAEAEA;
  padding: 15px 5px;
}
.lower .tbl_price > table tr td.vertical p:last-child {
  border-right: none;
}
@media only screen and (max-width: 767px) {
  .lower .tbl_price > table tr td.vertical p {
    padding: 10px;
    flex-direction: column;
    border-right: none;
    border-bottom: 1px solid #EAEAEA;
  }
  .lower .tbl_price > table tr td.vertical p:last-child {
    border-bottom: none;
  }
}
.lower #partner {
  width: 80%;
  max-width: 1100px;
  padding-top: 5.33vw;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .lower #partner {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .lower #partner {
    width: 95%;
  }
}
.lower #partner img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 1100px) {
  .lower #partner img:hover {
    opacity: 0.8;
  }
}

/*パートナー募集バナー*/
.add-control {
  position: fixed;
  bottom: 10px;
  left: 0px;
  z-index: 9999;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .add-control {
    bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .add-control {
    bottom: 65px;
  }
}
@media only screen and (max-width: 374px) {
  .add-control {
    bottom: 65px;
  }
}
.add-control .pt_box .partner_bnr a {
  display: inline-block;
  width: 180px;
}
@media only screen and (max-width: 767px) {
  .add-control .pt_box .partner_bnr a {
    width: 120px;
  }
}
.add-control .pt_box .partner_bnr a img {
  width: 100%;
  height: auto;
  opacity: 1;
  padding-bottom: 0;
}
.add-control .pt_box .partner_bnr a:hover {
  transition: 0.2s;
  padding-bottom: 5px;
}

/* close button */
.action-close {
  position: relative;
  margin-top: 2em;
}

.action-close .pt_btn {
  position: absolute;
  left: 5px;
  top: -10px;
}
@media only screen and (max-width: 767px) {
  .action-close .pt_btn {
    left: 5px;
    top: -10px;
  }
}
.action-close .pt_btn .round_btn {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #0C3F71; /* 枠の調整 */
  border-radius: 50%; /* 丸みの度合い */
  background: #fff; /* ボタンの背景色 */
}
@media only screen and (max-width: 767px) {
  .action-close .pt_btn .round_btn {
    width: 20px;
    height: 20px;
  }
}
.action-close .pt_btn .round_btn::after, .action-close .pt_btn .round_btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px; /* 棒の幅（太さ） */
  height: 22px; /* 棒の高さ */
  background: #0C3F71; /* バツ印の色 */
}
@media only screen and (max-width: 767px) {
  .action-close .pt_btn .round_btn::after, .action-close .pt_btn .round_btn::before {
    height: 13px; /* 棒の高さ */
  }
}
.action-close .pt_btn .round_btn::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.action-close .pt_btn .round_btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* checkbox 非表示 */
.add-control .checkbox {
  display: none;
}

/* close button's control */
.add-control .action-close #close:checked ~ .pt_btn {
  display: none;
}

.add-control .action-close #close:checked ~ .pt_box {
  display: none;
}

/* ------------------------------------------------
  コピー禁止
------------------------------------------------ */
.ncpy {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.boxEnclosure01 {
  padding: 25px;
  box-sizing: border-box;
  background-color: #F2F2F2;
  border: 1px solid #e3e3e3;
}
@media only screen and (max-width: 767px) {
  .boxEnclosure01 {
    padding: 15px;
  }
}
.boxEnclosure01 .phone {
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxEnclosure01 .phone svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.boxEnclosure01 .phone span {
  font-weight: bold;
  font-size: 2rem;
  display: block;
}
.boxEnclosure01 .phone span i {
  font-size: 1.5rem;
  font-style: normal;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .boxEnclosure01 .phone span i {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .alignC .mr10 {
    margin-right: 0 !important;
  }
}

.common_btn a {
  box-sizing: border-box;
}
.common_btn.style01 a {
  background-color: #0C3F71;
  color: #fff;
}
.common_btn.style01 a:hover {
  background-color: #005f88;
  transition: all 0.5s 0s ease;
}
.common_btn.style01 a::after {
  background: url(/top/img/circle-arrow_n.svg) center center no-repeat;
  background-size: 95%;
}

.section_inner .lower_pagetit.sp01 {
  margin-bottom: 0.5vw;
}
@media only screen and (max-width: 767px) {
  .section_inner .lower_pagetit.sp01 {
    margin-bottom: 1.3vw;
  }
}
.section_inner .fukidashi {
  text-align: center;
  margin-bottom: 2vw;
}
@media only screen and (max-width: 767px) {
  .section_inner .fukidashi {
    margin-bottom: 3vw;
  }
}
.section_inner .fukidashi span {
  font-style: normal;
  display: block;
  font-weight: bold;
  font-size: 15px;
  background-color: #0077AC;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  display: inline-block;
}

.tbl_vertical .fukidashi, .tbl_price .fukidashi {
  margin-top: 10px;
  display: block;
  text-align: left;
}
.tbl_vertical .fukidashi.center, .tbl_price .fukidashi.center {
  text-align: center;
}
.tbl_vertical .fukidashi.bg_W span, .tbl_price .fukidashi.bg_W span {
  background-color: #fff;
  color: #333;
}
.tbl_vertical .fukidashi.bg_W span::before, .tbl_price .fukidashi.bg_W span::before {
  border-color: transparent transparent #fff transparent;
}
.tbl_vertical .fukidashi span, .tbl_price .fukidashi span {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  background-color: #0077AC;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  display: inline-block !important;
  line-height: 1;
  position: relative;
  text-align: center;
}
.tbl_vertical .fukidashi span::before, .tbl_price .fukidashi span::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 10px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: transparent transparent #0077AC transparent;
  border-width: 0px 8px 8px 8px;
}
.tbl_vertical .fukidashi span.arrow_c::before, .tbl_price .fukidashi span.arrow_c::before {
  left: 0;
  right: 0;
}

#chatplusview #eye_catcher .button-hide {
  position: absolute;
  top: -10px !important;
  right: 33px !important;
  font-size: 13px !important;
  line-height: 1 !important;
  color: #0077AC !important;
  opacity: 1 !important;
  text-shadow: none !important;
  background: #fff !important;
  padding: 3px !important;
  border-radius: 30px !important;
  width: 20px !important;
  height: 20px !important;
  box-sizing: border-box !important;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
  justify-content: center !important;
  border: 2px solid #0077AC !important;
}

/*////////////////////////////////////////////////////

　全ページ共通

////////////////////////////////////////////////////*/
#main {
  margin-top: 0;
  color: #333;
}
@media only screen and (min-width: 1100px) {
  #main {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  #main {
    padding-top: 13.5vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  #main {
    padding-top: 6.75vw;
  }
}

/*////////////////////////////////////////////////////

    ヘッダー

////////////////////////////////////////////////////*/
.header {
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-sizing: border-box;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));
}
@media only screen and (min-width: 1100px) {
  .header {
    height: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .header {
    height: 13.5vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header {
    height: 6.75vw;
  }
}
.header .header_menu .header_menu_btn #header_menu_btn_check {
  display: none;
}
.header .header_menu .header_menu_btn .header_menu_nav {
  transition: all 0.5s 0s ease-out;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: -100vh;
  width: 100%;
  opacity: 0;
  z-index: 1;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.05));
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav {
    height: calc(100vh - 13.5vw);
  }
}
@media only screen and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav {
    z-index: 998;
    background-color: #EBEBEB;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap {
  background: #EBEBEB;
  padding: 3vw 0;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap {
    padding: 3vw 0 25vw;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap {
  display: flex;
  width: 80vw;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: space-between;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap.style01 .navBox {
  border-right: none;
  padding: 0 0.8vw;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap {
    width: 98vw;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap {
    flex-direction: column;
    width: 100%;
    padding: 3.5vw 2vw 0 2vw;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .titBox {
  position: relative;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .titBox .gnavMypageLink {
  position: absolute;
  top: 0;
  right: 5px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .titBox .gnavMypageLink a {
  display: flex;
  align-items: center;
  font-weight: normal;
  justify-content: flex-start;
  transition: all 0.5s 0s ease;
  font-weight: bold;
  color: #125da7;
  font-size: 1.3rem;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .titBox .gnavMypageLink a:hover {
  color: #448FC1;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .titBox .gnavMypageLink a:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(/common/img/circle-arrow02.svg) center center no-repeat;
  background-size: 95%;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a {
  display: flex;
  align-items: center;
  justify-content: left;
  justify-content: center;
  background-color: #0C3F71;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 10px;
  position: relative;
  transition: all 0.3s ease;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a svg {
  margin-right: 5px;
  fill: #fff;
  width: 20px;
  height: 20px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a:hover {
  background-color: #0077AC;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(/common/img/circle-arrow02-w.svg) center center no-repeat;
  background-size: 95%;
  margin-right: 5px;
  transition: all 0.5s 0s ease;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a:after {
    width: 18px;
    height: 18px;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a.style01 {
  background-color: unset;
  padding: 0;
  color: #0C3F71;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a.style01:hover {
  background-color: unset;
  color: #448FC1;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title a.style01:after {
  background: url(/common/img/circle-arrow02.svg) center center no-repeat;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title.style01 {
  display: flex;
  align-items: center;
  justify-content: left;
  justify-content: flex-start;
  padding: 0 10px 5px;
  box-sizing: border-box;
  color: #0C3F71;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 15px;
  position: relative;
  border-bottom: #448FC1 2px solid;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title.style01 svg {
  margin-right: 5px;
  fill: #0C3F71;
  width: 20px;
  height: 20px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title.style01 span {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title.style01 span img {
  width: 100%;
  height: auto;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap h4 {
  color: #448FC1;
  font-size: 1.3rem;
  font-weight: bold;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap h4.sec {
  margin-top: 10px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap h4 a {
  color: #448FC1;
  font-size: 1.3rem;
  font-weight: bold;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox {
  width: 25%;
  border-right: solid 2px #C5D0D5;
  box-sizing: border-box;
  padding: 0 1.5vw;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.style01 {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.style01 {
    width: 100%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.style02 {
  width: 75%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.style02 {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox {
    width: 100%;
    border-right: none;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox:last-child {
  border-right: none;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.col02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox.col02 p {
    width: 48%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt {
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
  font-size: 0.9em;
  line-height: 1.1;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt br {
  display: none;
}
@media screen and (max-width: 1400px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt {
    font-size: 0.8em;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt br {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt {
    font-size: 1em;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageTxt br {
    display: none;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageLink {
  margin-top: 5px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageLink a {
  display: flex;
  justify-content: center;
  font-weight: bold;
  background-color: #0077AC;
  color: #fff;
  font-size: 1.8rem;
  padding: 5px 12px;
  box-sizing: border-box;
  border-radius: 20px;
  align-items: center;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .navBox .mypageLink a::before {
  display: block;
  content: "";
  left: 3px;
  width: 4px;
  height: 4px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-right: 5px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap.style02 {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont {
  width: 33%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont {
    width: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont.sec {
    margin-top: 10px;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont .gnavList li {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont .gnavList li .g_subList {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont.style03 {
    width: 100%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont.style03 .gnavList {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont.style03 .gnavList {
    display: flex;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnav_memCont.style03 .gnavList > li {
    width: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap {
    flex-direction: column;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList {
  width: 100%;
  justify-content: space-between;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList.style02 {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList {
    width: 100%;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList > li {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList .g_subList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList .g_subList > li {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList .g_subList > li:last-child {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList.style01 {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList.style01:first-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavWrap .gnavList.style01 > li {
    width: 49%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap p.title02 {
  padding: 0 1vw;
  font-weight: bold;
  font-size: 0.83em;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-top: 15px;
    justify-content: space-between;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li {
  border-bottom: solid 2px #fff;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li {
    width: 49%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li a {
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: all 0.3s ease;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li a:hover {
  color: #0077AC !important;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li a span {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  fill: #0172BE;
  width: 20px;
  height: 20px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_atherList li a span img {
  width: 100%;
  height: auto;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_bnr {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_bnr.style01 {
    margin: 10px auto 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_bnr {
    width: 60%;
    margin: 0 auto 10px;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_bnr img {
  width: 100%;
  height: auto;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList {
  padding: 0 1vw;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList.style01 {
    margin-bottom: 0;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li {
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li {
    width: 50%;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li > a {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
}
@media only screen and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li > a {
    font-size: 1.3rem;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li > a:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  margin-right: 5px;
  transition: all 0.5s 0s ease;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li > a {
    transition: all 0.3s ease;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gnavList > li > a:hover {
    color: #0077AC;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li {
    margin-bottom: 5px;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li > a {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: normal;
}
@media only screen and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li > a {
    font-size: 1.3rem;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li > a:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(/common/img/circle-arrow02.svg) center center no-repeat;
  background-size: 95%;
  margin-right: 5px;
  transition: all 0.5s 0s ease;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li > a {
    transition: all 0.3s ease;
  }
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .g_subList > li > a:hover {
    color: #0077AC !important;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn {
    justify-content: space-between;
    padding: 0 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn.biz-connect li {
    width: 80%;
    margin: 0 auto;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn.biz-connect li a {
  background-color: #E44E00;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn.biz-connect li a img {
  width: 25px;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn.style01 li {
    align-items: center;
    width: 60%;
    margin: 0 auto;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li {
  height: 50px;
  text-align: center;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li {
    transition: all 0.5s 0s ease;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li {
    height: auto;
    width: 49%;
    display: flex;
    align-items: center;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) {
  background-color: #0C3F71;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) {
    margin-bottom: 0;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1) img {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(1):hover {
    background-color: #125da7;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li.biz svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li.biz img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li.biz svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li.biz img {
    width: 26px;
    height: 26px;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2) {
  background-color: #E44E00;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2) svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2) img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2) svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2) img {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li:nth-child(2):hover {
    background-color: #e47b26;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li a {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: bold;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li a {
    font-size: 13px;
    line-height: 1;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li a {
    height: auto;
  }
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li a svg, .header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .gWrap .gNav_btn li a img {
  margin-right: 10px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .navTopLink {
  margin-top: 15px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .navTopLink a {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  color: #032A44;
  justify-content: center;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .navTopLink a span {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.header .header_menu .header_menu_btn .header_menu_nav .header_menu_nav_wrap .navTopLink a span img {
  width: 100%;
  height: auto;
}
.header .header_menu .header_menu_btn .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 0;
  z-index: 0;
  opacity: 0;
  transition: all 0.5s 0s ease;
}
.header .header_menu .header_menu_btn .menu-btn {
  display: flex;
  z-index: 90;
  background-color: #0C3F71;
  border-radius: 0 0 5px 5px;
  top: 0;
  position: absolute;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn {
    left: 0;
    height: 110px;
    width: 84px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn {
    right: 0;
    height: 16.74vw;
    width: 15.66vw;
    border-radius: 0 0 1.35vw 1.35vw;
    z-index: 1000;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn {
    right: 0;
    height: 8.37vw;
    width: 7.83vw;
    border-radius: 0 0 0.68vw 0.68vw;
    z-index: 1000;
  }
}
.header .header_menu .header_menu_btn .menu-btn span {
  border-radius: 2px;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  transition: all 0.5s 0s ease;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn span {
    left: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn span {
    height: 1.89vw;
    left: 4.05vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn span {
    height: 0.945vw;
    left: 2.023vw;
  }
}
.header .header_menu .header_menu_btn .menu-btn span:nth-child(1) {
  background-color: #A5DEFF;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(1) {
    top: 24px;
    width: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(1) {
    top: 2.16vw;
    width: 5.67vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(1) {
    top: 1.08vw;
    width: 2.835vw;
  }
}
.header .header_menu .header_menu_btn .menu-btn span:nth-child(2) {
  background-color: #448FC1;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(2) {
    top: 38px;
    width: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(2) {
    top: 5.4vw;
    width: 6.48vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(2) {
    top: 2.7vw;
    width: 3.24vw;
  }
}
.header .header_menu .header_menu_btn .menu-btn span:nth-child(3) {
  background-color: #0172BE;
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(3) {
    top: 53px;
    width: 33px;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(3) {
    top: 8.64vw;
    width: 7.56vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(3) {
    top: 4.32vw;
    width: 3.78vw;
  }
}
.header .header_menu .header_menu_btn .menu-btn span:nth-child(4) {
  line-height: 1;
  color: #fff;
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(4) {
    top: 71px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(4) {
    top: 11.34vw;
    font-size: 2.97vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn .menu-btn span:nth-child(4) {
    top: 5.67vw;
    font-size: 1.49vw;
  }
}
.header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(1) {
  top: 38px;
  width: 33px;
  transform: rotate(45deg);
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(1) {
    top: 2.7vw;
    width: 3.78vw;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(1) {
    top: 5.4vw;
    width: 7.56vw;
  }
}
.header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(2) {
  display: none;
}
.header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(3) {
  top: 38px;
  transform: rotate(-45deg);
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(3) {
    top: 2.7vw;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .menu-btn span:nth-child(3) {
    top: 5.4vw;
  }
}
.header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .header_menu_nav {
  top: 100px;
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .header_menu_nav {
    top: 6.75vw;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .header_menu_nav {
    top: 13.5vw;
  }
}
@media only screen and (min-width: 1100px) {
  .header .header_menu .header_menu_btn #header_menu_btn_check:checked ~ .overlay {
    height: 100vh;
    opacity: 1;
  }
}
.header .header_wrap {
  background-color: #fff;
  position: relative;
  z-index: 3;
  height: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_wrap {
    height: 6.75vw;
    z-index: 999;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_wrap {
    height: 13.5vw;
    z-index: 999;
  }
}
.header .header_wrap .header_logo {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  white-space: nowrap;
}
.header .header_wrap .header_logo p.link01 {
  margin-right: 10px;
}
.header .header_wrap .header_logo p.link01:first-child {
  margin-left: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .header .header_wrap .header_logo p.link01 {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .header .header_wrap .header_logo p.link01 {
    display: none;
  }
}
.header .header_wrap .header_logo p.link01:last-child {
  margin-right: 0;
}
.header .header_wrap .header_logo p.link01 a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.2s 0s ease;
}
.header .header_wrap .header_logo p.link01 a::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url(/top/img/circle-arrow.svg) center center no-repeat;
  background-size: 95%;
  position: relative;
  margin-right: 5px;
}
.header .header_wrap .header_logo p.link01 a:hover {
  color: #0077AC;
}
.header .header_wrap .header_logo p.link01.style01 {
  margin-left: 30px;
}
.header .header_wrap .header_logo span {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #C5D0D5;
}
@media only screen and (min-width: 1100px) {
  .header .header_wrap .header_logo {
    left: 105px;
  }
  .header .header_wrap .header_logo span {
    height: 30px;
    /* vertical-align: middle; */
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    margin-left: 12px;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .header .header_wrap .header_logo {
    left: 10px;
  }
  .header .header_wrap .header_logo span {
    border-right: none;
    padding: 0;
    margin: 0;
    font-size: 11px;
    vertical-align: middle;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 1099px) and (max-width: 767px) {
  .header .header_wrap .header_logo {
    width: 60%;
  }
}
@media only screen and (max-width: 1099px) {
  .header .header_wrap .header_logo img {
    max-width: 100%;
  }
  .header .header_wrap .header_logo img.mini {
    max-width: 100%;
  }
}
.header .header_wrap .header_logo img {
  vertical-align: middle;
}
.header .header_wrap .header_contact {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  background-color: #fff;
}
@media only screen and (max-width: 1099px) {
  .header .header_wrap .header_contact {
    display: none;
  }
}
.header .header_wrap .header_contact .leftBox {
  padding: 0 20px;
  display: flex;
}
.header .header_wrap .header_contact .leftBox .btn_purpose p:last-child {
  margin-right: 0;
}
.header .header_wrap .header_contact .phone {
  padding: 0 35px;
  line-height: 1.25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header .header_wrap .header_contact .phone p {
  line-height: 0.5;
}
.header .header_wrap .header_contact .phone p:nth-child(1) {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 33px;
  font-size: 3.3rem;
  padding-top: 5px;
}
.header .header_wrap .header_contact .phone p:nth-child(1) .icon {
  width: 0.8em;
  height: 0.8em;
  vertical-align: middle;
  margin-right: 8px;
  fill: #0077AC;
}
.header .header_wrap .header_contact .phone p:nth-child(1) span {
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 3px;
}
.header .header_wrap .header_contact .phone p:nth-child(2) {
  line-height: 1.8;
  margin-top: 0;
  font-size: 13px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .header_wrap .header_contact .phone p:nth-child(2) span {
  display: inline-block;
  padding-left: 5px;
  font-size: 14px;
  font-size: 1.4rem;
}
.header .header_wrap .header_contact .btn_purpose {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.header .header_wrap .header_contact .btn_purpose p.link01 {
  margin-bottom: 7px;
}
.header .header_wrap .header_contact .btn_purpose p.link01 a {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.2s 0s ease;
  border-radius: 50px;
  border: solid 2px #C5D0D5;
  width: 130px;
  padding: 3px 5px;
  box-sizing: border-box;
}
.header .header_wrap .header_contact .btn_purpose p.link01 a:hover {
  background-color: #C5D0D5;
  color: #0077AC;
}
.header .header_wrap .header_contact .btn_purpose p.link01.style01 a {
  background-color: #e3e3e3;
  border-radius: 50px;
  border: none;
  border: solid 2px #d5d5d5;
  transition: all 0.2s 0s ease;
}
.header .header_wrap .header_contact .btn_purpose p.link01.style01 a:hover {
  color: #333;
  background-color: #c6c6c6;
  border: solid 2px #c6c6c6;
}
.header .header_wrap .header_contact .btn_purpose p.link02 a {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.2s 0s ease;
  border-radius: 50px;
  background-color: #0077AC;
  color: #fff;
  border: solid 2px #0077AC;
  width: 130px;
  box-sizing: border-box;
  padding: 3px 5px;
}
.header .header_wrap .header_contact .btn_purpose p.link02 a:hover {
  background-color: #fff;
  color: #0077AC;
}
.header .header_wrap .header_contact .btn_entry {
  width: 120px;
  height: 100px;
  background-color: #E44E00;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  position: relative;
  transition: all 0.5s 0s ease;
}
.header .header_wrap .header_contact .btn_entry.style01 a {
  background-color: #004B95;
  transition: all 0.5s 0s ease;
}
@media only screen and (min-width: 1100px) {
  .header .header_wrap .header_contact .btn_entry.style01 a:hover {
    background-color: #2567a7;
  }
}
.header .header_wrap .header_contact .btn_entry.biz p img {
  width: 25px;
  height: auto;
  margin-bottom: 5px;
}
@media only screen and (min-width: 1100px) {
  .header .header_wrap .header_contact .btn_entry:hover {
    background-color: #e47b26;
  }
}
.header .header_wrap .header_contact .btn_entry a {
  color: #fff;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: block;
}
.header .header_wrap .header_contact .btn_entry a p {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.25;
}
.header .header_wrap .header_contact .btn_entry a p svg, .header .header_wrap .header_contact .btn_entry a p img {
  width: 25.7px;
  height: 25.7px;
  display: block;
  margin: 0 auto 8px;
}
.header .header_wrap .header_contact .btn_entry a p span {
  display: block;
  font-size: 15px;
  font-size: 1.5rem;
}

@media only screen and (min-width: 1100px) {
  .lower .header_menu_nav_ind .toggle {
    display: none;
  }
  .lower .header_menu_nav_biz .toggle {
    display: none;
  }
}

@keyframes navAnime {
  0% {
    line-height: 0;
  }
  100% {
    line-height: 1.8;
  }
}
@keyframes navAnimeClose {
  0% {
    line-height: 1.8;
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    line-height: 0;
    opacity: 0;
  }
}
.contents_inner {
  padding-top: 150px;
  margin-top: -150px;
}
@media only screen and (max-width: 767px) {
  .contents_inner {
    padding-top: 80px;
    margin-top: -80px;
  }
}

/*////////////////////////////////////////////////////

    フッター

////////////////////////////////////////////////////*/
#inquiry {
  padding: 4vw 0 6.2vw;
}
@media only screen and (max-width: 767px) {
  #inquiry {
    padding: 8.3vw 0 6.2vw;
  }
}
#inquiry .top_cont_tit {
  margin: 0 auto 2.5vw;
}
@media only screen and (max-width: 767px) {
  #inquiry .top_cont_tit {
    margin: 0 auto 6.8vw;
  }
}
#inquiry .name {
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0 2.5vw;
}
@media only screen and (max-width: 767px) {
  #inquiry .name {
    font-size: 4.32vw;
    margin: 0 0 6.8vw;
  }
}
#inquiry .inquiry_wrap {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 1100px) and (max-width: 1280px) {
  #inquiry .inquiry_wrap {
    width: 90%;
  }
}
@media only screen and (max-width: 1099px) {
  #inquiry .inquiry_wrap {
    width: 95%;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap {
    flex-wrap: wrap;
  }
}
#inquiry .inquiry_wrap > div {
  width: 32%;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div {
    width: 48.5%;
    padding-bottom: 5.9vw;
  }
  #inquiry .inquiry_wrap > div:first-child {
    width: 100%;
  }
}
#inquiry .inquiry_wrap > div h2 {
  text-align: center;
  padding: 1.3vw 0;
  background-color: #E1EDF3;
  font-size: clamp(14px, 1.2vw, 16px);
  font-weight: bold;
  position: relative;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div h2 {
    padding: 2.16vw 0;
    font-size: 3.8vw;
  }
}
#inquiry .inquiry_wrap > div h2::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #C8DFEC transparent transparent transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.8vw 0.8vw 0 0.8vw;
  bottom: -0.8vw;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div h2::after {
    border-width: 2.97vw 2.97vw 0 2.97vw;
    bottom: -2.97vw;
  }
}
#inquiry .inquiry_wrap > div .way {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  #inquiry .inquiry_wrap > div .way {
    height: 5vw;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 1400px) {
  #inquiry .inquiry_wrap > div .way {
    padding: 0 1vw;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div .way {
    margin-top: 1rem;
  }
}
#inquiry .inquiry_wrap > div .hours {
  margin-top: 10px;
  font-size: clamp(10px, 0.88vw, 12px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div .hours {
    margin-top: 0;
    font-size: 3.24vw;
    white-space: nowrap;
    line-height: 1.3;
    font-weight: normal;
  }
}
#inquiry .inquiry_wrap > div .hours span {
  display: inline-block;
  background-color: #0077AC;
  color: #fff;
  vertical-align: middle;
  padding: 0.37vw 0.58vw;
  margin-right: 0.44vw;
  border-radius: 0.8vw;
  font-size: clamp(8px, 0.73vw, 10px);
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div .hours span {
    padding: 1.5vw;
    margin-right: 0.44vw;
    border-radius: 2.7vw;
    font-size: 2.7vw;
  }
}
#inquiry .inquiry_wrap > div.phone .way {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(36px, 2.92vw, 40px);
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  #inquiry .inquiry_wrap > div.phone .way {
    font-size: 3.5vw;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.phone .way {
    font-size: 9.45vw;
  }
}
#inquiry .inquiry_wrap > div.phone .way .icon {
  width: 0.8em;
  height: 0.8em;
  vertical-align: middle;
  margin-right: 8px;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.mail h2 {
    margin-bottom: 6.67vw;
  }
}
#inquiry .inquiry_wrap > div.mail .way.btn {
  width: 88%;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.mail .way.btn {
    width: 100%;
    height: 26.2vw;
    margin-bottom: 2rem;
  }
}
#inquiry .inquiry_wrap > div.mail .way.btn a {
  display: block;
  background-color: #0C3F71;
  color: #fff;
  font-size: clamp(14px, 1.17vw, 16px);
  font-weight: bold;
  padding: 1.3vw 0;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.mail .way.btn a {
    font-size: 4.32vw;
    padding: 4.266vw 0 2.16vw;
    text-align: center;
    line-height: 1.3;
  }
}
#inquiry .inquiry_wrap > div.mail .way.btn a .icon {
  width: 2.52vw;
  height: 1.79vw;
  vertical-align: middle;
  margin-right: 0.55vw;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.mail .way.btn a .icon {
    display: block;
    width: 9.3vw;
    height: 6.6vw;
    margin: 0 auto 2.7vw;
  }
}
@media only screen and (min-width: 1100px) {
  #inquiry .inquiry_wrap > div.mail .way.btn a:hover {
    padding-left: 1vw;
  }
  #inquiry .inquiry_wrap > div.mail .way.btn a:hover .icon {
    margin-right: 0.37vw;
  }
  #inquiry .inquiry_wrap > div.mail .way.btn a:hover::after {
    left: 100%;
  }
  #inquiry .inquiry_wrap > div.mail .way.btn a::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-color: #448FC1;
    position: absolute;
    opacity: 0.7;
    top: 0;
    left: -100%;
    z-index: 0;
    transition: all 0.3s ease-in;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.mail .hours {
    display: block;
    text-align: center;
  }
  #inquiry .inquiry_wrap > div.mail .hours span {
    margin-bottom: 5px;
  }
}
#inquiry .inquiry_wrap > div.chat {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.chat h2 {
    margin-bottom: 6.67vw;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.chat .way {
    height: 26.2vw;
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: calc(2rem - 2px);
  }
}
#inquiry .inquiry_wrap > div.chat .way .icon {
  width: 6.643vw;
  height: 4.93vw;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.chat .way .icon {
    width: 13.64vw;
    height: 10.1vw;
    display: block;
    margin: 4.4vw auto 1.8vw;
  }
}
@media only screen and (max-width: 767px) {
  #inquiry .inquiry_wrap > div.chat .hours {
    display: block;
    text-align: center;
  }
  #inquiry .inquiry_wrap > div.chat .hours span {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 1100px) {
  .footer {
    background-color: #E1EDF3;
    background-image: url(/common/img/wave_white.svg);
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}
.footer nav {
  background-color: #333;
}
.footer nav .footer_util {
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .footer nav .footer_util {
    width: 95%;
  }
}
@media only screen and (max-width: 767px) {
  .footer nav .footer_util {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .footer nav .footer_util li {
    font-size: clamp(11px, 0.95vw, 13px);
    width: 20%;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .footer nav .footer_util li {
    width: calc(50% - 1px);
    border-bottom: 1px solid #fff;
    text-align: center;
    font-size: 3.51vw;
  }
  .footer nav .footer_util li:nth-child(even) {
    border-left: 1px solid #fff;
  }
  .footer nav .footer_util li:nth-child(5) {
    width: 100%;
  }
}
@media only screen and (min-width: 1100px) {
  .footer nav .footer_util li {
    transition: all 0.3s ease;
  }
  .footer nav .footer_util li:hover {
    background-color: #585858;
  }
}
.footer nav .footer_util li a {
  display: block;
  color: #fff;
  padding: 12px 0;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .footer nav .footer_util li a {
    padding: 2.7vw 0;
  }
}
.footer #sitemap {
  width: 80%;
  max-width: 1100px;
  margin: 35px auto 50px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .footer #sitemap {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .footer #sitemap {
    width: 100%;
  }
}
.footer #sitemap > div {
  width: 20%;
  text-align: center;
}
.footer #sitemap > div.member {
  width: 40%;
}
.footer #sitemap > div.member > ul {
  width: 100%;
}
.footer #sitemap > div.member li {
  margin-right: 0.5em;
}
.footer #sitemap > div > ul {
  margin: 0 auto;
  display: inline-block;
  text-align: left;
}
.footer #sitemap > div > ul li {
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1100px) {
  .footer #sitemap > div > ul li a {
    transition: all 0.3s ease;
  }
  .footer #sitemap > div > ul li a:hover {
    color: #4699D1;
  }
}
.footer #sitemap > div > ul li ul {
  margin: 10px 0 0;
}
.footer #sitemap > div > ul li ul li a {
  font-weight: normal;
}
.footer #sitemap > div > ul li ul li::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 2px;
  background-color: #448FC1;
  vertical-align: middle;
  margin-right: 0.5em;
}
.footer #sitemap > div > ul li ul li.second a {
  font-weight: normal;
}
.footer #sitemap > div > ul li ul li.second::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #0077AC;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.footer #sitemap > div > ul li .wrapMember {
  display: flex;
  justify-content: space-around;
}
.footer #sitemap > div > ul li .wrapMember div:first-child {
  margin-right: 1vw;
}
.footer #sitemap > div > ul li .wrapMember div:last-child {
  margin-top: 1em;
}
@media only screen and (min-width: 768px) {
  .footer .footer_info {
    width: 80%;
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1100px) and (max-width: 1280px) {
  .footer .footer_info {
    width: 90%;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info::after {
    content: url(/top/img/ico_kamome-1.svg);
    transform: rotateY(180deg);
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info::after {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_logo {
    width: 31%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info .footer_logo {
    width: 48%;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_logo img {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info .footer_info_wrap {
    text-align: center;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .name {
    font-size: clamp(13px, 1vw, 15px);
    font-weight: bold;
    margin: 0;
    text-align: center;
    line-height: 1;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info .footer_info_wrap .name {
    font-size: clamp(13px, 1.7vw, 15px);
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .phone {
    padding: 0 2.5vw;
    line-height: 1;
    margin-bottom: 6px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1100px) and (max-width: 1280px) {
  .footer .footer_info .footer_info_wrap .phone {
    padding: 0 1.5vw;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(1) {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 39px;
    font-size: 3.9rem;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(1) {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(1) .icon {
    width: 0.8em;
    height: 0.8em;
    vertical-align: middle;
    margin-right: 8px;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(1) span {
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 3px;
  }
}
@media only screen and (min-width: 768px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(2) {
    margin-top: 0;
    font-size: 11px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer .footer_info .footer_info_wrap .phone p:nth-child(2) span {
    display: inline-block;
    background-color: #0077AC;
    color: #fff;
    padding: 5px 8px;
    border-radius: 11px;
    vertical-align: middle;
    font-size: 10px;
    font-size: 1rem;
    margin-right: 0.5em;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1100px) and (max-width: 1280px) {
  .footer .footer_info .footer_info_wrap .phone p:nth-child(2) {
    font-size: 10px;
    font-size: 1rem;
  }
  .footer .footer_info .footer_info_wrap .phone p:nth-child(2) span {
    font-size: 8px;
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer .footer_info {
    background-color: #fff;
    padding: 9.8vw 0 13.5vw;
    text-align: center;
  }
  .footer .footer_info .footer_logo {
    width: 73vw;
    margin: 0 auto 4.5vw;
  }
  .footer .footer_info .footer_logo img {
    width: 100%;
  }
  .footer .footer_info .name {
    font-size: 4vw;
    font-weight: bold;
    line-height: 1;
  }
  .footer .footer_info .phone {
    line-height: 1;
    margin: 0 auto 7.8vw;
    width: 90%;
  }
  .footer .footer_info .phone p:nth-child(1) {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 10.8vw;
    margin-bottom: 5px;
  }
  .footer .footer_info .phone p:nth-child(1) .icon {
    width: 0.8em;
    height: 0.8em;
    vertical-align: middle;
    margin-right: 8px;
  }
  .footer .footer_info .phone p:nth-child(1) span {
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 3px;
  }
  .footer .footer_info .phone p:nth-child(2) {
    margin: 0 auto;
    font-size: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer .footer_info .phone p:nth-child(2) span {
    display: inline-block;
    background-color: #0077AC;
    color: #fff;
    padding: 5px 8px;
    border-radius: 11px;
    vertical-align: middle;
    font-size: 2.7vw;
    margin-right: 5px;
  }
}
.footer .footer_info .footer_info_list {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .footer .footer_info .footer_info_list {
    width: 100%;
  }
}
@media only screen and (max-width: 1099px) {
  .footer .footer_info .footer_info_list {
    justify-content: center;
  }
}
.footer .footer_info .footer_info_list li {
  margin-right: 15px;
}

.copylight {
  letter-spacing: 0;
  font-size: 12px;
  font-size: 1.2rem;
  background-color: #0C3F71;
  color: #fff;
  text-align: center;
  padding: 18px 0;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .copylight {
    font-size: 3.24vw;
    margin-bottom: 16vw;
  }
}

.btn_scrolltop {
  background-color: #E6E6E6;
  padding: 0.88vw 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .btn_scrolltop {
    line-height: 1;
    padding: 3.24vw 0;
    font-size: clamp(12px, 3.8vw, 14px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .btn_scrolltop {
    font-size: clamp(12px, 1.82vw, 14px);
  }
}
@media only screen and (min-width: 1100px) {
  .btn_scrolltop {
    font-size: clamp(12px, 1.02vw, 14px);
    transition: all 0.3s ease;
  }
  .btn_scrolltop:hover {
    opacity: 0.8;
  }
}
.btn_scrolltop span::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.3vw 0.44vw 0.3vw;
  border-color: transparent transparent #0C3F71 transparent;
  vertical-align: middle;
  margin-left: 0.37vw;
}
@media only screen and (max-width: 767px) {
  .btn_scrolltop span::after {
    border-width: 0 1.08vw 1.62vw 1.08vw;
    margin-left: 2.5vw;
  }
}

@media only screen and (min-width: 1100px) {
  .fixed_bnr {
    display: none;
  }
}
@media only screen and (max-width: 1099px) {
  .fixed_bnr {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    z-index: 999;
  }
  .fixed_bnr ul {
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 1% 0;
    margin: 0 auto;
  }
  .biz .fixed_bnr ul li {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .biz .fixed_bnr ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    color: #fff;
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: bold;
  }
  .fixed_bnr ul li {
    width: 49%;
  }
  .fixed_bnr ul li a {
    display: block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 2.7vw 0;
    font-size: 4.05vw;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 768px) and (max-width: 1099px) {
  .fixed_bnr ul li a {
    padding: 1vw 0;
    font-size: 1.8vw;
  }
}
@media only screen and (max-width: 1099px) {
  .fixed_bnr ul li a svg {
    vertical-align: middle;
  }
}
@media only screen and (max-width: 1099px) {
  .fixed_bnr ul li:nth-child(1) {
    background-color: #0C3F71;
  }
  .fixed_bnr ul li:nth-child(1) svg, .fixed_bnr ul li:nth-child(1) img {
    width: 5.86vw;
    height: 4.3vw;
    margin-right: 1vw;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 768px) and (max-width: 1099px) {
  .fixed_bnr ul li:nth-child(1) svg, .fixed_bnr ul li:nth-child(1) img {
    width: 2.93vw;
    height: 2.1vw;
    margin-right: 0.5vw;
  }
}
@media only screen and (max-width: 1099px) {
  .fixed_bnr ul li.biz svg, .fixed_bnr ul li.biz img {
    width: 6.17vw;
    height: 6.17vw;
    margin-right: 1vw;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 768px) and (max-width: 1099px) {
  .fixed_bnr ul li.biz svg, .fixed_bnr ul li.biz img {
    width: 3.08vw;
    height: 3.08vw;
    margin-right: 0.5vw;
  }
}
@media only screen and (max-width: 1099px) {
  .fixed_bnr ul li:nth-child(2) {
    background-color: #E44E00;
  }
  .fixed_bnr ul li:nth-child(2) svg, .fixed_bnr ul li:nth-child(2) img {
    width: 6.17vw;
    height: 6.17vw;
    margin-right: 1vw;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 768px) and (max-width: 1099px) {
  .fixed_bnr ul li:nth-child(2) svg, .fixed_bnr ul li:nth-child(2) img {
    width: 3.08vw;
    height: 3.08vw;
    margin-right: 0.5vw;
  }
}

/*////////////////////////////////////////////////////

  例外のレイアウト

  ※各ページ固有の、ちょっとしたスタイルの追加に使用します

////////////////////////////////////////////////////*/
/* ------------------------------------------------
  デバイスごとの表示切り分け
------------------------------------------------ */
.only-sp {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .only-sp {
    display: block !important;
  }
}

.only-pc {
  display: none !important;
}
@media only screen and (min-width: 1100px) {
  .only-pc {
    display: block !important;
  }
}

.only-tablet {
  display: none !important;
}
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .only-tablet {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .no-sp {
    display: none !important;
  }
}

@media only screen and (min-width: 1100px) {
  .no-pc {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1099px) {
  .no-tablet {
    display: none !important;
  }
}

/* ------------------------------------------------
  テキストの調整
------------------------------------------------ */
.color01 {
  color: #448FC1;
}

.color02 {
  color: #0077AC;
}

.fw_b {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

/* ------------------------------------------------
  余白の調整
------------------------------------------------ */
.mt_0 {
  margin-top: 0 !important;
}

@media print, screen and (min-width: 768px) {
  .mt_l {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .mt_l {
    margin-top: 40px;
  }
}

@media print, screen and (min-width: 768px) {
  .mt_m {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .mt_m {
    margin-top: 20px;
  }
}

@media print, screen and (min-width: 768px) {
  .mt_s {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .mt_s {
    margin-top: 10px;
  }
}

.mb_0 {
  margin-bottom: 0 !important;
}

@media print, screen and (min-width: 768px) {
  .mb_l {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .mb_l {
    margin-bottom: 40px;
  }
}

@media print, screen and (min-width: 768px) {
  .mb_m {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .mb_m {
    margin-bottom: 20px;
  }
}

@media print, screen and (min-width: 768px) {
  .mb_s {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .mb_s {
    margin-bottom: 10px;
  }
}

/* ------------------------------------------------
	utility margin class
------------------------------------------------ */
.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mt110 {
  margin-top: 110px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.alignC {
  text-align: center !important;
}

.alignR {
  text-align: right !important;
}

.alignL {
  text-align: left !important;
}

/* ------------------------------------------------
  電話
------------------------------------------------ */
@media only screen and (min-width: 768px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}